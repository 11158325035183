<template>
  <div>
    <div v-if="loading">
      <loader />
    </div>
    <div v-else-if="showActivityDetails">
      <activity-details
        :activity="selectedActivity"
        :playbook="playbook"
        :parentContainer="selectedParentContainer"
        :task="task"
        @viewPlaybook="onViewPlaybook"
      ></activity-details>
    </div>
    <div v-else-if="showCategoryDetails">
      <category-details
        :category="selectedCategory"
        :playbook="playbook"
        :parentContainer="selectedParentContainer"
        :task="task"
        @viewPlaybook="onViewPlaybook"
        @viewCategory="onViewCategory"
        @viewActivity="onViewActivity"
      ></category-details>
    </div>
    <div v-else-if="showEventsFeed">
      <playbook-events-feed
        :playbook="playbook"
        :taskId="task.id"
        @viewPlaybook="onViewPlaybook"
      ></playbook-events-feed>
    </div>
    <div v-else :class="{ 'answer-loading': answersLoading }">
      <div class="import-export">
        <span @click="onShowEventsFeed" class="show-events-feed-button">
          <md-icon class="icon-action-big icon-add">dynamic_feed</md-icon>
          <md-tooltip md-delay="300">Show Events Feed</md-tooltip>
        </span>
        <el-dropdown style="cursor: pointer;">
          <span class="el-dropdown-link">
            Import/Export<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="importPlaybook()">Import</el-dropdown-item>
            <el-dropdown-item @click.native="exportPlaybook()">Export</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <h5 class="playbook-section-title">
        Summary
        <span
          class="saving-button"
          :class="{
            saved: summaryStatus === UpdateStatus.SAVED,
            failed: summaryStatus === UpdateStatus.FAILED,
            updating: summaryStatus === UpdateStatus.UPDATING,
            editing: summaryStatus === UpdateStatus.EDITING
          }"
        ></span>
      </h5>
      <div class="playbook-form-container">
        <el-form :model="playbook" label-width="120px" :rules="rules" ref="playbook">
          <el-form-item label="Id" prop="id">
            <el-input v-model="playbook._id" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="Playbook title" prop="title">
            <div class="form-input">
              <el-input
                type="textarea"
                autosize
                v-model="playbook.title"
                @blur="
                  playbookCopy.title != playbook.title
                    ? sendUpdatePlaybookCommand({ title: playbook.title })
                    : ''
                "
                @input="onSummaryInputChange"
                @keydown.enter.prevent.native="
                  () =>
                    playbookCopy.title != playbook.title
                      ? sendUpdatePlaybookCommand({ title: playbook.title })
                      : ''
                "
              ></el-input>
              <history
                :taskId="task.id"
                :playbookId="playbook._id"
                field="title"
                @restore="
                  summaryStatus = UpdateStatus.EDITING;
                  sendUpdatePlaybookCommand({ title: $event.value ||'' });
                "
              />
            </div>
          </el-form-item>

          <el-form-item label="Time frame">
            <el-col :span="10">
              <div class="form-input">
                <el-date-picker
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="Start date"
                  v-model="playbook.startDate"
                  style="width: 100%"
                  prop="startDate"
                  @change="
                    playbookCopy.startDate != playbook.startDate &&
                      sendUpdatePlaybookCommand({ startDate: playbook.startDate || '' })
                  "
                  @input="onSummaryInputChange"
                ></el-date-picker>
                <history
                  :taskId="task.id"
                  :playbookId="playbook._id"
                  field="startDate"
                  @restore="
                    summaryStatus = UpdateStatus.EDITING;
                    sendUpdatePlaybookCommand({ startDate: $event.value || '' });
                  "
                />
              </div>
            </el-col>
            <el-col class="line" :span="3">-</el-col>
            <el-col :span="11">
              <div class="form-input">
                <el-date-picker
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="End date"
                  v-model="playbook.endDate"
                  style="width: 100%"
                  prop="endDate"
                  @change="
                    playbookCopy.endDate != playbook.endDate &&
                      sendUpdatePlaybookCommand({ endDate: playbook.endDate || '' })
                  "
                  @input="onSummaryInputChange"
                ></el-date-picker>
                <history
                  :taskId="task.id"
                  :playbookId="playbook._id"
                  field="endDate"
                  @restore="
                    summaryStatus = UpdateStatus.EDITING;
                    sendUpdatePlaybookCommand({ endDate: $event.value || '' });
                  "
                />
              </div>
            </el-col>
          </el-form-item>
          <el-form-item label="Description">
            <div class="form-input">
              <el-input
                type="textarea"
                v-model="playbook.description"
                style="width: 100%"
                prop="description"
                rows="8"
                @blur="
                  playbookCopy.description != playbook.description
                    ? sendUpdatePlaybookCommand({ description: playbook.description })
                    : ''
                "
                @input="onSummaryInputChange"
              >
              </el-input>
              <history
                :taskId="task.id"
                :playbookId="playbook._id"
                field="description"
                @restore="
                  summaryStatus = UpdateStatus.EDITING;
                  sendUpdatePlaybookCommand({ description: $event.value ||'' });
                "
              />
            </div>
          </el-form-item>
        </el-form>
      </div>
      <!-- NOTES ------------------------------------------------->
      <playbook-notes
        :task="task"
        :playbook="playbook"
        :element="playbook"
        :containerType="'Playbook'"
      />
      <!-- Addition properties ------------------------------------------------->
      <additional-properties :task="task" :playbook="playbook" :element="playbook" />
      <h5 class="playbook-section-title">
        <archived-categories :playbookId="playbook._id" :taskId="task.id" :categories="playbook.categories.filter(e=>e.isArchived)"
            @status="activitiesStatus = $event"/> Categories

        <span
          class="saving-button"
          :class="{
            saved: activitiesStatus === UpdateStatus.SAVED,
            failed: activitiesStatus === UpdateStatus.FAILED,
            updating: activitiesStatus === UpdateStatus.UPDATING,
            editing: activitiesStatus === UpdateStatus.EDITING
          }"
        ></span>
      </h5>
      <div class="categories-table-container">
        <draggable
          tag="ul"
          v-model="categories"
          :animation="200"
          :group="{ name: 'all-categories', pull: 'all-categories', put: 'all-categories' }"
          handle=".handle"
          @change="onChangeCategories($event)"
        >
          <category-card
            v-for="category in categories"
            :category="category"
            :key="category.id"
            :playbook="playbook"
            :task="task"
            :parentContainer="playbook"
            @viewCategory="onViewCategory"
            @viewActivity="onViewActivity"
            @status="activitiesStatus = $event"
          >
          </category-card>
        </draggable>
        <ul>
          <new-category-card
            v-if="!createCategoryButtonDisplayed"
            @saveNewCategory="createCategory"
            @closeDialogue="onCloseNewCategoryDialogue"
          >
          </new-category-card>
        </ul>
        <div class="activities-table-footer">
          <span
            @click="showCreateCategoryForm"
            class="create-activity-button-container"
            v-if="createCategoryButtonDisplayed"
          >
            <md-icon class="icon-action icon-add">create_new_folder</md-icon>
            <md-tooltip md-delay="300">Create Activity Category</md-tooltip>
          </span>
        </div>
      </div>
      <div class="categories-table-container">
        <h5 class="playbook-section-title">

         <archived-activities :playbookId="playbook._id" :taskId="task.id" :activities="playbook.activities.filter(e=>e.isArchived)"
            @status="activitiesStatus = $event"/> Activities

        <span
          class="saving-button"
          :class="{
            saved: activitiesStatus === UpdateStatus.SAVED,
            failed: activitiesStatus === UpdateStatus.FAILED,
            updating: activitiesStatus === UpdateStatus.UPDATING,
            editing: activitiesStatus === UpdateStatus.EDITING
          }"
        ></span>
      </h5>
        <draggable
          tag="ul"
          v-model="activities"
          :animation="200"
          :group="{ name: 'all-activities', pull: 'all-activities', put: 'all-activities' }"
          handle=".handleActivity"
          @change="onChangeActivities($event)"
        >
          <activity-card
            v-for="activity in activities"
            :activity="activity"
            :parentContainer="playbook"
            :key="activity.id"
            :playbook="playbook"
            :task="task"
            @viewActivity="onViewActivity"
            @status="activitiesStatus = $event"
          >
          </activity-card>
        </draggable>
        <ul>
          <new-activity-card
            v-if="!createActivityButtonDisplayed"
            @saveNewActivity="createActivity"
            @closeDialogue="onCloseNewActivityDialogue"
          >
          </new-activity-card>
        </ul>
        <div slot="footer" class="activities-table-footer" role="group" key="footer">
          <div v-if="createActivityButtonDisplayed">
            <span @click="showCreateActivityForm" class="create-activity-button-container">
              <md-icon class="icon-action icon-add">post_add</md-icon>
              <md-tooltip md-delay="300">Create Activity</md-tooltip>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/api';
import notify from '@/utils/notificationsUtils';
import draggable from 'vuedraggable';
import moment from 'moment';
import Loader from '@/components/Avalia/Loader/Loader.vue';
import ActivityDetails from '@/components/Avalia/TaskDetails/Playbook/ActivityDetails/ActivityDetails.vue';
import CategoryDetails from '@/components/Avalia/TaskDetails/Playbook/CategoryDetails.vue';
import CategoryCard from '@/components/Avalia/TaskDetails/Playbook/CategoryCard.vue';
import NewCategoryCard from '@/components/Avalia/TaskDetails/Playbook/NewCategoryCard.vue';
import ArchivedCategories from '@/components/Avalia/TaskDetails/Playbook/ArchivedCategories.vue';
import ActivityCard from '@/components/Avalia/TaskDetails/Playbook/ActivityCard.vue';
import NewActivityCard from '@/components/Avalia/TaskDetails/Playbook/NewActivityCard.vue';
import ArchivedActivities from '@/components/Avalia/TaskDetails/Playbook/ActivityDetails/ArchivedActivities.vue';
import PlaybookEventsFeed from '@/components/Avalia/TaskDetails/Playbook/Events/PlaybookEventsFeed.vue';
import AdditionalProperties from '@/components/Avalia/TaskDetails/Playbook/ActivityDetails/AdditionalProperties.vue';
import History from '@/components/Avalia/TaskDetails/Playbook/History.vue';
import {
  Form,
  FormItem,
  Input,
  Col,
  DatePicker,
  Button,
  Table,
  TableColumn,
  Dropdown,
  DropdownMenu,
  DropdownItem,
} from 'element-ui';
import PlaybookNotes from '../Playbook/Notes/PlaybookNotes.vue';
import UpdateStatus from '../UpdateStatus';

export default {
  name: 'playbook-task',
  components: {
    draggable,
    Loader,
    ActivityDetails,
    CategoryCard,
    NewCategoryCard,
    ActivityCard,
    NewActivityCard,
    [Form.name]: Form,
    [Input.name]: Input,
    [Col.name]: Col,
    [DatePicker.name]: DatePicker,
    [FormItem.name]: FormItem,
    [Button.name]: Button,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    AdditionalProperties,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    History,
    ArchivedCategories,
    ArchivedActivities,
    CategoryDetails,
    PlaybookEventsFeed,
    PlaybookNotes,
  },
  props: {
    projectId: Number,
    readOnly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: true,
      answersLoading: false,
      createCategoryButtonDisplayed: true,
      createActivityButtonDisplayed: true,
      showActivityDetails: false,
      selectedActivityId: null,
      showCategoryDetails: false,
      showEventsFeed: false,
      selectedCategoryId: null,
      selectedParentContainer: {},
      isFormProcessingSave: false,
      summaryStatus: UpdateStatus.SAVED,
      activitiesStatus: UpdateStatus.SAVED,
      playbookCopy: {},
      UpdateStatus,
      rules: {
        title: [{ required: true, message: 'Please input Playbook title', trigger: 'blur' }],
        startDate: [
          {
            type: 'date',
            required: true,
            message: 'Please pick a start date for the Playbook',
            trigger: 'change',
          },
        ],
        endDate: [
          {
            type: 'date',
            required: true,
            message: 'Please pick an end date for the playbook',
            trigger: 'change',
          },
        ],
      },
    };
  },
  computed: {
    accessToken() {
      return api.assess.defaults.headers.common.Authorization;
    },
    computeCursorClass() {
      return this.readOnly ? 'cursor-not-allowed' : '';
    },
    playbook() {
      return this.$store.getters['playbooks/GET_PLAYBOOK'];
    },
    categories: {
      get() {
        return this.playbook.categories;//.filter((a) => !a.isArchived);
      },
      set(categories) {
        this.playbook.categories = categories;
        //this.playbook.categories.concat(this.playbook.categories.filter((a) => a.isArchived));
      },
    },
    activities: {
      get() {
        return this.playbook.activities;//.filter((a) => !a.isArchived);
      },
      set(activities) {
        this.playbook.activities = activities;
        //this.playbook.activities.concat(this.playbook.activities.filter((a) => a.isArchived));
      },
    },
    selectedActivity() {
      return this.findActivityInPlaybook(this.selectedActivityId);
    },
    selectedCategory() {
      return this.findCategoryInPlaybook(this.selectedCategoryId);
    },
    task() {
      return this.$store.getters['task/GET_TASK'];
    },
    taskNotes() {
      return this.$store.getters['notes/GET_TASK_NOTES'];
    },
    isTaskCompleted() {
      return this.task.state === 'completed';
    },
    isTaskLocked() {
      return this.task.state === 'locked';
    },
    assessUrl() {
      return process.env.VUE_APP_ASSESS_BACKEND_API_URL;
    },
  },
  watch: {
    'task.playbookId': function () {
      this.fetchPlaybookOnInit();
    },
  },
  // On created
  created() {
    this.fetchPlaybookOnInit();
  },
  mounted() {},
  methods: {
    // ----------- Navigation --------------
    onViewActivity(eventData) {
      this.selectedActivityId = eventData.activity.id;
      this.selectedParentContainer = eventData.parentContainer;
      this.showActivityDetails = true;
    },
    onViewCategory(eventData) {
      this.selectedCategoryId = eventData.category.id;
      this.selectedParentContainer = eventData.parentContainer;
      this.showCategoryDetails = true;
    },
    onViewPlaybook() {
      this.showActivityDetails = false;
      this.showCategoryDetails = false;
      this.showEventsFeed = false;
      this.selectedActivityId = null;
      this.selectedParentContainer = {};
    },
    onShowEventsFeed() {
      this.showEventsFeed = true;
    },
    // DFS to find an activity in the playbook
    findActivityInPlaybook(activityId) {
      return this.recursiveFindActivityInPlaybook(this.playbook, activityId);
    },
    recursiveFindActivityInPlaybook(container, activityId) {
      let i;
      let currentCategory;
      let activityFound;

      activityFound = container.activities.find((activity) => {
        return activity.id === activityId;
      });
      if (activityFound != null) {
        return activityFound;
      }
      // Use a for loop instead of forEach to avoid nested functions
      // Otherwise "return" will not work properly
      for (i = 0; i < container.categories.length; i += 1) {
        currentCategory = container.categories[i];
        // Search in the current child
        activityFound = this.recursiveFindActivityInPlaybook(currentCategory, activityId);
        // Return the result if the node has been found
        if (activityFound !== false) {
          return activityFound;
        }
      }
      // The node has not been found and we have no more options
      return false;
    },
    findCategoryInPlaybook(categoryId) {
      return this.recursiveFindCategoryInPlaybook(this.playbook, categoryId);
    },
    recursiveFindCategoryInPlaybook(container, categoryId) {
      let i;
      let currentCategory;
      let categoryFound;

      categoryFound = container.categories.find((category) => {
        return category.id === categoryId;
      });
      if (categoryFound != null) {
        return categoryFound;
      }
      // Use a for loop instead of forEach to avoid nested functions
      // Otherwise "return" will not work properly
      for (i = 0; i < container.categories.length; i += 1) {
        currentCategory = container.categories[i];
        // Search in the current child
        categoryFound = this.recursiveFindCategoryInPlaybook(currentCategory, categoryId);
        // Return the result if the node has been found
        if (categoryFound !== false) {
          return categoryFound;
        }
      }
      // The node has not been found and we have no more options
      return false;
    },
    // ---------- Fetch playbook ----------
    fetchPlaybookOnInit() {
      this.loading = true;
      return this.fetchPlaybook().then(() => {
        this.loading = false;
      });
    },
    fetchPlaybook() {
      const payload = {
        taskId: this.task.id,
        vm: this,
      };
      return this.$store.dispatch('playbooks/fetchPlaybook', payload).then(() => {
        this.playbookCopy.title = this.playbook.title;
        this.playbookCopy.startDate = this.playbook.startDate;
        this.playbookCopy.endDate = this.playbook.endDate;
        this.playbookCopy.description = this.playbook.description;
      });
    },
    getCurrentDate() {
      const date = new Date();
      const d = date.getDate();
      const m = date.getMonth() + 1; //Month from 0 to 11
      const y = date.getFullYear();
      return `${y}-${m <= 9 ? `0${m}` : m}-${d <= 9 ? `0${d}` : d}`;
    },
    exportPlaybook() {
      const payload = {
        taskId: this.task.id,
        vm: this,
      };
      return this.$store.dispatch('playbooks/fetchPlaybook', payload).then(() => {
        const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(
          JSON.stringify(this.playbook),
        )}`;
        const el = document.createElement('a');
        el.setAttribute('href', dataStr);
        el.setAttribute(
          'download',
          `avalia_playbook_${this.playbook.title}_${this.getCurrentDate()}.json`,
        );
        el.click();
        const successMessage = `Playbook ${this.playbook.title} successfully exported`;
        notify.notifySuccess(successMessage, this);
      });
    },
    importPlaybook() {
      // creating input on-the-fly
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', '.json');

      input.onchange = () => {
        const files = Array.from(input.files);
        const reader = new FileReader();
        reader.onload = () => {
          //console.log(reader.result);
          const UpdatePlaybookCommand = {
            type: 'ImportPlaybookCommand',
            // eslint-disable-next-line no-underscore-dangle
            playbookId: this.playbook._id,
          };

          const command = { ...UpdatePlaybookCommand, playbookDTO: JSON.parse(reader.result) };
          return this.sendPlaybookCommand(command).then(() => {
            const successMessage = 'Playbook successfully imported';
            notify.notifySuccess(successMessage, this);
          });
        };
        reader.readAsText(files[0]);
      };
      input.click();
    },
    // --------- Update summary ------------
    onSummaryInputChange() {
      this.summaryStatus = UpdateStatus.EDITING;
    },
    sendPlaybookCommand(command) {
      const answerPayload = {
        action: 'WORK',
        taskWorkDTO: {
          type: 'playbook task',
          command,
        },
      };
      this.isFormProcessingSave = true;
      this.summaryStatus = UpdateStatus.UPDATING;
      return new Promise((resolve, reject) => {
        api.assess
          .post(`/tasks/${this.task.id}`, answerPayload)
          .then((response) => {
            //console.log('Playbook successfully Saved');
            this.summaryStatus = UpdateStatus.SAVED;
            return this.fetchPlaybook();
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            const errorMessage = `Could not save playbook : ${error.response.data.message || ''}`;
            notify.notifyError(errorMessage, this);
            this.summaryStatus = UpdateStatus.FAILED;
            reject(error);
          })
          .finally(() => {
            this.isFormProcessingSave = false;
          });
      });
    },
    sendUpdatePlaybookCommand(partialCommand) {
      if (this.summaryStatus !== UpdateStatus.EDITING) {
        return false;
      }

      const UpdatePlaybookCommand = {
        type: 'UpdatePlaybookCommand',
        // eslint-disable-next-line no-underscore-dangle
        playbookId: this.playbook._id,
      };

      const command = { ...UpdatePlaybookCommand, ...partialCommand };
      return this.sendPlaybookCommand(command);
    },
    // ------- Update activities and categories --------
    showCreateCategoryForm() {
      this.createCategoryButtonDisplayed = false;
    },
    showCreateActivityForm() {
      this.createActivityButtonDisplayed = false;
    },
    onCloseNewCategoryDialogue() {
      this.createCategoryButtonDisplayed = true;
    },
    onCloseNewActivityDialogue() {
      this.createActivityButtonDisplayed = true;
    },
    onChangeActivities(event) {
      if (event.added) {
        this.onMoveActivityToList(event.added.element, event.added.newIndex);
      } else if (event.removed) {
        // Remove is only triggered when we add the element to another list, so we manage that in the add event
      } else if (event.moved) {
        const newIndex = event.moved.newIndex + (event.moved.newIndex > event.moved.oldIndex ? 1 : 0);
        this.onMoveActivityToList(event.moved.element, newIndex);
        //this.onReorderActivities();
      }
    },
    onChangeCategories(event) {
      //console.log('playbook event', event);
      if (event.added) {
        this.onMoveCategoryToList(event.added.element, event.added.newIndex);
      } else if (event.removed) {
        // Remove is only triggered when we add the element to another list, so we manage that in the add event
      } else if (event.moved) {
        const newIndex = event.moved.newIndex + (event.moved.newIndex > event.moved.oldIndex ? 1 : 0);
        this.onMoveCategoryToList(event.moved.element, newIndex);
      }
    },
    onMoveActivityToList(activity, index) {
      const moveActivityCommand = {
        type: 'MoveActivityCommand',
        // eslint-disable-next-line no-underscore-dangle
        playbookId: this.playbook._id,
        activityId: activity.id,
        index,
      };
      const answerPayload = {
        action: 'WORK',
        taskWorkDTO: {
          type: 'playbook task',
          command: moveActivityCommand,
        },
      };
      this.activitiesStatus = UpdateStatus.UPDATING;
      api.assess
        .post(`/tasks/${this.task.id}`, answerPayload)
        .then((response) => {
          this.fetchPlaybook();
          this.activitiesStatus = UpdateStatus.SAVED;
        })
        .catch((error) => {
          const errorMessage = `Could not reorder Playbook's activities : ${error.response.data
            .message || ''}`;
          notify.notifyError(errorMessage, this);
          this.activitiesStatus = UpdateStatus.FAILED;
        });
    },
    onMoveCategoryToList(category, index) {
      const moveCategoryCommand = {
        type: 'MoveCategoryCommand',
        // eslint-disable-next-line no-underscore-dangle
        playbookId: this.playbook._id,
        categoryId: category.id,
        index,
      };
      const answerPayload = {
        action: 'WORK',
        taskWorkDTO: {
          type: 'playbook task',
          command: moveCategoryCommand,
        },
      };
      this.activitiesStatus = UpdateStatus.UPDATING;
      api.assess
        .post(`/tasks/${this.task.id}`, answerPayload)
        .then((response) => {
          this.fetchPlaybook();
          this.activitiesStatus = UpdateStatus.SAVED;
        })
        .catch((error) => {
          const errorMessage = `Could not reorder Playbook's categories : ${error.response.data
            .message || ''}`;
          notify.notifyError(errorMessage, this);
          this.activitiesStatus = UpdateStatus.FAILED;
        });
    },
    createCategory(category) {
      const addCategoryCommand = {
        type: 'AddCategoryCommand',
        // eslint-disable-next-line no-underscore-dangle
        playbookId: this.playbook._id,
        title: category.title,
      };
      const answerPayload = {
        action: 'WORK',
        taskWorkDTO: {
          type: 'playbook task',
          command: addCategoryCommand,
        },
      };

      this.activitiesStatus = UpdateStatus.UPDATING;
      return api.assess
        .post(`/tasks/${this.task.id}`, answerPayload)
        .then((response) => {
          //console.log('Activity successfully Created');
          this.fetchPlaybook();
          this.createCategoryButtonDisplayed = true;
          this.activitiesStatus = UpdateStatus.SAVED;
        })
        .catch((error) => {
          const errorMessage = `Could not save playbook : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, this);
          this.activitiesStatus = UpdateStatus.FAILED;
        });
    },
    createActivity(activity) {
      const addActivityCommand = {
        type: 'AddActivityCommand',
        // eslint-disable-next-line no-underscore-dangle
        playbookId: this.playbook._id,
        title: activity.title,
      };
      const answerPayload = {
        action: 'WORK',
        taskWorkDTO: {
          type: 'playbook task',
          command: addActivityCommand,
        },
      };

      this.activitiesStatus = UpdateStatus.UPDATING;
      return api.assess
        .post(`/tasks/${this.task.id}`, answerPayload)
        .then((response) => {
          //console.log('Activity successfully Created');
          this.fetchPlaybook();
          this.createActivityButtonDisplayed = true;
          this.activitiesStatus = UpdateStatus.SAVED;
        })
        .catch((error) => {
          const errorMessage = `Could not save playbook : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, this);
          this.activitiesStatus = UpdateStatus.FAILED;
        });
    },
    // --------------- Utils -------------
    dateFormat(date) {
      if (date === undefined) {
        return '';
      }
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
  },
};
</script>
<!-- element ui css override -->
<style lang="scss" scoped>
ul {
  margin: 0.1rem;
}
.activity-card {
  margin-top: 1rem;
  margin-bottom: 0rem;
}
.el-step__title {
  line-height: 1.5em;
}
.line {
  text-align: center;
}
.save-button-container {
  padding-left: 2rem;
  text-align: right;
}
.small-lines .el-form-item__content {
  line-height: 1rem;
}
.curl-command-container {
  padding-top: 0.6rem;
  line-height: 1rem;
  word-wrap: break-word;
}
.playbook-section-title {
  padding-left: 1.2rem;
}
.activities-table-container {
  margin: 0rem 0rem 0rem 2rem;
}
.playbook-form-container {
  margin: 0rem 0rem 0rem 2rem;
}
.md-tabs-content table thead {
  display: table-header-group;
}
.activities-table-footer {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0;
  .create-activity-button-container {
    padding-right: 1rem;
  }
}
.el-form-item__label {
  height: 100%;
}
.handle {
  float: left;
  padding-top: 8px;
  padding-bottom: 8px;
}
.import-export {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 8px;
}
.form-input {
  display: flex;
  gap: 10px;
}
.show-events-feed-button{
  padding-left: 1rem;
  padding-right: 2rem;
}
/*.form-input .history{
  transition: opacity 0.2s ease-out;
  opacity: 0;
}
.form-input:hover .history{
  transition: opacity 0.2s ease-in;
  opacity: 1;
}*/
</style>
