<template>
  <div class="archive">
    <span>
      <md-icon class="icon-action" tabindex="-1" v-popover:archive slot="reference">restore</md-icon>
      <md-tooltip md-delay="300">Show archived elements</md-tooltip>
    </span>
    <el-popover
        placement="bottom-start"
        title="Archived resources"
        ref="archive"
        trigger="click"
        width="400"
        v-model="popoverVisible">
        <div class="archive-popover">
          <table class="archive-table">
              <tr>
                  <th style="min-width:120px;">Name</th>
                  <th colspan="2" style="min-width:50px;">Actions</th>
              </tr>
              <tr v-for="(resource, i) in resources" :key="i">
                  <td style="vertical-align:top" :title="resource.name">
                    {{resource.name}}
                  </td>
                  <td style="vertical-align:top">
                      <md-icon class="icon-action" @click.native="unarchive(resource)" >unarchive</md-icon>
                      <md-tooltip class="archive-action-tooltip" md-delay="300">Unarchive resource</md-tooltip>
                  </td>
                  <td style="vertical-align:top">
                      <md-icon class="icon-action" @click.native="showDeletionConfirmationPopUp(resource)" >delete</md-icon>
                      <md-tooltip class="archive-action-tooltip" md-delay="300">Delete resource permanently</md-tooltip>
                  </td>
              </tr>
          </table>
        </div>
    </el-popover>
  </div>
</template>

<script>
import api from '@/api/api';
import notify from '@/utils/notificationsUtils';
import {
  Popover,
  Button,
  Tooltip,
  InfiniteScroll,
} from 'element-ui';
import Swal from 'sweetalert2';
import UpdateStatus from '../../UpdateStatus';

const swalWithCustomButtons = Swal.mixin({
  customClass: {
    confirmButton: 'md-button md-success btn-fill',
    cancelButton: 'md-button md-danger btn-fill',
  },
  buttonsStyling: false,
});

export default {
  components: {
    [Button.name]: Button,
    [Popover.name]: Popover,
    [Tooltip.name]: Tooltip,
  },
  directives: {
    [InfiniteScroll.name]: InfiniteScroll,
  },
  name: 'archived-resources',
  props: {
    taskId: {
      type: Number,
      required: true,
    },
    playbookId: {
      type: String,
      required: true,
    },
    activityId: {
      type: String,
      required: true,
    },
    resources: {
      type: Array,
      required: true,
      default: () => [],
      description: 'This is the  list of archived resources.',
    },
  },
  data() {
    return {
      popoverVisible: false,
    };
  },
  methods: {
    showDeletionConfirmationPopUp(resource) {
      this.isDeletionPopUpOpen = true;
      swalWithCustomButtons
        .fire({
          title: 'Are you sure you want to permanently delete this resource :',
          text: `"${resource.name}"`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, remove it!',
          buttonsStyling: false,
          onClose: this.closeIsDeletionPopUpOpen,
        })
        .then((result) => {
          if (result.value) {
            this.delete(resource);
          }
        });
    },
    delete(resource) {
      const deleteResourceCommand = {
        // eslint-disable-next-line no-underscore-dangle
        playbookId: this.playbookId,
        activityId: this.activityId,
        resourceId: resource.id,
        type: 'DeleteResourceCommand',
      };
      const taskWorkCommand = {
        action: 'WORK',
        taskWorkDTO: {
          type: 'playbook task',
          command: deleteResourceCommand,
        },
      };
      this.$emit('status', UpdateStatus.UPDATING);
      return api.assess
        .post(`/tasks/${this.taskId}`, taskWorkCommand)
        .then((response) => {
          //console.log('Resource successfully deleted');
          this.fetchPlaybook();
          this.popoverVisible = false;
          this.$emit('status', UpdateStatus.SAVED);
        })
        .catch((error) => {
          const errorMessage = `Could not delete resource : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, this);
          this.$emit('status', UpdateStatus.FAILED);
        });
    },
    unarchive(resource) {
      const archiveResourcesCommand = {
        type: 'UnarchiveResourceCommand',
        // eslint-disable-next-line no-underscore-dangle
        playbookId: this.playbookId,
        activityId: this.activityId,
        resourceId: resource.id,
      };
      const answerPayload = {
        action: 'WORK',
        taskWorkDTO: {
          type: 'playbook task',
          command: archiveResourcesCommand,
        },
      };
      this.$emit('status', UpdateStatus.UPDATING);
      api.assess
        .post(`/tasks/${this.taskId}`, answerPayload)
        .then((response) => {
          this.fetchPlaybook();
          this.popoverVisible = false;
          this.$emit('status', UpdateStatus.SAVED);
        })
        .catch((error) => {
          const errorMessage = `Could not archive resource : ${error.response
            .data.message || ''}`;
          notify.notifyError(errorMessage, this);
          this.$emit('status', UpdateStatus.FAILED);
        });
    },
    fetchPlaybook() {
      const payload = {
        taskId: this.taskId,
        vm: this,
      };
      return this.$store.dispatch('playbooks/fetchPlaybook', payload);
    },
  },
};
</script>

<style lang="scss" scoped >
.archive{
  display: inline-block;
}
.archive-popover{
  max-height: 400px;
  overflow-y: auto;
  z-index: 4000;
}
.archive-table{
  width: 100%;
}
.archive-action-tooltip {
  z-index: 4000;
}
</style>
