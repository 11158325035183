<template>
  <div class="md-layout">
    <div class="md-layout-item md-layout md-size-100 md-medium-size-100">
      <div class="md-layout-item md-size-100 md-medium-size-100 nopadding">
        <div class="md-layout-item md-size-100 md-medium-size-100">
          <internal-breadcrumbs
            :sourceName="playbook.title"
            :currentName="category.title"
            @showSource="onShowSource"
          />
        </div>
        <div>
          <div class="md-layout">
            <div class="md-layout-item md-size-100 md-medium-size-100">
              <h5 class="section-title">
                Category
                <span
                  class="saving-button"
                  :class="{
                    saved: status === UpdateStatus.SAVED,
                    failed: status === UpdateStatus.FAILED,
                    updating: status === UpdateStatus.UPDATING,
                    editing: status === UpdateStatus.EDITING
                  }"
                >
                </span>
              </h5>
              <el-form :model="category" label-width="120px" :rules="rules" ref="category">
                <el-form-item label="Title" prop="title">
                  <div class="form-input">
                    <el-input
                      type="textarea"
                      autosize
                      v-model="category.title"
                      @blur="
                        categoryCopy.title != category.title
                          ? sendUpdateCategoryCommand({ title: category.title })
                          : ''
                      "
                      @keydown.enter.prevent.native="
                        () =>
                          categoryCopy.title != category.title
                            ? sendUpdateCategoryCommand({ title: category.title })
                            : ''
                      "
                      @input="onCategoryFormInputChange(categoryCopy.title, category.title)"
                    >
                    </el-input>
                    <history
                      :taskId="task.id"
                      :playbookId="playbook._id"
                      :targetId="category.id"
                      field="title"
                      @restore="
                        status = UpdateStatus.EDITING;
                        sendUpdateCategoryCommand({ title: $event.value ||'' });
                      "
                    />
                  </div>
                </el-form-item><el-form-item label="Summary" prop="summary">
                  <div class="form-input">
                    <el-input
                      type="textarea"
                      autosize
                      v-model="category.summary"
                      @blur="
                        categoryCopy.summary != category.summary
                          ? sendUpdateCategoryCommand({ summary: category.summary })
                          : ''
                      "
                      @keydown.enter.prevent.native="
                        () =>
                          categoryCopy.summary != category.summary
                            ? sendUpdateCategoryCommand({ summary: category.summary })
                            : ''
                      "
                      @input="onCategoryFormInputChange(categoryCopy.summary, category.summary)"
                    >
                    </el-input>
                    <history
                      :taskId="task.id"
                      :playbookId="playbook._id"
                      :targetId="category.id"
                      field="summary"
                      @restore="
                        status = UpdateStatus.EDITING;
                        sendUpdateCategoryCommand({ summary: $event.value ||'' });
                      "
                    />
                  </div>
                </el-form-item>
              </el-form>
              <!-- NOTES ------------------------------------------------->
              <playbook-notes
                :task="task"
                :playbook="playbook"
                :element="category"
                :containerType="'Category'"
              />
              <!-- Addition properties ------------------------------------------------->
              <additional-properties
                :task="task"
                :playbook="playbook"
                :parentContainer="parentContainer"
                :element="category"
              />
            </div>
            <!--  ADDED FOR INNER CATEGORIES AND ACTIVITIES-->
            <div class="md-layout-item md-size-100 md-medium-size-100 categories-table-container">
              <h5 class="playbook-section-title">
                <archived-categories :playbookId="playbook._id" :taskId="task.id" :categories="category.categories.filter(e=>e.isArchived)"
                    @status="categoriesStatus = $event"/>
                Categories
                <span
                  class="saving-button"
                  :class="{
                    saved: categoriesStatus === UpdateStatus.SAVED,
                    failed: categoriesStatus === UpdateStatus.FAILED,
                    updating: categoriesStatus === UpdateStatus.UPDATING,
                    editing: categoriesStatus === UpdateStatus.EDITING
                  }"
                >
                </span>
              </h5>
              <draggable
                tag="ul"
                v-model="categories"
                :animation="200"
                :group="{ name: 'all-categories', pull: 'all-categories', put: 'all-categories' }"
                handle=".handle"
                @change="onChangeCategories($event)"
              >
                <category-card
                  v-for="categoryIterator in categories"
                  :category="categoryIterator"
                  :key="categoryIterator.id"
                  :playbook="playbook"
                  :parentContainer="category"
                  :task="task"
                  @viewCategory="onViewCategory"
                  @viewActivity="onViewActivity"
                  @status="categoriesStatus = $event"
                >
                </category-card>
              </draggable>
              <ul>
                <new-category-card
                  v-if="!createCategoryButtonDisplayed"
                  @saveNewCategory="createCategory"
                  @closeDialogue="onCloseNewCategoryDialogue"
                >
                </new-category-card>
              </ul>
              <div class="activities-table-footer">
                <span
                  @click="showCreateCategoryForm"
                  class="create-activity-button-container"
                  v-if="createCategoryButtonDisplayed"
                >
                  <md-icon class="icon-action icon-add">create_new_folder</md-icon>
                  <md-tooltip md-delay="300">Create Activity Category</md-tooltip>
                </span>
              </div>
            </div>
            <div class="md-layout-item md-size-100 md-medium-size-100 categories-table-container">
              <h5 class="playbook-section-title">
                <archived-activities :playbookId="playbook._id" :taskId="task.id" :activities="category.activities.filter(e=>e.isArchived)"
                    @status="activitiesStatus = $event"/>
                Activities
                <span
                  class="saving-button"
                  :class="{
                    saved: activitiesStatus === UpdateStatus.SAVED,
                    failed: activitiesStatus === UpdateStatus.FAILED,
                    updating: activitiesStatus === UpdateStatus.UPDATING,
                    editing: activitiesStatus === UpdateStatus.EDITING
                  }"
                >
                </span>
              </h5>
              <draggable
                tag="ul"
                v-model="activities"
                :animation="200"
                :group="{ name: 'all-activities', pull: 'all-activities', put: 'all-activities' }"
                handle=".handleActivity"
                @change="onChangeActivities($event)"
              >
                <activity-card
                  v-for="activity in activities"
                  :activity="activity"
                  :parentContainer="category"
                  :key="activity.id"
                  :playbook="playbook"
                  :task="task"
                  @viewActivity="onViewActivity"
                  @status="activitiesStatus = $event"
                >
                </activity-card>
              </draggable>
              <ul>
                <new-activity-card
                  v-if="!createActivityButtonDisplayed"
                  @saveNewActivity="createActivity"
                  @closeDialogue="onCloseNewActivityDialogue"
                >
                </new-activity-card>
              </ul>
              <div slot="footer" class="activities-table-footer" role="group" key="footer">
                <div v-if="createActivityButtonDisplayed">
                  <span @click="showCreateActivityForm" class="create-activity-button-container">
                    <md-icon class="icon-action icon-add">post_add</md-icon>
                    <md-tooltip md-delay="300">Create Activity</md-tooltip>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Form,
  FormItem,
  Input,
  DatePicker,
  Button,
  Col,
  Checkbox,
  Select,
  Option,
  InputNumber,
  Slider,
} from 'element-ui';
import ActivityCard from '@/components/Avalia/TaskDetails/Playbook/ActivityCard.vue';
import api from '@/api/api';
import draggable from 'vuedraggable';
import InternalBreadcrumbs from '@/components/Avalia/Breadcrumbs/InternalBreadcrumbs.vue';
import NewActivityCard from '@/components/Avalia/TaskDetails/Playbook/NewActivityCard.vue';
import NewCategoryCard from '@/components/Avalia/TaskDetails/Playbook/NewCategoryCard.vue';
import notify from '@/utils/notificationsUtils';
import ArchivedCategories from '@/components/Avalia/TaskDetails/Playbook/ArchivedCategories.vue';
import CategoryCard from '@/components/Avalia/TaskDetails/Playbook/CategoryCard.vue';
import History from './History.vue';
import AdditionalProperties from './ActivityDetails/AdditionalProperties.vue';
import UpdateStatus from '../UpdateStatus';
import ArchivedActivities from './ActivityDetails/ArchivedActivities.vue';
import PlaybookNotes from './Notes/PlaybookNotes.vue';
//import notify from '@/utils/notificationsUtils';

export default {
  name: 'category-details',
  components: {
    [Form.name]: Form,
    [Input.name]: Input,
    [DatePicker.name]: DatePicker,
    [FormItem.name]: FormItem,
    [Button.name]: Button,
    [Col.name]: Col,
    [Select.name]: Select,
    [Option.name]: Option,
    [InputNumber.name]: InputNumber,
    [Slider.name]: Slider,
    [Checkbox.name]: Checkbox,
    ActivityCard,
    AdditionalProperties,
    draggable,
    History,
    InternalBreadcrumbs,
    NewCategoryCard,
    NewActivityCard,
    ArchivedActivities,
    ArchivedCategories,
    CategoryCard,
    PlaybookNotes,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
    parentContainer: {
      type: Object,
      required: true,
    },
    playbook: {
      type: Object,
      required: true,
    },
    task: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activitiesStatus: UpdateStatus.SAVED,
      categoriesStatus: UpdateStatus.SAVED,
      categoryCopy: {},
      createActivityButtonDisplayed: true,
      createCategoryButtonDisplayed: true,
      isNewConditionFieldOpen: false,
      rules: {
        title: [{ required: true, message: 'Please input an Category title', trigger: 'blur' }],
      },
      status: UpdateStatus.SAVED,
      UpdateStatus,

    };
  },
  computed: {
    categories: {
      get() {
        return this.category.categories;//.filter((a) => !a.isArchived);
      },
      set(categories) {
        this.category.categories = categories;
        //this.category.categories.concat(this.category.categories.filter((a) => a.isArchived));
      },
    },
    activities: {
      get() {
        return this.category.activities;//.filter((a) => !a.isArchived);
      },
      set(activities) {
        this.category.activities = activities;
        //this.category.activities.concat(this.category.activities.filter((a) => a.isArchived));
      },
    },
  },
  watch: {},
  created() {
    // On created
  },
  mounted() {
    this.saveCategoryToCopy();
  },
  methods: {
    // -------- Navigation -----------
    onCategoryFormInputChange(oldValue, newValue) {
      if (oldValue !== newValue) {
        this.status = UpdateStatus.EDITING;
      } else {
        this.status = UpdateStatus.SAVED;
      }
    },
    onCategoryCheckboxInputChange(partialCommand) {
      this.status = UpdateStatus.EDITING;
      this.sendUpdateCategoryCommand(partialCommand);
    },
    onShowSource() {
      this.$emit('viewPlaybook');
    },
    // --------- Update category -----------
    sendUpdateCategoryCommand(partialCommand) {
      if (this.status !== UpdateStatus.EDITING) {
        return false;
      }
      const updateCategoryCommand = {
        type: 'UpdateCategoryCommand',
        // eslint-disable-next-line no-underscore-dangle
        playbookId: this.playbook._id,
        categoryId: this.category.id,
      };
      const command = { ...updateCategoryCommand, ...partialCommand };
      return this.sendCategoryCommand(command);
    },
    sendCategoryCommand(command) {
      const answerPayload = {
        action: 'WORK',
        taskWorkDTO: {
          type: 'playbook task',
          command,
        },
      };
      this.status = UpdateStatus.UPDATING;
      return api.assess
        .post(`/tasks/${this.task.id}`, answerPayload)
        .then((response) => {
          this.fetchPlaybook();
          this.status = UpdateStatus.SAVED;
        })
        .catch((error) => {
          const errorMessage = `Could not save Category : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, this);
          this.status = UpdateStatus.FAILED;
        });
    },
    // ----------- Inner Categories commands -----------
    onChangeCategories(event) {
      if (event.added) {
        this.onMoveCategoryToList(event.added.element, event.added.newIndex);
      } else if (event.removed) {
        // Remove is only triggered when we add the element to another list, so we manage that in the add event
      } else if (event.moved) {
        const newIndex = event.moved.newIndex + (event.moved.newIndex > event.moved.oldIndex ? 1 : 0);
        this.onMoveCategoryToList(event.moved.element, newIndex);
      }
    },
    onMoveCategoryToList(categoryToMove, index) {
      const moveCategoryCommand = {
        type: 'MoveCategoryCommand',
        // eslint-disable-next-line no-underscore-dangle
        playbookId: this.playbook._id,
        destinationContainerId: this.category.id,
        categoryId: categoryToMove.id,
        index,
      };
      const answerPayload = {
        action: 'WORK',
        taskWorkDTO: {
          type: 'playbook task',
          command: moveCategoryCommand,
        },
      };
      this.categoriesStatus = UpdateStatus.UPDATING;
      api.assess
        .post(`/tasks/${this.task.id}`, answerPayload)
        .then((response) => {
          this.fetchPlaybook();
          this.categoriesStatus = UpdateStatus.SAVED;
        })
        .catch((error) => {
          const errorMessage = `Could not reorder Playbook's categories : ${error.response.data
            .message || ''}`;
          notify.notifyError(errorMessage, this);
          this.categoriesStatus = UpdateStatus.FAILED;
        });
    },
    onViewCategory(eventData) {
      this.$emit('viewCategory', eventData);
    },
    createCategory(newCategory) {
      const addCategoryCommand = {
        type: 'AddCategoryCommand',
        // eslint-disable-next-line no-underscore-dangle
        playbookId: this.playbook._id,
        title: newCategory.title,
        containerId: this.category.id,
      };
      const answerPayload = {
        action: 'WORK',
        taskWorkDTO: {
          type: 'playbook task',
          command: addCategoryCommand,
        },
      };

      this.categoriesStatus = UpdateStatus.UPDATING;
      return api.assess
        .post(`/tasks/${this.task.id}`, answerPayload)
        .then((response) => {
          //console.log('Activity successfully Created');
          this.fetchPlaybook();
          this.createCategoryButtonDisplayed = true;
          this.categoriesStatus = UpdateStatus.SAVED;
        })
        .catch((error) => {
          const errorMessage = `Could not save playbook : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, this);
          this.categoriesStatus = UpdateStatus.FAILED;
        });
    },
    onArchiveCategory() {
      const archiveCategoriesCommand = {
        type: 'ArchiveCategoryCommand',
        // eslint-disable-next-line no-underscore-dangle
        playbookId: this.playbook._id,
        categoryId: this.category.id,
      };
      const answerPayload = {
        action: 'WORK',
        taskWorkDTO: {
          type: 'playbook task',
          command: archiveCategoriesCommand,
        },
      };
      this.categoriesStatus = UpdateStatus.UPDATING;
      api.assess
        .post(`/tasks/${this.task.id}`, answerPayload)
        .then((response) => {
          this.fetchPlaybook();
          this.$emit('status', UpdateStatus.SAVED);
        })
        .catch((error) => {
          const errorMessage = `Could not archive category : ${error.response
            .data.message || ''}`;
          notify.notifyError(errorMessage, this);
          this.categoriesStatus = UpdateStatus.FAILED;
        });
    },
    onCloseNewCategoryDialogue() {
      this.createCategoryButtonDisplayed = true;
    },
    showCreateCategoryForm() {
      this.createCategoryButtonDisplayed = false;
    },
    // ----------- Inner Activities commands -----------
    onViewActivity(eventData) {
      this.$emit('viewActivity', eventData);
    },
    onChangeActivities(event) {
      if (event.added) {
        this.onMoveActivityToList(event.added.element, event.added.newIndex);
      } else if (event.removed) {
        // Remove is only triggered when we add the element to another list, so we manage that in the add event
      } else if (event.moved) {
        const newIndex = event.moved.newIndex + (event.moved.newIndex > event.moved.oldIndex ? 1 : 0);
        this.onMoveActivityToList(event.moved.element, newIndex);
      }
    },
    onMoveActivityToList(activity, index) {
      const moveActivityCommand = {
        type: 'MoveActivityCommand',
        // eslint-disable-next-line no-underscore-dangle
        playbookId: this.playbook._id,
        activityId: activity.id,
        destinationContainerId: this.category.id,
        index,
      };
      const answerPayload = {
        action: 'WORK',
        taskWorkDTO: {
          type: 'playbook task',
          command: moveActivityCommand,
        },
      };
      this.activitiesStatus = UpdateStatus.UPDATING;
      api.assess
        .post(`/tasks/${this.task.id}`, answerPayload)
        .then((response) => {
          this.fetchPlaybook();
          this.activitiesStatus = UpdateStatus.SAVED;
        })
        .catch((error) => {
          const errorMessage = `Could not reorder Playbook's activities : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, this);
          this.activitiesStatus = UpdateStatus.FAILED;
        });
    },
    createActivity(activity) {
      const addActivityCommand = {
        type: 'AddActivityCommand',
        // eslint-disable-next-line no-underscore-dangle
        playbookId: this.playbook._id,
        title: activity.title,
        containerId: this.category.id,
      };
      const answerPayload = {
        action: 'WORK',
        taskWorkDTO: {
          type: 'playbook task',
          command: addActivityCommand,
        },
      };

      this.activitiesStatus = UpdateStatus.UPDATING;
      return api.assess
        .post(`/tasks/${this.task.id}`, answerPayload)
        .then((response) => {
          //console.log('Activity successfully Created');
          this.fetchPlaybook();
          this.createActivityButtonDisplayed = true;
          this.activitiesStatus = UpdateStatus.SAVED;
        })
        .catch((error) => {
          const errorMessage = `Could not save playbook : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, this);
          this.activitiesStatus = UpdateStatus.FAILED;
        });
    },
    onCloseNewActivityDialogue() {
      this.createActivityButtonDisplayed = true;
    },
    showCreateActivityForm() {
      this.createActivityButtonDisplayed = false;
    },
    // ----------- Fetch playbook ------------
    fetchPlaybook() {
      const payload = {
        taskId: this.task.id,
        vm: this,
      };
      this.$store.dispatch('playbooks/fetchPlaybook', payload).then(this.saveCategoryToCopy);
    },
    saveCategoryToCopy() {
      this.categoryCopy.title = this.category.title;
    },
  },
};
</script>

<style lang="scss" scoped>
.section-title {
  margin-bottom: 1rem;
  padding-left: 1.2rem;
}
.button-container {
  padding-left: 1rem;
  text-align: right;
}
.line {
  text-align: center;
}
.align-right {
  text-align: right;
}
.form-input {
  display: flex;
  gap: 10px;
}
.activities-table-footer {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0 0 0;
  .create-activity-button-container {
    padding-right: 1rem;
  }
}
</style>
