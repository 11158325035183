<template>
  <div class="event md-layout">
    <div class="event-content md-layout-item md-layout md-size-100 nopadding">
      <div class="event-text md-layout-item">
        {{ event.label }}
      </div>
    </div>
    <div class="event-footer md-layout-item">
      <div class="event-author">
        <md-icon class="">person</md-icon>
        {{ getAuthorLabel(event.author) }}
      </div>
      <div class="timestamp">
        {{ getLocalTimestamp(`${event.date} ${event.time}`) | moment('from') }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'playbook-event',
  components: {},
  props: {
    event: Object,
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    getLocalTimestamp(timestamp) {
      const localTimestamp = moment(`${timestamp}+00:00`).local().format('YYYY-MM-DD HH:mm:ss');
      return moment(localTimestamp);
    },
    getAuthorLabel(author) {
      if (author == null) {
        return 'anonymous';
      }
      if (author.firstName != null && author.lastName != null) {
        return `${author.firstName} ${author.lastName}`;
      }
      if (author.email != null) {
        return author.email;
      }
      return 'anonymous';
    },
  },
};
</script>

<style lang="scss" scoped>
.event {
  background-color: rgba(188, 198, 204, 0.2);
  padding: 0.2em 0em 0.2em;
  margin: 0em 0.4em 0.4em 0em;
  border-radius: 0.6em;
  cursor: default;
  //max-height: 14vh;
}

.icon-container {
  text-align: right;
  font-size: 0.8em;
  line-height: 1em;
}
.event-footer {
  display: flex;
  padding-bottom: 0.3rem;
}
.timestamp {
  margin-left: auto;
  color: $avalia-cyan;
  text-align: right;
}
.event-text {
  font-size: 0.9rem;
}
.event-author {
  font-weight: bold;
}
</style>
