function isFormTask(taskType) {
  return taskType === 'form task';
}
function isPlaybookTask(taskType) {
  return taskType === 'playbook task';
}

export default {
  isFormTask,
  isPlaybookTask,
};
