<template>
  <div>
    <el-col :span="24">
      <h5 class="section-title">
        <archived-playbook-notes :playbookId="playbook._id" :taskId="task.id" :elementId="element.id"
            :containerType="containerType"
            :notes="element.notes.filter(e=>e.isArchived)"
            @status="status = $event"/> Notes
        <span class="saving-button"
        :class="{saved: status === UpdateStatus.SAVED,
          failed: status === UpdateStatus.FAILED,
          updating: status === UpdateStatus.UPDATING,
          editing: status === UpdateStatus.EDITING}"></span>
      </h5>
    </el-col>

    <draggable
      tag="ul"
      v-model="notes"
      :animation="200"
      :group="{ name: 'all-activities', pull: 'all-activities', put: 'all-activities' }"
      handle=".handleNote"
      @change="onChangeNote($event)"
    >
      <div v-for="(note, index) in notes" :key="note.id" class="note-card md-layout">
        <div v-if="!note.isArchived" class="md-layout-item md-layout">
          <div class="md-layout-item md-size-5 ">
            <div class="card-icon noshadow cursor-pointer">
              <md-icon class="handleNote">drag_handle</md-icon>
            </div>
          </div>
          <el-form class="md-layout-item md-size-95 md-layout" label-width="80px">
            <el-form-item class="md-layout-item md-size-90 nopadding" label="Note">
              <el-col :span="24">
                <el-input
                  type="textarea"
                  autosize
                  style="width: 100%;"
                  v-model="note.text"
                  :prop="'note.' + index"
                  placeholder="New note..."
                  @blur="notesCopy[index].text !== note.text ? sendUpdateNoteCommand(note):''"
                  @keydown.enter.prevent.native="() => notesCopy[index].text !== note.text ? sendUpdateNoteCommand(note):''"
                  @input="onNoteFormChange(notesCopy[index].text, note.text)"
                >
                </el-input>
              </el-col>
            </el-form-item>
            <el-form-item class="md-layout-item md-size-10 nopadding archive-note-button-container">
              <el-col :span="6" class="hide-button">
                <span @click="archiveNote(note)">
                  <md-icon class="icon-action icon-action icon-delete"
                    >archive</md-icon
                  >
                  <md-tooltip md-delay="300"
                    >Archive note : "{{ getEllipsedNoteText(note.text) }}"</md-tooltip
                  >
                </span>
              </el-col>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </draggable>
    <el-form label-width="120px">
      <el-form-item v-if="addNoteFormDisplayed">
        <el-input
          type="textarea"
          autosize
          v-model="newNote.text"
          :prop="'newNote'"
          placeholder="New note text"
          @keydown.enter.prevent.native="createNewNote()"
          @blur="createNewNote()"
          ref="noteInput"
        >
        </el-input>
      </el-form-item>
      <el-form-item v-else>
        <el-col :span="1" :offset="23">
          <span @click="displayAddNoteForm">
            <md-icon class="icon-action">add</md-icon>
            <md-tooltip md-delay="300">Add activity note</md-tooltip>
          </span>
        </el-col>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import api from '@/api/api';
import notify from '@/utils/notificationsUtils';
import draggable from 'vuedraggable';
import {
  Form,
  FormItem,
  Input,
  Col,
} from 'element-ui';
import ArchivedPlaybookNotes from './ArchivedPlaybookNotes.vue';
import UpdateStatus from '../../UpdateStatus';

export default {
  name: 'playbook-notes',
  components: {
    draggable,
    [Form.name]: Form,
    [Input.name]: Input,
    [FormItem.name]: FormItem,
    [Col.name]: Col,
    ArchivedPlaybookNotes,
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
    playbook: {
      type: Object,
      required: true,
    },
    element: {
      type: Object,
      required: true,
    },
    containerType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      addNoteFormDisplayed: false,
      newNote: {},
      status: UpdateStatus.SAVED,
      UpdateStatus,
      notesCopy: [],
      activityIdKey: 'activityId',
      categoryIdKey: 'categoryId',
    };
  },
  mounted() {
    this.saveNotesToCopy();
  },
  computed: {
    notes: {
      get() {
        return this.element.notes;
      },
      set(notes) {
        this.element.notes = notes;
      },
    },
  },
  methods: {
    getEllipsedNoteText(text) {
      const textLength = text.length;
      return textLength > 50 ? `${text.substring(0, 47)}...` : text;
    },
    // reordering
    onChangeNote(event) {
      //console.log('playbook event', event);
      if (event.added) {
        // move between lists is currently unsupported for Conditions
      } else if (event.removed) {
        // Remove is only triggered when we add the element to another list, so we manage that in the add event
      } else if (event.moved) {
        const newIndex = event.moved.newIndex + (event.moved.newIndex > event.moved.oldIndex ? 1 : 0);
        this.onMoveNoteToList(event.moved.element, newIndex);
      }
    },
    onMoveNoteToList(note, index) {
      const moveNoteCommand = {
        type: `Move${this.containerType}NoteCommand`,
        // eslint-disable-next-line no-underscore-dangle
        playbookId: this.playbook._id,
        noteId: note.id,
        index,
      };
      // Add required field according to container type (if container is a Playbook, we don't need to add any field)
      if (this.containerType === 'Activity') {
        moveNoteCommand[this.activityIdKey] = this.element.id;
      } else if (this.containerType === 'Category') {
        moveNoteCommand[this.categoryIdKey] = this.element.id;
      }

      const answerPayload = {
        action: 'WORK',
        taskWorkDTO: {
          type: 'playbook task',
          command: moveNoteCommand,
        },
      };
      this.activitiesStatus = UpdateStatus.UPDATING;
      api.assess
        .post(`/tasks/${this.task.id}`, answerPayload)
        .then((response) => {
          this.fetchPlaybook();
          this.activitiesStatus = UpdateStatus.SAVED;
        })
        .catch((error) => {
          const errorMessage = `Could not reorder Activity's notes : ${error.response.data
            .message || ''}`;
          notify.notifyError(errorMessage, this);
          this.activitiesStatus = UpdateStatus.FAILED;
        });
    },
    // -------- Navigation -------
    displayAddNoteForm() {
      this.addNoteFormDisplayed = true;
      this.$nextTick(() => {
        this.$refs.noteInput.focus();
      });
    },
    hideAddNoteForm() {
      this.addNoteFormDisplayed = false;
    },
    // --------- Conditions actions ---------
    onNoteFormChange(oldValue, newValue) {
      if (oldValue !== newValue) {
        this.status = UpdateStatus.EDITING;
      } else {
        this.status = UpdateStatus.SAVED;
      }
    },
    createNewNote() {
      if (this.addNoteFormDisplayed) {
        if (this.newNote.text) {
          this.sendNoteCommand({
            type: `Add${this.containerType}NoteCommand`,
            text: this.newNote.text,
          }).then(() => {
            this.newNote = {};
            this.fetchPlaybook();
            this.status = UpdateStatus.SAVED;
          });
        }
        this.hideAddNoteForm();
      }
    },
    sendUpdateNoteCommand(note) {
      const index = this.element.notes.indexOf(note);
      if (index !== -1 && this.status !== UpdateStatus.SAVED) {
        this.sendNoteCommand({
          type: `Update${this.containerType}NoteCommand`,
          noteId: note.id,
          text: note.text,
        })
          .then(() => {
            this.status = UpdateStatus.SAVED;
          });
      }
    },
    archiveNote(note) {
      const index = this.element.notes.indexOf(note);
      if (index !== -1) {
        this.sendNoteCommand({
          type: `Archive${this.containerType}NoteCommand`,
          noteId: note.id,
        })
          .then(() => {
            this.fetchPlaybook();
            this.status = UpdateStatus.SAVED;
          }).catch(() => {
            this.status = UpdateStatus.FAILED;
          });
      }
    },
    sendNoteCommand(partialCommand) {
      const noteCommand = {
        // eslint-disable-next-line no-underscore-dangle
        playbookId: this.playbook._id,
        ...partialCommand,
      };

      // Add required field according to container type (if container is a Playbook, we don't need to add any field)
      if (this.containerType === 'Activity') {
        noteCommand[this.activityIdKey] = this.element.id;
      } else if (this.containerType === 'Category') {
        noteCommand[this.categoryIdKey] = this.element.id;
      }

      const answerPayload = {
        action: 'WORK',
        taskWorkDTO: {
          type: 'playbook task',
          command: noteCommand,
        },
      };
      this.status = UpdateStatus.UPDATING;
      return api.assess
        .post(`/tasks/${this.task.id}`, answerPayload)
        .then(() => {
          this.fetchPlaybook();
        })
        .catch((error) => {
          const errorMessage = `Could not execute Note command : ${
            error.response.data.message || ''
          }`;
          notify.notifyError(errorMessage, this);
          this.status = UpdateStatus.FAILED;
        });
    },
    // -------- Fetch playbook -----------
    fetchPlaybook() {
      const payload = {
        taskId: this.task.id,
        vm: this,
      };
      return this.$store.dispatch('playbooks/fetchPlaybook', payload).then(this.saveNotesToCopy);
    },
    saveNotesToCopy() {
      this.notesCopy = [];
      Object.values(this.element.notes).forEach((note) => {
        const noteCopy = {
          text: note.text,
        };
        this.notesCopy.push(noteCopy);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
hr {
  border-width: 1px 0px 0px 0px;
  border-style: solid;
  border-color: #c8c8c8;
}
.note-card {
  .hide-button{
    transition: opacity 0.3s ease-out;
    opacity: 0;
  }
  &:hover .hide-button{
    transition: opacity 0.3s ease-in;
    opacity: 1;
  }
}
.section-title {
  margin-bottom: 1rem;
  padding-left: 1.2rem;
}
.button-container {
  padding-left: 1rem;
  text-align: right;
}
.line {
  text-align: center;
}
.align-right {
  text-align: right;
}
.el-form-item__content {
  margin-left: 10px !important;
}
.handleNote {
  padding-top: 0.9rem;
}
.archive-note-button-container {
  &::v-deep .el-form-item__content {
    margin-left: 70px !important;
  }
}
.cursor-pointer {
  cursor: pointer;
}
</style>
