<template>
  <span class="breadcrumbs">
    <div>
      <div class="custom-breadcrumbs custom-breadcrumbs-font">
        <span class="custom-breadcrumbs-link"
          @click="showSource">{{ truncateTitle(sourceName, 35) }}</span>
      </div>
    </div>
    <md-icon class="breadcrumb-icon"><i class="fas fa-angle-right"></i></md-icon>
    <div class="custom-breadcrumbs custom-breadcrumbs-font">
      {{ truncateTitle(currentName,35) }}
    </div>
  </span>
</template>
<script>
export default {
  name: 'internal-breadcrumbs',
  props: {
    sourceName: String,
    currentName: String,
  },
  methods: {
    showSource() {
      this.$emit('showSource');
    },
    // ----------- utils ------------
    truncateTitle(title, size) {
      if (title.length > size - 3) {
        return `${title.substring(0, size - 3)}...`;
      }
      return title;
    },
  },
  computed: {

  },
};
</script>
<style lang="scss" scoped>
.breadcrumbs {
  display: -webkit-box;
  margin-bottom: 10px;
}
.custom-breadcrumbs-font {
  font-weight: 400;
  font-size: 1.25rem!important;
  text-transform: uppercase!important;
  color: #7a7a7a !important;
}
.custom-breadcrumbs-link {
  &:hover {
    text-decoration: underline;
  }
  cursor: pointer;
}
.custom-breadcrumbs {
  padding-top: 0.1rem;
  padding-right: 0.8rem;
}
.breadcrumb-icon {
  padding-right: 0.8rem;
}
</style>
