<template>
  <div>
    <el-col :span="24">
      <h5 class="section-title">
        <ArchivedResources :playbookId="playbook._id" :taskId="task.id" :activityId="activity.id" :resources="activity.resources.filter(e=>e.isArchived)"
            @status="status = $event"/> Resources
        <span @click="toggleEditResource">
          <md-icon class="icon-action icon-action icon-save">
            {{ isModificationActive ? 'link' : 'edit' }}
          </md-icon>
          <md-tooltip md-delay="300">
            {{ isModificationActive ? 'Switch to link display' : 'Switch to edit mode' }}
          </md-tooltip>
        </span>
        <span class="saving-button"
        :class="{saved: status === UpdateStatus.SAVED,
          failed: status === UpdateStatus.FAILED,
          updating: status === UpdateStatus.UPDATING,
          editing: status === UpdateStatus.EDITING}"></span>
      </h5>
    </el-col>

    <draggable
      tag="ul"
      v-model="resources"
      :animation="200"
      :group="{ name: 'all-activities', pull: 'all-activities', put: 'all-activities' }"
      handle=".handleResources"
      @change="onChangeResources($event)"
    >
      <div v-for="(resource, index) in resources" :key="resource.id" class="resource-card md-layout">
        <div v-if="!resource.isArchived" class="md-layout-item md-layout">
            <div class="md-layout-item md-size-5">
              <div class="card-icon noshadow cursor-pointer">
                <md-icon class="handleResources">drag_handle</md-icon>
              </div>
            </div>
            <el-form class="md-layout-item md-size-95" label-width="80px">
              <el-form-item v-if="isModificationActive" label="Name">
                <!-- EDIT MODE -->
                  <el-input
                    v-model="resource.name"
                    placeholder="Resource name"
                    @blur="
                      resourcesCopy[index].name !== resource.name ?
                      sendUpdateResourceCommand({
                        resourceId: resource.id,
                        name: resource.name,
                      }):''
                    "
                    @keydown.enter.prevent.native="
                      resourcesCopy[index].name !== resource.name ?
                      sendUpdateResourceCommand({
                        resourceId: resource.id,
                        name: resource.name,
                      }):''"
                    @input="onResourceFormChange(resourcesCopy[index].name, resource.name)"
                  >
                  </el-input>
              </el-form-item>
              <el-form-item v-if="isModificationActive" label="URL">
                <el-col :span="21">
                  <el-input
                    v-model="resource.link"
                    placeholder="Resource link"
                    @blur="
                      resourcesCopy[index].link !== resource.link ?
                      sendUpdateResourceCommand({
                        resourceId: resource.id,
                        link: resource.link,
                      }):''
                    "
                    @keydown.enter.prevent.native="resourcesCopy[index].link !== resource.link ?
                      sendUpdateResourceCommand({
                        resourceId: resource.id,
                        link: resource.link,
                      }):''"
                    @input="onResourceFormChange(resourcesCopy[index].link, resource.link)"
                  />
                  </el-col>
                  <!-- Delete button -->
                  <el-col :span="2" :offset="1" class="align-right hide-button">
                    <span @click="archiveResource(resource)">
                      <md-icon class="icon-action icon-action icon-delete"
                        >archive</md-icon
                      >
                      <md-tooltip md-delay="300"
                        >Archive resource : "{{ resource.name }}"</md-tooltip
                      >
                    </span>
                  </el-col>
              </el-form-item>
              <el-form-item v-if="!isModificationActive">
                <!-- LINK MODE -->
                <el-col :span="21">
                  <span
                    ><a :href="cleanHttpLink(resource.link)" target="_blank">{{
                      resource.name
                    }}</a></span
                  >
                </el-col>

                <!-- Delete button -->
                <el-col :span="2" :offset="1" class="align-right hide-button">
                  <span @click="archiveResource(resource)">
                    <md-icon class="icon-action icon-action icon-delete"
                      >archive</md-icon
                    >
                    <md-tooltip md-delay="300"
                      >Archive resource : "{{ resource.name }}"</md-tooltip
                    >
                  </span>
                </el-col>
              </el-form-item>
              <el-form-item>
                <hr>
              </el-form-item>
            </el-form>
          </div>
      </div>
    </draggable>
    <el-form label-width="120px">
      <el-form-item>
        <el-col v-if="addResourceFormDisplayed" :span="24">
          <el-input
            v-model="newResource.link"
            :prop="'newResource'"
            placeholder="New resource link"
            @keydown.enter.prevent.native="createNewResource()"
            @blur="createNewResource()"
            ref="resourceInput"
          >
          </el-input>
        </el-col>
        <el-col v-else :span="1" :offset="23">
          <span @click="displayAddResourceForm">
            <md-icon class="icon-action">add</md-icon>
            <md-tooltip md-delay="300">Add activity resource</md-tooltip>
          </span>
        </el-col>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import api from '@/api/api';
import notify from '@/utils/notificationsUtils';
import {
  Form,
  FormItem,
  Input,
  DatePicker,
  Button,
  Col,
  Checkbox,
  Select,
  Option,
  InputNumber,
  Slider,
} from 'element-ui';
import draggable from 'vuedraggable';
import UpdateStatus from '../../UpdateStatus';
import ArchivedResources from './ArchivedResources.vue';

export default {
  name: 'resources',
  components: {
    draggable,
    [Form.name]: Form,
    [Input.name]: Input,
    [DatePicker.name]: DatePicker,
    [FormItem.name]: FormItem,
    [Button.name]: Button,
    [Col.name]: Col,
    [Select.name]: Select,
    [Option.name]: Option,
    [InputNumber.name]: InputNumber,
    [Slider.name]: Slider,
    [Checkbox.name]: Checkbox,
    ArchivedResources,
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
    activity: {
      type: Object,
      required: true,
    },
    parentContainer: {
      type: Object,
      required: true,
    },
    playbook: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isModificationActive: false,
      addResourceFormDisplayed: false,
      newResource: {},
      status: UpdateStatus.SAVED,
      UpdateStatus,
      resourcesCopy: [],
    };
  },
  mounted() {
    this.saveResourcesToCopy();
  },
  computed: {
    resources: {
      get() {
        return this.activity.resources;//.filter((r) => !r.isArchived);
      },
      set(resources) {
        this.activity.resources = resources;
        //this.activity.resources.concat(this.activity.resources.filter((r) => r.isArchived));
      },
    },
  },
  methods: {
    // reordering
    onChangeResources(event) {
      //console.log('playbook event', event);
      if (event.added) {
        // move between lists is currently unsupported for Resources
      } else if (event.removed) {
        // Remove is only triggered when we add the element to another list, so we manage that in the add event
      } else if (event.moved) {
        const newIndex = event.moved.newIndex + (event.moved.newIndex > event.moved.oldIndex ? 1 : 0);
        this.onMoveResourceToList(event.moved.element, newIndex);
      }
    },
    onMoveResourceToList(resource, index) {
      const moveResourceCommand = {
        type: 'MoveResourceCommand',
        // eslint-disable-next-line no-underscore-dangle
        playbookId: this.playbook._id,
        activityId: this.activity.id,
        resourceId: resource.id,
        index,
      };
      const answerPayload = {
        action: 'WORK',
        taskWorkDTO: {
          type: 'playbook task',
          command: moveResourceCommand,
        },
      };
      this.activitiesStatus = UpdateStatus.UPDATING;
      api.assess
        .post(`/tasks/${this.task.id}`, answerPayload)
        .then((response) => {
          this.fetchPlaybook();
          this.activitiesStatus = UpdateStatus.SAVED;
        })
        .catch((error) => {
          const errorMessage = `Could not reorder Playbook's conditions : ${error.response.data
            .message || ''}`;
          notify.notifyError(errorMessage, this);
          this.activitiesStatus = UpdateStatus.FAILED;
        });
    },
    // -------- Navigation ------------
    toggleEditResource() {
      this.isModificationActive = !this.isModificationActive;
    },
    displayAddResourceForm() {
      this.addResourceFormDisplayed = true;
      this.$nextTick(() => {
        this.$refs.resourceInput.focus();
      });
    },
    hideAddResourceForm() {
      this.addResourceFormDisplayed = false;
    },
    // -------- Resource actions ---------
    onResourceFormChange(oldValue, newValue) {
      if (oldValue !== newValue) {
        this.status = UpdateStatus.EDITING;
      } else {
        this.status = UpdateStatus.SAVED;
      }
    },
    createNewResource() {
      if (this.addResourceFormDisplayed) {
        if (this.newResource.link) {
          this.sendResourceCommand({
            type: 'AddResourceCommand',
            name: this.newResource.link,
            link: this.newResource.link,
          }).then(() => {
            this.newResource = {};

            //console.log('Resource successfully created');
            this.status = UpdateStatus.SAVED;
            this.fetchPlaybook();
          });
        }
        this.hideAddResourceForm();
      }
    },
    sendUpdateResourceCommand(partialCommand) {
      if (this.status !== UpdateStatus.SAVED) {
        this.sendResourceCommand({
          type: 'UpdateResourceCommand',
          ...partialCommand,
        })
          .then(() => {
            this.isFormModified = false;
            this.status = UpdateStatus.SAVED;
          });
      }
    },
    archiveResource(resource) {
      const index = this.activity.resources.indexOf(resource);
      if (index !== -1) {
        this.sendResourceCommand({
          type: 'ArchiveResourceCommand',
          resourceId: resource.id,
        })
          .then(() => {
            this.fetchPlaybook();
            this.status = UpdateStatus.SAVED;
          }).catch(() => {
            this.status = UpdateStatus.FAILED;
          });
      }
    },
    sendResourceCommand(partialCommand) {
      const resourceCommand = {
        // eslint-disable-next-line no-underscore-dangle
        playbookId: this.playbook._id,
        activityId: this.activity.id,
        ...partialCommand,
      };
      const answerPayload = {
        action: 'WORK',
        taskWorkDTO: {
          type: 'playbook task',
          command: resourceCommand,
        },
      };
      this.status = UpdateStatus.UPDATING;
      return api.assess
        .post(`/tasks/${this.task.id}`, answerPayload)
        .catch((error) => {
          const errorMessage = `Could not save Resource : ${
            error.response.data.message || ''
          }`;
          notify.notifyError(errorMessage, this);
          this.status = UpdateStatus.FAILED;
        });
    },
    // --------- Fetch playboook ---------
    fetchPlaybook() {
      const payload = {
        taskId: this.task.id,
        vm: this,
      };
      return this.$store.dispatch('playbooks/fetchPlaybook', payload).then(this.saveResourcesToCopy);
    },
    saveResourcesToCopy() {
      this.resourcesCopy = [];
      Object.values(this.activity.resources).forEach((resource) => {
        const resourceCopy = {
          name: resource.name,
          link: resource.link,
        };
        this.resourcesCopy.push(resourceCopy);
      });
    },
    // ------- Utils ----------
    cleanHttpLink(link) {
      return (/^http[s]*:\/\/[\w]+/i.test(link) ? '' : 'https://') + link;
    },
  },
};
</script>

<style lang="scss" scoped>
hr {
  border-width: 1px 0px 0px 0px;
  border-style: solid;
  border-color: #c8c8c8;
}
.resource-card {
  padding-top: 2rem;
  .hide-button{
    transition: opacity 0.3s ease-out;
    opacity: 0;
  }
  &:hover .hide-button{
    transition: opacity 0.3s ease-in;
    opacity: 1;
  }
}
.section-title {
  margin-bottom: 1rem;
  padding-left: 1.2rem;
}
.create-additional-property {
  margin-top: 1rem;
}
.button-container {
  padding-left: 1rem;
  text-align: right;
}
.line {
  text-align: center;
}
.align-right {
  text-align: right;
}
.additional-properties-form {
  .delete-button {
    transition: opacity 0.3s ease-out;
    opacity: 0;
  }
  &:hover .delete-button {
    transition: opacity 0.3s ease-in;
    opacity: 1;
  }
}
.cursor-pointer {
  cursor: pointer;
}
</style>
