var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('div',[_c('loader')],1):(_vm.showActivityDetails)?_c('div',[_c('activity-details',{attrs:{"activity":_vm.selectedActivity,"playbook":_vm.playbook,"parentContainer":_vm.selectedParentContainer,"task":_vm.task},on:{"viewPlaybook":_vm.onViewPlaybook}})],1):(_vm.showCategoryDetails)?_c('div',[_c('category-details',{attrs:{"category":_vm.selectedCategory,"playbook":_vm.playbook,"parentContainer":_vm.selectedParentContainer,"task":_vm.task},on:{"viewPlaybook":_vm.onViewPlaybook,"viewCategory":_vm.onViewCategory,"viewActivity":_vm.onViewActivity}})],1):(_vm.showEventsFeed)?_c('div',[_c('playbook-events-feed',{attrs:{"playbook":_vm.playbook,"taskId":_vm.task.id},on:{"viewPlaybook":_vm.onViewPlaybook}})],1):_c('div',{class:{ 'answer-loading': _vm.answersLoading }},[_c('div',{staticClass:"import-export"},[_c('span',{staticClass:"show-events-feed-button",on:{"click":_vm.onShowEventsFeed}},[_c('md-icon',{staticClass:"icon-action-big icon-add"},[_vm._v("dynamic_feed")]),_c('md-tooltip',{attrs:{"md-delay":"300"}},[_vm._v("Show Events Feed")])],1),_c('el-dropdown',{staticStyle:{"cursor":"pointer"}},[_c('span',{staticClass:"el-dropdown-link"},[_vm._v(" Import/Export"),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{nativeOn:{"click":function($event){return _vm.importPlaybook()}}},[_vm._v("Import")]),_c('el-dropdown-item',{nativeOn:{"click":function($event){return _vm.exportPlaybook()}}},[_vm._v("Export")])],1)],1)],1),_c('h5',{staticClass:"playbook-section-title"},[_vm._v(" Summary "),_c('span',{staticClass:"saving-button",class:{
          saved: _vm.summaryStatus === _vm.UpdateStatus.SAVED,
          failed: _vm.summaryStatus === _vm.UpdateStatus.FAILED,
          updating: _vm.summaryStatus === _vm.UpdateStatus.UPDATING,
          editing: _vm.summaryStatus === _vm.UpdateStatus.EDITING
        }})]),_c('div',{staticClass:"playbook-form-container"},[_c('el-form',{ref:"playbook",attrs:{"model":_vm.playbook,"label-width":"120px","rules":_vm.rules}},[_c('el-form-item',{attrs:{"label":"Id","prop":"id"}},[_c('el-input',{attrs:{"disabled":true},model:{value:(_vm.playbook._id),callback:function ($$v) {_vm.$set(_vm.playbook, "_id", $$v)},expression:"playbook._id"}})],1),_c('el-form-item',{attrs:{"label":"Playbook title","prop":"title"}},[_c('div',{staticClass:"form-input"},[_c('el-input',{attrs:{"type":"textarea","autosize":""},on:{"blur":function($event){_vm.playbookCopy.title != _vm.playbook.title
                  ? _vm.sendUpdatePlaybookCommand({ title: _vm.playbook.title })
                  : ''},"input":_vm.onSummaryInputChange},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return (function () { return _vm.playbookCopy.title != _vm.playbook.title
                    ? _vm.sendUpdatePlaybookCommand({ title: _vm.playbook.title })
                    : ''; }).apply(null, arguments)}},model:{value:(_vm.playbook.title),callback:function ($$v) {_vm.$set(_vm.playbook, "title", $$v)},expression:"playbook.title"}}),_c('history',{attrs:{"taskId":_vm.task.id,"playbookId":_vm.playbook._id,"field":"title"},on:{"restore":function($event){_vm.summaryStatus = _vm.UpdateStatus.EDITING;
                _vm.sendUpdatePlaybookCommand({ title: $event.value ||'' });}}})],1)]),_c('el-form-item',{attrs:{"label":"Time frame"}},[_c('el-col',{attrs:{"span":10}},[_c('div',{staticClass:"form-input"},[_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"type":"date","value-format":"yyyy-MM-dd","placeholder":"Start date","prop":"startDate"},on:{"change":function($event){_vm.playbookCopy.startDate != _vm.playbook.startDate &&
                    _vm.sendUpdatePlaybookCommand({ startDate: _vm.playbook.startDate || '' })},"input":_vm.onSummaryInputChange},model:{value:(_vm.playbook.startDate),callback:function ($$v) {_vm.$set(_vm.playbook, "startDate", $$v)},expression:"playbook.startDate"}}),_c('history',{attrs:{"taskId":_vm.task.id,"playbookId":_vm.playbook._id,"field":"startDate"},on:{"restore":function($event){_vm.summaryStatus = _vm.UpdateStatus.EDITING;
                  _vm.sendUpdatePlaybookCommand({ startDate: $event.value || '' });}}})],1)]),_c('el-col',{staticClass:"line",attrs:{"span":3}},[_vm._v("-")]),_c('el-col',{attrs:{"span":11}},[_c('div',{staticClass:"form-input"},[_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"type":"date","value-format":"yyyy-MM-dd","placeholder":"End date","prop":"endDate"},on:{"change":function($event){_vm.playbookCopy.endDate != _vm.playbook.endDate &&
                    _vm.sendUpdatePlaybookCommand({ endDate: _vm.playbook.endDate || '' })},"input":_vm.onSummaryInputChange},model:{value:(_vm.playbook.endDate),callback:function ($$v) {_vm.$set(_vm.playbook, "endDate", $$v)},expression:"playbook.endDate"}}),_c('history',{attrs:{"taskId":_vm.task.id,"playbookId":_vm.playbook._id,"field":"endDate"},on:{"restore":function($event){_vm.summaryStatus = _vm.UpdateStatus.EDITING;
                  _vm.sendUpdatePlaybookCommand({ endDate: $event.value || '' });}}})],1)])],1),_c('el-form-item',{attrs:{"label":"Description"}},[_c('div',{staticClass:"form-input"},[_c('el-input',{staticStyle:{"width":"100%"},attrs:{"type":"textarea","prop":"description","rows":"8"},on:{"blur":function($event){_vm.playbookCopy.description != _vm.playbook.description
                  ? _vm.sendUpdatePlaybookCommand({ description: _vm.playbook.description })
                  : ''},"input":_vm.onSummaryInputChange},model:{value:(_vm.playbook.description),callback:function ($$v) {_vm.$set(_vm.playbook, "description", $$v)},expression:"playbook.description"}}),_c('history',{attrs:{"taskId":_vm.task.id,"playbookId":_vm.playbook._id,"field":"description"},on:{"restore":function($event){_vm.summaryStatus = _vm.UpdateStatus.EDITING;
                _vm.sendUpdatePlaybookCommand({ description: $event.value ||'' });}}})],1)])],1)],1),_c('playbook-notes',{attrs:{"task":_vm.task,"playbook":_vm.playbook,"element":_vm.playbook,"containerType":'Playbook'}}),_c('additional-properties',{attrs:{"task":_vm.task,"playbook":_vm.playbook,"element":_vm.playbook}}),_c('h5',{staticClass:"playbook-section-title"},[_c('archived-categories',{attrs:{"playbookId":_vm.playbook._id,"taskId":_vm.task.id,"categories":_vm.playbook.categories.filter(function (e){ return e.isArchived; })},on:{"status":function($event){_vm.activitiesStatus = $event}}}),_vm._v(" Categories "),_c('span',{staticClass:"saving-button",class:{
          saved: _vm.activitiesStatus === _vm.UpdateStatus.SAVED,
          failed: _vm.activitiesStatus === _vm.UpdateStatus.FAILED,
          updating: _vm.activitiesStatus === _vm.UpdateStatus.UPDATING,
          editing: _vm.activitiesStatus === _vm.UpdateStatus.EDITING
        }})],1),_c('div',{staticClass:"categories-table-container"},[_c('draggable',{attrs:{"tag":"ul","animation":200,"group":{ name: 'all-categories', pull: 'all-categories', put: 'all-categories' },"handle":".handle"},on:{"change":function($event){return _vm.onChangeCategories($event)}},model:{value:(_vm.categories),callback:function ($$v) {_vm.categories=$$v},expression:"categories"}},_vm._l((_vm.categories),function(category){return _c('category-card',{key:category.id,attrs:{"category":category,"playbook":_vm.playbook,"task":_vm.task,"parentContainer":_vm.playbook},on:{"viewCategory":_vm.onViewCategory,"viewActivity":_vm.onViewActivity,"status":function($event){_vm.activitiesStatus = $event}}})}),1),_c('ul',[(!_vm.createCategoryButtonDisplayed)?_c('new-category-card',{on:{"saveNewCategory":_vm.createCategory,"closeDialogue":_vm.onCloseNewCategoryDialogue}}):_vm._e()],1),_c('div',{staticClass:"activities-table-footer"},[(_vm.createCategoryButtonDisplayed)?_c('span',{staticClass:"create-activity-button-container",on:{"click":_vm.showCreateCategoryForm}},[_c('md-icon',{staticClass:"icon-action icon-add"},[_vm._v("create_new_folder")]),_c('md-tooltip',{attrs:{"md-delay":"300"}},[_vm._v("Create Activity Category")])],1):_vm._e()])],1),_c('div',{staticClass:"categories-table-container"},[_c('h5',{staticClass:"playbook-section-title"},[_c('archived-activities',{attrs:{"playbookId":_vm.playbook._id,"taskId":_vm.task.id,"activities":_vm.playbook.activities.filter(function (e){ return e.isArchived; })},on:{"status":function($event){_vm.activitiesStatus = $event}}}),_vm._v(" Activities "),_c('span',{staticClass:"saving-button",class:{
          saved: _vm.activitiesStatus === _vm.UpdateStatus.SAVED,
          failed: _vm.activitiesStatus === _vm.UpdateStatus.FAILED,
          updating: _vm.activitiesStatus === _vm.UpdateStatus.UPDATING,
          editing: _vm.activitiesStatus === _vm.UpdateStatus.EDITING
        }})],1),_c('draggable',{attrs:{"tag":"ul","animation":200,"group":{ name: 'all-activities', pull: 'all-activities', put: 'all-activities' },"handle":".handleActivity"},on:{"change":function($event){return _vm.onChangeActivities($event)}},model:{value:(_vm.activities),callback:function ($$v) {_vm.activities=$$v},expression:"activities"}},_vm._l((_vm.activities),function(activity){return _c('activity-card',{key:activity.id,attrs:{"activity":activity,"parentContainer":_vm.playbook,"playbook":_vm.playbook,"task":_vm.task},on:{"viewActivity":_vm.onViewActivity,"status":function($event){_vm.activitiesStatus = $event}}})}),1),_c('ul',[(!_vm.createActivityButtonDisplayed)?_c('new-activity-card',{on:{"saveNewActivity":_vm.createActivity,"closeDialogue":_vm.onCloseNewActivityDialogue}}):_vm._e()],1),_c('div',{key:"footer",staticClass:"activities-table-footer",attrs:{"slot":"footer","role":"group"},slot:"footer"},[(_vm.createActivityButtonDisplayed)?_c('div',[_c('span',{staticClass:"create-activity-button-container",on:{"click":_vm.showCreateActivityForm}},[_c('md-icon',{staticClass:"icon-action icon-add"},[_vm._v("post_add")]),_c('md-tooltip',{attrs:{"md-delay":"300"}},[_vm._v("Create Activity")])],1)]):_vm._e()])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }