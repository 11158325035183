<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <label class="question-text">
        {{ question.label }}
      </label>
      <div v-if="hasQuestionDetail">
        <question-detail :detail="question.detail"> </question-detail>
      </div>
    </div>
    <div class="md-layout-item md-size-100 question-content-container">
      <question-content
        :question="question"
        :readOnly="readOnly"
        @onAnsweringQuestion="(payload) => onAnsweringQuestion(payload)"
        @onAnsweringFileQuestion="(payload) => onAnsweringFileQuestion(payload)"
        @downloadFile="onDownloadFile"
        @deleteFile="onDeleteFile"
      />
    </div>
  </div>
</template>
<script>
import QuestionDetail from '@/components/Avalia/TaskDetails/Form/QuestionType/QuestionDetail.vue';

export default {
  name: 'question',
  components: {
    QuestionDetail,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      answers: [],
    };
  },
  computed: {
    hasQuestionDetail() {
      return this.question.detail != null && this.question.detail.length > 0;
    },
  },
  methods: {
    onAnsweringQuestion(payload) {
      this.$emit('onAnsweringQuestion', payload);
    },
    onAnsweringFileQuestion(payload) {
      this.$emit('onAnsweringFileQuestion', payload);
    },
    onDownloadFile($event) {
      this.$emit('downloadFile', this.question.questionKey);
    },
    onDeleteFile($event) {
      this.$emit('deleteFile', this.question.questionKey);
    },
  },
};
</script>
<style lang="scss" scoped>
.question-content-container {
  margin-top: 20px;
}
</style>
