var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-layout md-size-100 md-medium-size-100"},[_c('div',{staticClass:"md-layout-item md-size-100 md-medium-size-100 nopadding"},[_c('div',{staticClass:"md-layout-item md-size-100 md-medium-size-100"},[_c('internal-breadcrumbs',{attrs:{"sourceName":_vm.playbook.title,"currentName":_vm.category.title},on:{"showSource":_vm.onShowSource}})],1),_c('div',[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-size-100 md-medium-size-100"},[_c('h5',{staticClass:"section-title"},[_vm._v(" Category "),_c('span',{staticClass:"saving-button",class:{
                  saved: _vm.status === _vm.UpdateStatus.SAVED,
                  failed: _vm.status === _vm.UpdateStatus.FAILED,
                  updating: _vm.status === _vm.UpdateStatus.UPDATING,
                  editing: _vm.status === _vm.UpdateStatus.EDITING
                }})]),_c('el-form',{ref:"category",attrs:{"model":_vm.category,"label-width":"120px","rules":_vm.rules}},[_c('el-form-item',{attrs:{"label":"Title","prop":"title"}},[_c('div',{staticClass:"form-input"},[_c('el-input',{attrs:{"type":"textarea","autosize":""},on:{"blur":function($event){_vm.categoryCopy.title != _vm.category.title
                        ? _vm.sendUpdateCategoryCommand({ title: _vm.category.title })
                        : ''},"input":function($event){return _vm.onCategoryFormInputChange(_vm.categoryCopy.title, _vm.category.title)}},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return (function () { return _vm.categoryCopy.title != _vm.category.title
                          ? _vm.sendUpdateCategoryCommand({ title: _vm.category.title })
                          : ''; }).apply(null, arguments)}},model:{value:(_vm.category.title),callback:function ($$v) {_vm.$set(_vm.category, "title", $$v)},expression:"category.title"}}),_c('history',{attrs:{"taskId":_vm.task.id,"playbookId":_vm.playbook._id,"targetId":_vm.category.id,"field":"title"},on:{"restore":function($event){_vm.status = _vm.UpdateStatus.EDITING;
                      _vm.sendUpdateCategoryCommand({ title: $event.value ||'' });}}})],1)]),_c('el-form-item',{attrs:{"label":"Summary","prop":"summary"}},[_c('div',{staticClass:"form-input"},[_c('el-input',{attrs:{"type":"textarea","autosize":""},on:{"blur":function($event){_vm.categoryCopy.summary != _vm.category.summary
                        ? _vm.sendUpdateCategoryCommand({ summary: _vm.category.summary })
                        : ''},"input":function($event){return _vm.onCategoryFormInputChange(_vm.categoryCopy.summary, _vm.category.summary)}},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return (function () { return _vm.categoryCopy.summary != _vm.category.summary
                          ? _vm.sendUpdateCategoryCommand({ summary: _vm.category.summary })
                          : ''; }).apply(null, arguments)}},model:{value:(_vm.category.summary),callback:function ($$v) {_vm.$set(_vm.category, "summary", $$v)},expression:"category.summary"}}),_c('history',{attrs:{"taskId":_vm.task.id,"playbookId":_vm.playbook._id,"targetId":_vm.category.id,"field":"summary"},on:{"restore":function($event){_vm.status = _vm.UpdateStatus.EDITING;
                      _vm.sendUpdateCategoryCommand({ summary: $event.value ||'' });}}})],1)])],1),_c('playbook-notes',{attrs:{"task":_vm.task,"playbook":_vm.playbook,"element":_vm.category,"containerType":'Category'}}),_c('additional-properties',{attrs:{"task":_vm.task,"playbook":_vm.playbook,"parentContainer":_vm.parentContainer,"element":_vm.category}})],1),_c('div',{staticClass:"md-layout-item md-size-100 md-medium-size-100 categories-table-container"},[_c('h5',{staticClass:"playbook-section-title"},[_c('archived-categories',{attrs:{"playbookId":_vm.playbook._id,"taskId":_vm.task.id,"categories":_vm.category.categories.filter(function (e){ return e.isArchived; })},on:{"status":function($event){_vm.categoriesStatus = $event}}}),_vm._v(" Categories "),_c('span',{staticClass:"saving-button",class:{
                  saved: _vm.categoriesStatus === _vm.UpdateStatus.SAVED,
                  failed: _vm.categoriesStatus === _vm.UpdateStatus.FAILED,
                  updating: _vm.categoriesStatus === _vm.UpdateStatus.UPDATING,
                  editing: _vm.categoriesStatus === _vm.UpdateStatus.EDITING
                }})],1),_c('draggable',{attrs:{"tag":"ul","animation":200,"group":{ name: 'all-categories', pull: 'all-categories', put: 'all-categories' },"handle":".handle"},on:{"change":function($event){return _vm.onChangeCategories($event)}},model:{value:(_vm.categories),callback:function ($$v) {_vm.categories=$$v},expression:"categories"}},_vm._l((_vm.categories),function(categoryIterator){return _c('category-card',{key:categoryIterator.id,attrs:{"category":categoryIterator,"playbook":_vm.playbook,"parentContainer":_vm.category,"task":_vm.task},on:{"viewCategory":_vm.onViewCategory,"viewActivity":_vm.onViewActivity,"status":function($event){_vm.categoriesStatus = $event}}})}),1),_c('ul',[(!_vm.createCategoryButtonDisplayed)?_c('new-category-card',{on:{"saveNewCategory":_vm.createCategory,"closeDialogue":_vm.onCloseNewCategoryDialogue}}):_vm._e()],1),_c('div',{staticClass:"activities-table-footer"},[(_vm.createCategoryButtonDisplayed)?_c('span',{staticClass:"create-activity-button-container",on:{"click":_vm.showCreateCategoryForm}},[_c('md-icon',{staticClass:"icon-action icon-add"},[_vm._v("create_new_folder")]),_c('md-tooltip',{attrs:{"md-delay":"300"}},[_vm._v("Create Activity Category")])],1):_vm._e()])],1),_c('div',{staticClass:"md-layout-item md-size-100 md-medium-size-100 categories-table-container"},[_c('h5',{staticClass:"playbook-section-title"},[_c('archived-activities',{attrs:{"playbookId":_vm.playbook._id,"taskId":_vm.task.id,"activities":_vm.category.activities.filter(function (e){ return e.isArchived; })},on:{"status":function($event){_vm.activitiesStatus = $event}}}),_vm._v(" Activities "),_c('span',{staticClass:"saving-button",class:{
                  saved: _vm.activitiesStatus === _vm.UpdateStatus.SAVED,
                  failed: _vm.activitiesStatus === _vm.UpdateStatus.FAILED,
                  updating: _vm.activitiesStatus === _vm.UpdateStatus.UPDATING,
                  editing: _vm.activitiesStatus === _vm.UpdateStatus.EDITING
                }})],1),_c('draggable',{attrs:{"tag":"ul","animation":200,"group":{ name: 'all-activities', pull: 'all-activities', put: 'all-activities' },"handle":".handleActivity"},on:{"change":function($event){return _vm.onChangeActivities($event)}},model:{value:(_vm.activities),callback:function ($$v) {_vm.activities=$$v},expression:"activities"}},_vm._l((_vm.activities),function(activity){return _c('activity-card',{key:activity.id,attrs:{"activity":activity,"parentContainer":_vm.category,"playbook":_vm.playbook,"task":_vm.task},on:{"viewActivity":_vm.onViewActivity,"status":function($event){_vm.activitiesStatus = $event}}})}),1),_c('ul',[(!_vm.createActivityButtonDisplayed)?_c('new-activity-card',{on:{"saveNewActivity":_vm.createActivity,"closeDialogue":_vm.onCloseNewActivityDialogue}}):_vm._e()],1),_c('div',{key:"footer",staticClass:"activities-table-footer",attrs:{"slot":"footer","role":"group"},slot:"footer"},[(_vm.createActivityButtonDisplayed)?_c('div',[_c('span',{staticClass:"create-activity-button-container",on:{"click":_vm.showCreateActivityForm}},[_c('md-icon',{staticClass:"icon-action icon-add"},[_vm._v("post_add")]),_c('md-tooltip',{attrs:{"md-delay":"300"}},[_vm._v("Create Activity")])],1)]):_vm._e()])],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }