<template>
  <div class="event-group md-layout" @click="emitFilterByGroupEvent">
    <div class="event-group-content md-layout-item md-layout md-size-100 nopadding">
      <div class="event-group-text md-layout-item">
        {{ group.nbEventsInGroup }} events
      </div>
    </div>
    <div class="event-group-footer md-layout-item md-size-100">
      <div v-if="group._id.author != null" class="event-group-author">
        <md-icon class="">person</md-icon>
        {{ getAuthorLabel(group._id.author) }}
      </div>
      <div v-if="group._id.targetName != null" class="event-group-author">
        target : {{ group._id.targetName }}
      </div>
      <div v-if="group._id.targetType != null" class="event-group-author">
        target type : {{ group._id.targetType }}
      </div>
      <div v-if="group._id.date != null" class="timestamp">
        <span class="timestep-bounds">Date: </span>{{ getLocalTimestamp(`${group._id.date}`)}}
      </div>
      <div v-else class="timestamp">
        <div v-if="group.firstDate != null && group.lastDate != null">
          <span class="timestep-bounds">From: </span>{{ getLocalTimestamp(`${group.firstDate}`)}}
          <span class="timestep-bounds">To: </span>{{ getLocalTimestamp(`${group.lastDate}`)}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'playbook-event-group',
  components: {},
  props: {
    group: Object,
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    emitFilterByGroupEvent() {
      // eslint-disable-next-line no-underscore-dangle
      this.$emit('filter', this.group._id);
    },
    getLocalTimestamp(timestamp) {
      const localTimestamp = moment(`${timestamp}`).local().format('YYYY-MM-DD');
      return localTimestamp;
    },
    getAuthorLabel(author) {
      if (author == null) {
        return 'anonymous';
      }
      if (author.firstName != null && author.lastName != null) {
        return `${author.firstName} ${author.lastName} `;
      }
      if (author.email != null) {
        return `${author.email} `;
      }
      return 'anonymous ';
    },
  },
};
</script>

<style lang="scss" scoped>
.event-group {
  background-color: rgba(188, 198, 204, 0.2);
  padding: 0.2em 0em 0.2em;
  margin: 0em 0.4em 0.4em 0em;
  border-radius: 0.6em;
  cursor: pointer;
  &:hover {
    background-color: rgba(173, 180, 183, 0.2);
  }
}

.icon-container {
  text-align: right;
  font-size: 0.8em;
  line-height: 1em;
}
.event-group-footer {
  display: flex;
  padding-bottom: 0.3rem;
}
.timestamp {
  margin-left: auto;
  color: $avalia-cyan;
  text-align: right;
}
.event-group-text {
  font-size: 0.9rem;
}
.event-group-author {
  font-weight: bold;
  padding-right: 0.3rem;
}
.timestep-bounds {
  color: $avalia-black;
}
</style>
