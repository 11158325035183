<template>
  <div>
    <div v-if="loading">
      <loader />
    </div>
    <div v-else :class="{ 'answer-loading': answersLoading}">
      <div class="form-container-content" v-if="formHasSections">
        <div>
          <el-steps :active="activeSection" finish-status="success" align-center class="stepper">
            <el-step
              v-for="(section, index) in formWithNotes.sections"
              :key="section.number"
              :title="section.title"
              @click.native="() => setActiveSection(index)"
            >
            </el-step>
          </el-steps>
          <div class="animation-container">
            <transition-group tag="div" :name="isSlideBack ? 'slideback' : 'slide'" mode="out-in">
              <div v-for="section in [currentSection]" :key="section.number">
                <form-section
                  :formSection="section"
                  :isTaskReadOnly="isTaskReadOnly"
                  :taskId="task.id"
                  @input="onQuestionInput($event, section)"
                  @fileInput="(payload) => onFileQuestionInput(payload, section)"
                  @downloadFile="questionKey => onDownloadFile(questionKey)"
                  @deleteFile="questionKey => onDeleteFile(questionKey, section.number)"
                  @onCreateNote="onCreateFormNote"
                  @onUpdateNote="onUpdateFormNote"
                  @onDeleteNote="onDeleteFormNote"
                  class="form-section"
                ></form-section>
              </div>
            </transition-group>
          </div>
        </div>
      </div>
      <div class="form-button-container">
        <div>
          <span v-if="!isTaskReadOnly" @click="onSaveWork(activeSection)">
            <md-icon class="icon-action-big icon-save">save</md-icon>
            <md-tooltip md-delay="300">Save Answers</md-tooltip>
          </span>
        </div>
        <div>
          <md-button
            v-if="formHasSections"
            :disabled="!hasPreviousSection"
            class=" md-sm nav-button"
            @click="onPrevious(activeSection)"
          >
            Previous
          </md-button>
          <md-button
            v-if="formHasSections"
            :disabled="!hasNextSection"
            class="md-sm nav-button"
            @click="onNext(activeSection)"
          >
            Next
          </md-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/api';
import notify from '@/utils/notificationsUtils';
import notesService from '@/services/notesService';
import FormSection from '@/components/Avalia/TaskDetails/Form/Section/FormSection.vue';
import Loader from '@/components/Avalia/Loader/Loader.vue';
import { Step, Steps } from 'element-ui';

export default {
  name: 'form-task',
  components: {
    FormSection,
    Loader,
    [Step.name]: Step,
    [Steps.name]: Steps,
  },
  props: {
    projectId: Number,
    isReadOnly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      answers: [],
      formWithNotes: null,
      activeSection: 0,
      tabChoose: 'tab-form',
      loading: true,
      isSlideBack: false,
      answersLoading: false,
    };
  },
  computed: {
    /*
    deckUrl() {
      const config = this.$store.getters['config/GET_CORE_CONFIG'];
      const slidesAppUrl = config.applications.slides.externalUrl;
      let target;
      if (!api.assess.defaults.baseURL.startsWith('http')) {
        // eslint-disable-next-line no-restricted-globals
        target = encodeURIComponent(`${location.origin}${api.assess.defaults.baseURL}/projects/${this.projectId}/slides`);
      } else {
        target = encodeURIComponent(`${api.assess.defaults.baseURL}/projects/${this.projectId}/slides`);
      }
      return `${slidesAppUrl}/decks/remote/1?url=${target}&relayUrl=${this.jwtRelayUrl}`;
    }, */
    task() {
      return this.$store.getters['task/GET_TASK'];
    },
    form() {
      return this.$store.getters['form/GET_FORM'];
    },
    taskNotes() {
      return this.$store.getters['notes/GET_TASK_NOTES'];
    },
    isTaskCompleted() {
      return this.task.state === 'completed';
    },
    isTaskLocked() {
      return this.task.state === 'locked';
    },
    isTaskReadOnly() {
      return this.isReadOnly || this.isTaskLocked;
    },
    formHasSections() {
      return this.formWithNotes && this.formWithNotes.sections && this.sectionsLength > 0;
    },
    hasUpdatedAnswers() {
      return this.answers && this.answers.length > 0;
    },
    sectionsLength() {
      return this.formWithNotes.sections.length;
    },
    hasNextSection() {
      return this.activeSection + 1 < this.sectionsLength;
    },
    hasPreviousSection() {
      return this.activeSection > 0;
    },
    currentSection() {
      return this.formWithNotes.sections[this.activeSection];
    },
  },
  watch: {
    'task.formId': function () {
      this.fetchFormAndMergeNotesOnInit();
    },
    /*activeSection(newActiveValue, oldActiveValue) {
      if (this.hasUpdatedAnswers) {
        this.onSaveWork();
      }
    },*/
  },
  created() {
    this.fetchFormAndMergeNotesOnInit();
    // On created
  },
  mounted() {},
  methods: {
    /** FETCH **/
    fetchFormAndMergeNotesOnInit() {
      this.loading = true;
      return this.fetchForm()
        .then(() => {
          const formNotes = this.getFormNotesFromTaskNotes(this.taskNotes);
          this.formWithNotes = this.mergeNotesIntoForm(this.form, formNotes);
        })
        .then(() => {
          this.loading = false;

          // set active section to the first section
          // eslint-disable-next-line radix
          //this.activeSection = this.formWithNotes.sections[0].number;
        });
    },
    fetchAndMergeFormAndNotes() {
      return this.fetchForm()
        .then((form) => {
          return this.fetchNotes();
        })
        .then(() => {
          const formNotes = this.getFormNotesFromTaskNotes(this.taskNotes);
          this.formWithNotes = this.mergeNotesIntoForm(this.form, formNotes);
        });
    },
    fetchAndMergeNotes() {
      return this.fetchNotes().then(() => {
        const formNotes = this.getFormNotesFromTaskNotes(this.taskNotes);
        this.formWithNotes = this.mergeNotesIntoForm(this.form, formNotes);
      });
    },
    fetchForm() {
      const payload = {
        taskId: this.task.id,
        vm: this,
      };
      return this.$store.dispatch('form/fetchForm', payload);
    },
    /** FETCH - NOTE */
    /* Notes need to be fetch and modified here because of they need to be link to the right question */
    fetchNotes() {
      const payload = {
        taskId: this.task.id,
        vm: this,
      };
      return this.$store.dispatch('notes/fetchTaskNotes', payload);
    },
    mergeNotesIntoForm(form, notes) {
      return {
        sections: form.sections.map((section) => {
          return {
            ...section,
            questions: section.questions.map((question) => {
              return {
                ...question,
                notes: this.getQuestionNotes(notes, question.questionKey),
              };
            }),
          };
        }),
      };
    },
    getFormNotesFromTaskNotes(taskNotes) {
      return taskNotes.filter((note) => note.metadata.some(
        (metadata) => metadata.key === 'relatedElement' && metadata.value === 'form',
      ));
    },
    getQuestionNotes(notes, questionKey) {
      let questionNotes = [];
      if (notes && notes.length > 0) {
        questionNotes = notes.filter((note) => note.metadata.some(
          (metadata) => metadata.key === 'questionKey' && metadata.value === questionKey,
        ));
      }
      return questionNotes;
    },
    /** CREATE - NOTE **/
    // questionKey and questionLabel added in FormSection
    onCreateFormNote({ notePayload, questionKey, questionLabel }) {
      const createFormNotePayload = this.createFormNotePayload(
        notePayload,
        questionKey,
        questionLabel,
      );
      api.assess
        .post(`/tasks/${this.task.id}/notes`, createFormNotePayload)
        .then((response) => {
          this.showSuccessMessageAndRefetchNotes('Note successfully Saved');
        })
        .catch((error) => {
          const errorMessage = `Could not Save Note : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, this);
        });
    },
    createFormNotePayload(notePayload, questionKey, questionLabel) {
      return {
        ...notePayload,
        metadata: [
          {
            key: 'relatedElement',
            value: 'form',
          },
          {
            key: 'relatedElementName',
            value: this.task.name,
          },
          {
            key: 'relatedElementLink',
            value: `/project/${this.projectId}/task/${this.task.id}`,
          },
          {
            key: 'questionKey',
            value: questionKey,
          },
          {
            key: 'questionLabel',
            value: questionLabel,
          },
        ],
      };
    },
    /** UPDATE - NOTE **/
    onUpdateFormNote(notePayload) {
      notesService.updateNote(notePayload, this).then((response) => this.fetchAndMergeNotes());
    },
    /** DELETE - NOTE **/
    onDeleteFormNote(notePayload) {
      notesService.deleteNote(notePayload, this).then(() => this.fetchAndMergeNotes());
    },
    /** UTILITIES METHODS - REFETCH NOTES/FORMS **/
    showSuccessMessageAndRefetchNotes(message) {
      this.fetchAndMergeNotes();
      notify.notifySuccess(message, this);
    },
    showSuccessMessageAndRefetchFormAndNotes(message) {
      notify.notifySuccess(message, this);
      return this.fetchAndMergeFormAndNotes();
    },
    /** SAVED - QUESTION **/
    submitAnswers() {
      const answerPayload = {
        action: 'WORK',
        taskWorkDTO: {
          type: 'form task',
          payload: {
            formId: this.task.formId,
            answers: this.answers,
          },
        },
      };
      this.answersLoading = true;
      return api.assess
        .post(`/tasks/${this.task.id}`, answerPayload)
        .then((response) => {
          this.answers = [];
          return this.showSuccessMessageAndRefetchFormAndNotes(
            `Answers for Form ${this.form.name} successfully Saved`,
          ).then(() => this.answersLoading = false);
        })
        .catch((error) => {
          this.answersLoading = false;
          const errorMessage = `Could not Save Answers for form ${this.form.name} : ${error.response
            .data.message || ''}`;
          notify.notifyError(errorMessage, this);
        });
    },
    savePendingModifications() {
      if (this.hasUpdatedAnswers) {
        this.submitAnswers();
      }
    },
    onSaveWork() {
      if (this.hasUpdatedAnswers) {
        return this.submitAnswers();
      }
      return null;
    },
    /** TABS **/
    onTabChanged(event) {
      // Save potentially unsaved answers
      if (this.hasUpdatedAnswers) {
        this.submitAnswers();
      }
      // Save potentially unsaved expert opinion
      this.$refs.expertOpinion.saveModifications();
      // Save potentially unsaved notes - Talk with Guillaume about this
      // EventBus.$emit('saveNotes');

      // Scroll tab back up on tab change
      const elements = document.getElementsByClassName('md-tabs-content');
      const element = elements[0];
      if (element) {
        element.scrollTop = 0;
      }
      this.tabChoose = event;
    },
    onNext(activeSection) {
      if (this.hasNextSection) {
        this.setActiveSection(this.activeSection + 1);
      }
    },
    onPrevious(activeSection) {
      if (this.hasPreviousSection) {
        this.setActiveSection(this.activeSection - 1);
      }
    },
    setActiveSection(sectionNumber) {
      if (sectionNumber > this.activeSection) {
        this.isSlideBack = false;
      } else {
        this.isSlideBack = true;
      }
      if (this.hasUpdatedAnswers) {
        this.onSaveWork().then(() => {
          this.activeSection = sectionNumber;
        });
      } else {
        this.activeSection = sectionNumber;
      }
      this.scrollBackUp();
    },
    /** QUESTION ANSWERING **/
    onQuestionInput({ answerContent, question }, section) {
      if (!this.isReadOnly) {
        this.insertOrUpdateAnswer(answerContent, question, section);
      }
    },
    insertOrUpdateAnswer(value, question, section) {
      const index = this.answers.findIndex((e) => e.questionKey === question.questionKey);
      if (index === -1) {
        this.answers.push({
          questionKey: question.questionKey,
          sectionNumber: section.number,
          answerType: question.answer.answerType,
          answerContent: value,
        });
      } else {
        this.answers[index].answer = value;
      }
    },
    /** FILE QUESTION ANSWERING **/
    onFileQuestionInput({ file, question }, section) {
      const filenameWithoutExtension = file.name.substr(0, file.name.lastIndexOf('.'));
      const formData = new FormData();
      formData.append('name', filenameWithoutExtension);
      formData.append('description', '');
      formData.append('formId', this.task.formId);
      formData.append('questionKey', question.questionKey);
      formData.append('sectionNumber', section.number);
      formData.append('file', file);

      api.assess
        .post(`/tasks/${this.task.id}/file`, formData)
        .then((response) => {
          this.showSuccessMessageAndRefetchFormAndNotes(
            `File successfully uploaded for question of Form ${this.form.name}`,
          );
        })
        .catch((error) => {
          const errorMessage = `Could not upload File for question of Form ${
            this.form.name
          } : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, this);
          this.fetchAndMergeFormAndNotes();
        });
    },
    /** DELETE FILE **/
    onDeleteFile(questionKey, sectionNumber) {
      api.assess
        .delete(`/tasks/${this.task.id}/file?formId=${this.task.formId}&questionKey=${questionKey}&sectionNumber=${sectionNumber}`)
        .then((response) => {
          this.showSuccessMessageAndRefetchFormAndNotes(
            `File successfully deleted for question of Form ${this.form.name}`,
          );
        })
        .catch((error) => {
          const errorMessage = `Could not delete File for question of Form ${this.form.name} : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, this);
        });
    },
    /** DOWNLOAD FILE **/
    onDownloadFile(questionKey) {
      api.assess
        .get(
          `/tasks/${this.task.id}/file?questionKey=${questionKey}`,
          { responseType: 'blob' },
        )
        .then((response) => {
          this.downloadFileClientSide(response);
          this.showSuccessMessageAndRefetchFormAndNotes(
            `File successfully downloaded for question of Form ${this.form.name}`,
          );
        })
        .catch((error) => {
          const errorMessage = `Could not download File for question of Form ${this.form.name} : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, this);
        });
    },
    downloadFileClientSide(response) {
      const headerContent = response.headers['content-disposition'];
      const startFileNameIndex = headerContent.indexOf('"') + 1;
      const endFileNameIndex = headerContent.lastIndexOf('"');
      const filename = headerContent.substring(
        startFileNameIndex,
        endFileNameIndex,
      );
      const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      link.click();
    },
    /** UTILS **/
    scrollBackUp() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-title {
  margin-top: 0rem;
}
.card-form-task {
  margin-bottom: 0rem;
}
// CUSTOM BUTTONS TO MOVE TO avalia.scss
.save-button {
  margin-right: 1rem;

  color: #27eb68 !important;
  background-color: white !important;
  border-style: solid !important;
  border-width: 2px !important;
  &:hover {
    color: white !important;
    background-color: #27eb68 !important;
    opacity: 0.8 !important;
    border-color: #27eb68 !important;
  }
}
.nav-button {
  margin-left: 0.2rem;

  color: $avalia-gray !important;
  background-color: white !important;
  border-style: solid !important;
  border-width: 2px !important;
  &:hover {
    color: white !important;
    background-color: $avalia-gray !important;
    opacity: 0.8 !important;
    border-color: $avalia-gray !important;
  }
}
.form-button-container {
  display: flex;
  justify-content: space-between;
  padding: 0rem 0.9rem 0rem;;
}
.stepper {
  margin-top: 20px;
  margin-bottom: 14px;
}
.animation-container {
  position: relative;
}
/* TRANSITION */
.slide-leave-active,
.slide-enter-active {
  transition: 1s;
}
.slide-enter {
  transform: translate(100%, 0%);
}
.slide-leave,
.slideback-leave {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.slide-leave-to {
  transform: translate(-100%, 0%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.answer-loading {
  cursor: progress;
}
.slideback-leave-active,
.slideback-enter-active {
  transition: 1s;
}
.slideback-enter {
  transform: translate(-100%, 0%);
}
.slideback-leave-to {
  transform: translate(100%, 0%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
<!-- element ui css override -->
<style lang="scss">
.el-step__title {
  line-height: 1.5em;
}
</style>
