<template>
  <md-card md-with-hover class="activity-card">
    <md-card-header class="md-card-header-icon">
        <div class="md-layout">
            <div class="md-layout-item md-size-95 title-container"
              @keydown.enter.prevent.stop="() => submitForm('newActivity')">
                <el-form :model="newActivity" label-width="120px" :rules="rules" ref="newActivity" class="new-activity-form">
                  <el-form-item label="New activity title" prop="title">
                    <el-input
                      type="textarea"
                      autosize
                      v-model="newActivity.title"
                      ref="titleInput"
                    >
                    </el-input>
                  </el-form-item>
                </el-form>
            </div>
            <div class="md-layout-item md-size-5"
              @click="closeDialogue()">
              <md-icon class="icon-action icon-delete">close</md-icon>
            </div>
        </div>
    </md-card-header>
  </md-card>
</template>

<script>
import {
  Form, FormItem, Input, Col,
} from 'element-ui';

export default {
  components: {
    [Form.name]: Form,
    [Input.name]: Input,
    [Col.name]: Col,
    [FormItem.name]: FormItem,
  },
  name: 'new-activity-card',
  props: {
  },
  data() {
    return {
      newActivity: {
        title: '',
      },
      rules: {
        title: [
          { required: true, message: 'Please input new Activity title', trigger: 'blur' },
        ],
      },
    };
  },
  computed: {
  },
  mounted() {
    this.$refs.titleInput.focus();
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit('saveNewActivity', this.newActivity);
        }
      });
    },
    closeDialogue() {
      this.$emit('closeDialogue');
    },
  },
};
</script>

<style lang="scss" scoped>
.activity-card {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: auto;
}
.md-card-header {
  &::v-deep .card-icon {
    margin-top: 0.8rem;
    padding: 8px;
    background-color: $avalia-cyan;
  }
  &::v-deep .title {
    margin-top: 8px;
  }
}
.title-container {
    padding-top: 1rem;
    padding-left: 0rem;
    display: flex;
}
.actions-container {
    margin-left: auto;
    padding-left: 1rem;
}
.new-activity-form{
  width: 100%;
}
</style>
