<template>
  <div class="archive">
    <span>
      <md-icon class="icon-action" tabindex="-1" v-popover:archive slot="reference">restore</md-icon>
      <md-tooltip md-delay="300">Show archived elements</md-tooltip>
    </span>
    <el-popover
        placement="bottom-start"
        title="Archived notes"
        ref="archive"
        trigger="click"
        width="400"
        v-model="popoverVisible">
        <div class="archive-popover">
          <table class="archive-table">
              <tr>
                  <th style="min-width:120px;">Note</th>
                  <th colspan="2" style="min-width:50px;">Actions</th>
              </tr>
              <tr v-for="(note, i) in notes" :key="i">
                  <td style="vertical-align:top" :title="note.text">
                    {{getEllipsedNoteText(note.text)}}
                  </td>
                  <td style="vertical-align:top">
                    <md-icon class="icon-action" @click.native="unarchive(note)" >unarchive</md-icon>
                    <md-tooltip class="archive-action-tooltip" md-delay="300">Unarchive note</md-tooltip>
                  </td>
                  <td style="vertical-align:top">
                    <md-icon class="icon-action" @click.native="showDeletionConfirmationPopUp(note)" >delete</md-icon>
                    <md-tooltip class="archive-action-tooltip" md-delay="300">Delete note permanently</md-tooltip>
                  </td>
              </tr>
          </table>
        </div>
    </el-popover>
  </div>
</template>

<script>
import api from '@/api/api';
import notify from '@/utils/notificationsUtils';
import {
  Popover,
  Button,
  Tooltip,
  InfiniteScroll,
} from 'element-ui';
import Swal from 'sweetalert2';
import UpdateStatus from '../../UpdateStatus';

const swalWithCustomButtons = Swal.mixin({
  customClass: {
    confirmButton: 'md-button md-success btn-fill',
    cancelButton: 'md-button md-danger btn-fill',
  },
  buttonsStyling: false,
});

export default {
  components: {
    [Button.name]: Button,
    [Popover.name]: Popover,
    [Tooltip.name]: Tooltip,
  },
  directives: {
    [InfiniteScroll.name]: InfiniteScroll,
  },
  name: 'archived-playbook-notes',
  props: {
    taskId: {
      type: Number,
      required: true,
    },
    playbookId: {
      type: String,
      required: true,
    },
    elementId: {
      type: String,
      required: false,
    },
    notes: {
      type: Array,
      required: true,
      default: () => [],
      description: 'This is the  list of archived notes.',
    },
    containerType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      popoverVisible: false,
      activityIdKey: 'activityId',
      categoryIdKey: 'categoryId',
    };
  },
  methods: {
    getEllipsedNoteText(text) {
      const textLength = text.length;
      return textLength > 50 ? `${text.substring(0, 47)}...` : text;
    },
    showDeletionConfirmationPopUp(note) {
      this.isDeletionPopUpOpen = true;
      swalWithCustomButtons
        .fire({
          title: 'Are you sure you want to permanently delete this note :',
          text: `"${note.text}"`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, remove it!',
          buttonsStyling: false,
          onClose: this.closeIsDeletionPopUpOpen,
        })
        .then((result) => {
          if (result.value) {
            this.delete(note);
          }
        });
    },
    delete(note) {
      const deleteNoteCommand = {
        // eslint-disable-next-line no-underscore-dangle
        playbookId: this.playbookId,
        noteId: note.id,
        type: `Delete${this.containerType}NoteCommand`,
      };

      // Add required field according to container type (if container is a Playbook, we don't need to add any field)
      if (this.containerType === 'Activity') {
        deleteNoteCommand[this.activityIdKey] = this.elementId;
      } else if (this.containerType === 'Category') {
        deleteNoteCommand[this.categoryIdKey] = this.elementId;
      }

      const taskWorkCommand = {
        action: 'WORK',
        taskWorkDTO: {
          type: 'playbook task',
          command: deleteNoteCommand,
        },
      };
      this.$emit('status', UpdateStatus.UPDATING);
      return api.assess
        .post(`/tasks/${this.taskId}`, taskWorkCommand)
        .then((response) => {
          //console.log('Condition successfully deleted');
          this.fetchPlaybook();
          this.popoverVisible = false;
          this.$emit('status', UpdateStatus.SAVED);
        })
        .catch((error) => {
          const errorMessage = `Could not delete note : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, this);
          this.$emit('status', UpdateStatus.FAILED);
        });
    },
    unarchive(note) {
      const unarchiveNoteCommand = {
        type: `Unarchive${this.containerType}NoteCommand`,
        // eslint-disable-next-line no-underscore-dangle
        playbookId: this.playbookId,
        noteId: note.id,
      };
      // Add required field according to container type (if container is a Playbook, we don't need to add any field)
      if (this.containerType === 'Activity') {
        unarchiveNoteCommand[this.activityIdKey] = this.elementId;
      } else if (this.containerType === 'Category') {
        unarchiveNoteCommand[this.categoryIdKey] = this.elementId;
      }
      const taskWorkCommand = {
        action: 'WORK',
        taskWorkDTO: {
          type: 'playbook task',
          command: unarchiveNoteCommand,
        },
      };
      this.$emit('status', UpdateStatus.UPDATING);
      api.assess
        .post(`/tasks/${this.taskId}`, taskWorkCommand)
        .then((response) => {
          this.fetchPlaybook();
          this.popoverVisible = false;
          this.$emit('status', UpdateStatus.SAVED);
        })
        .catch((error) => {
          const errorMessage = `Could not archive note : ${error.response
            .data.message || ''}`;
          notify.notifyError(errorMessage, this);
          this.$emit('status', UpdateStatus.FAILED);
        });
    },
    fetchPlaybook() {
      const payload = {
        taskId: this.taskId,
        vm: this,
      };
      return this.$store.dispatch('playbooks/fetchPlaybook', payload);
    },
  },
};
</script>

<style lang="scss" scoped >
.archive{
  display: inline-block;
}
.archive-popover{
  max-height: 400px;
  overflow-y: auto;
  z-index: 4000;
}
.archive-table{
  width: 100%;
}
.archive-action-tooltip {
  z-index: 5000;
}
</style>
