<template>
  <div class="md-layout activity-card draggable-item" v-if="!activity.isArchived">
    <md-card md-with-hover class="md-layout-item ">
      <md-card-content class="md-layout">
        <div class="md-layout-item-10 ">
          <div class="md-layout-item card-icon noshadow">
            <md-icon class="handleActivity">drag_handle</md-icon>
          </div>
        </div>
        <div class="md-layout-item title-container" @click="viewActivityCardClicked(activity, $event)">
          <h4 class="title">{{getActivityCode}} {{ truncateTitle(activity.title, 55) }}
            <span v-if="activity.isFlagged">
              <md-icon class="md-icon red-flag">flag</md-icon>
              <md-tooltip md-delay="300">Flagged activity</md-tooltip>
            </span>
          </h4>
        </div>
        <div class="md-layout-item-10 hide-button" @click="cloneActivity()">
          <md-icon class="md-layout-item icon-action icon-save" >content_copy</md-icon>
          <md-tooltip md-delay="300">Duplicate activity</md-tooltip>
        </div>
        <div class="md-layout-item-10 hide-button" @click="onArchiveActivity()">
          <md-icon class="md-layout-item icon-action icon-delete" >archive</md-icon>
          <md-tooltip md-delay="300">Archive activity </md-tooltip>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import moment from 'moment';
import api from '@/api/api';
import notify from '@/utils/notificationsUtils';
import Swal from 'sweetalert2';
import UpdateStatus from '../UpdateStatus';

const swalWithCustomButtons = Swal.mixin({
  customClass: {
    confirmButton: 'md-button md-success btn-fill',
    cancelButton: 'md-button md-danger btn-fill',
  },
  buttonsStyling: false,
});

export default {
  components: {},
  name: 'activity-card',
  props: {
    activity: {
      type: Object,
      required: true,
    },
    parentContainer: {
      type: Object,
      required: true,
    },
    playbook: {
      type: Object,
      required: true,
    },
    task: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      numberOfCharInDescription: 220,
      isDeletionPopUpOpen: false,
    };
  },
  computed: {
    getActivityCode() {
      return (this.activity.activityCode != null && this.activity.activityCode.length > 0) ? `${this.activity.activityCode} - ` : '';
    },
  },
  methods: {
    cloneActivity() {
      const cloneActivityCommand = {
        type: 'CloneActivityCommand',
        // eslint-disable-next-line no-underscore-dangle
        playbookId: this.playbook._id,
        activityId: this.activity.id,
      };
      const answerPayload = {
        action: 'WORK',
        taskWorkDTO: {
          type: 'playbook task',
          command: cloneActivityCommand,
        },
      };

      this.$emit('status', UpdateStatus.UPDATING);
      return api.assess
        .post(`/tasks/${this.task.id}`, answerPayload)
        .then((response) => {
          console.log('Activity successfully Created');
          this.fetchPlaybook();
          this.createCategoryButtonDisplayed = true;
          this.$emit('status', UpdateStatus.SAVED);
        })
        .catch((error) => {
          const errorMessage = `Could not save playbook : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, this);
          this.$emit('status', UpdateStatus.FAILED);
        });
    },
    descriptionContent(description) {
      if (description) {
        const cleanedDescription = description.replace(/<\/?[^>]+(>|$)/g, '');
        return cleanedDescription.length < this.numberOfCharInDescription
          ? cleanedDescription
          : `${cleanedDescription.substring(0, this.numberOfCharInDescription)
          }...`;
      }
      return '-';
    },
    dateFormat(date) {
      if (date === undefined) {
        return '';
      }
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
    viewActivityCardClicked(activity) {
      this.$emit('viewActivity', { activity, parentContainer: this.parentContainer });
    },
    fetchPlaybook() {
      const payload = {
        taskId: this.task.id,
        vm: this,
      };
      return this.$store.dispatch('playbooks/fetchPlaybook', payload);
    },
    deleteActivity() {
      const deleteActivityCommand = {
        // eslint-disable-next-line no-underscore-dangle
        playbookId: this.playbook._id,
        activityId: this.activity.id,
        type: 'DeleteActivityCommand',
      };
      const taskWorkCommand = {
        action: 'WORK',
        taskWorkDTO: {
          type: 'playbook task',
          command: deleteActivityCommand,
        },
      };
      this.$emit('status', UpdateStatus.UPDATING);
      return api.assess
        .post(`/tasks/${this.task.id}`, taskWorkCommand)
        .then((response) => {
          this.fetchPlaybook();
          this.$emit('status', UpdateStatus.SAVED);
        })
        .catch((error) => {
          const errorMessage = `Could not save Activity : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, this);
          this.$emit('status', UpdateStatus.FAILED);
        });
    },
    showDeletionConfirmationPopUp() {
      this.isDeletionPopUpOpen = true;
      swalWithCustomButtons
        .fire({
          title: 'Are you sure you want to archive this activity :',
          text: `"${this.activity.title}"`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, archive it!',
          buttonsStyling: false,
          onClose: this.closeIsDeletionPopUpOpen,
        })
        .then((result) => {
          if (result.value) {
            this.onArchiveActivity();
          }
        });
    },
    onArchiveActivity() {
      const archiveActivitiesCommand = {
        type: 'ArchiveActivityCommand',
        // eslint-disable-next-line no-underscore-dangle
        playbookId: this.playbook._id,
        activityId: this.activity.id,
      };
      const answerPayload = {
        action: 'WORK',
        taskWorkDTO: {
          type: 'playbook task',
          command: archiveActivitiesCommand,
        },
      };
      this.$emit('status', UpdateStatus.UPDATING);
      api.assess
        .post(`/tasks/${this.task.id}`, answerPayload)
        .then((response) => {
          this.fetchPlaybook();
          this.$emit('status', UpdateStatus.SAVED);
        })
        .catch((error) => {
          const errorMessage = `Could not archive activity : ${error.response
            .data.message || ''}`;
          notify.notifyError(errorMessage, this);
          this.$emit('status', UpdateStatus.FAILED);
        });
    },
    closeIsDeletionPopUpOpen() {
      // Didn't find other solution for this value change after loosing focus while clicking on cancel button
      setTimeout(() => this.isDeletionPopUpOpen = false, 200);
    },
    // ---------- Utils ----------
    truncateTitle(title, size) {
      if (title.length > size - 3) {
        return `${title.substring(0, size - 3)}...`;
      }
      return title;
    },
  },
};
</script>

<style lang="scss" scoped>
.activity-card {
  margin-top: 1rem;
  .hide-button{
    text-align: right;
    transition: opacity 0.3s ease-out;
    opacity: 0;
  }
  &:hover .hide-button{
    transition: opacity 0.3s ease-in;
    opacity: 1;
  }
}
.md-card {
  margin: 1rem;
}
.md-card-header {
  &::v-deep .card-icon {
    margin-top: 0.8rem;
    padding: 8px;
    background-color: $avalia-cyan;
  }
}
.margin-right {
  margin-right: 0.2rem;
}
.badge {
    background-color: $avalia-cyan;
}
.infos-container {
   padding-top: 1rem;
   display: flex;
}
.status-container {
   display: flex;
}
.field-container{
    padding-left: 1.5rem;
}
.owner-container{
    margin-left: auto;
}
.owner-icon {
  padding-right: 0.1rem;
  position: relative;
  top: 0.2rem;
}
.custom-chip {
    &::v-deep .md-chip {
        margin-top: 0px;
  }
}
.title {
    padding-left: 1.5rem;
}
.bottom-container {
    display: flex;
}
.actions-container {
    margin-left: auto;
}
.md-card-header .card-icon {
  margin: 0px;
}
.cursor-pointer {
  cursor: pointer;
}
.md-layout .md-card{
  margin: 0px;
}
.red-flag {
  color: #fe0600 !important;
}
</style>
