var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-layout md-size-100 md-medium-size-100"},[_c('div',{staticClass:"md-layout-item md-size-100 md-medium-size-100 nopadding"},[_c('div',{staticClass:"md-layout-item md-size-100 md-medium-size-100"},[_c('internal-breadcrumbs',{attrs:{"sourceName":_vm.playbook.title,"currentName":_vm.activity.title},on:{"showSource":_vm.onShowSource}})],1),_c('div',[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-size-100 md-medium-size-100"},[_c('h5',{staticClass:"section-title"},[_vm._v(" Activity "),_c('span',{staticClass:"saving-button",class:{
                  saved: _vm.status === _vm.UpdateStatus.SAVED,
                  failed: _vm.status === _vm.UpdateStatus.FAILED,
                  updating: _vm.status === _vm.UpdateStatus.UPDATING,
                  editing: _vm.status === _vm.UpdateStatus.EDITING
                }})]),_c('el-form',{ref:"activity",attrs:{"model":_vm.activity,"label-width":"120px","rules":_vm.rules}},[_c('el-form-item',{attrs:{"label":"Id","prop":"id"}},[_c('el-col',{attrs:{"span":18}},[_c('el-input',{attrs:{"disabled":true},model:{value:(_vm.activity.id),callback:function ($$v) {_vm.$set(_vm.activity, "id", $$v)},expression:"activity.id"}})],1),_c('el-col',{staticClass:"align-right",attrs:{"span":6}},[_c('span',{on:{"click":_vm.toggleFlag}},[_c('md-icon',{staticClass:"icon-action",class:_vm.flagClass},[_vm._v("flag")]),_c('md-tooltip',{attrs:{"md-delay":"300"}},[_vm._v("Flag activity")])],1)])],1),_c('el-form-item',{attrs:{"label":"Category","prop":"category"}},[_c('el-input',{attrs:{"disabled":true},model:{value:(_vm.parentContainer.title),callback:function ($$v) {_vm.$set(_vm.parentContainer, "title", $$v)},expression:"parentContainer.title"}})],1),_c('el-form-item',{attrs:{"label":"Title","prop":"title"}},[_c('div',{staticClass:"form-input"},[_c('el-input',{attrs:{"type":"textarea","autosize":""},on:{"blur":function($event){_vm.activityCopy.title != _vm.activity.title
                        ? _vm.sendUpdateActivityCommand({ title: _vm.activity.title })
                        : ''},"input":function($event){return _vm.onActivityFormInputChange(_vm.activityCopy.title, _vm.activity.title)}},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return (function () { return _vm.activityCopy.title != _vm.activity.title
                          ? _vm.sendUpdateActivityCommand({ title: _vm.activity.title })
                          : ''; }).apply(null, arguments)}},model:{value:(_vm.activity.title),callback:function ($$v) {_vm.$set(_vm.activity, "title", $$v)},expression:"activity.title"}}),_c('history',{attrs:{"taskId":_vm.task.id,"playbookId":_vm.playbook._id,"targetId":_vm.activity.id,"field":"title"},on:{"restore":function($event){_vm.status = _vm.UpdateStatus.EDITING;
                      _vm.sendUpdateActivityCommand({ title: $event.value ||'' });}}})],1)]),_c('el-form-item',{attrs:{"label":"Activity Type","prop":"activityType"}},[_c('div',{staticClass:"form-input"},[_c('el-input',{on:{"blur":function($event){_vm.activityCopy.activityType != _vm.activity.activityType
                        ? _vm.sendUpdateActivityCommand({ activityType: _vm.activity.activityType })
                        : ''},"input":function($event){return _vm.onActivityFormInputChange(_vm.activityCopy.activityType, _vm.activity.activityType)}},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return (function () { return _vm.activityCopy.activityType != _vm.activity.activityType
                          ? _vm.sendUpdateActivityCommand({ activityType: _vm.activity.activityType })
                          : ''; }).apply(null, arguments)}},model:{value:(_vm.activity.activityType),callback:function ($$v) {_vm.$set(_vm.activity, "activityType", $$v)},expression:"activity.activityType"}}),_c('history',{attrs:{"taskId":_vm.task.id,"playbookId":_vm.playbook._id,"targetId":_vm.activity.id,"field":"activityType"},on:{"restore":function($event){_vm.status = _vm.UpdateStatus.EDITING;
                      _vm.sendUpdateActivityCommand({ activityType: $event.value ||'' });}}})],1)]),_c('el-form-item',{attrs:{"label":"Activity Code","prop":"activityCode"}},[_c('div',{staticClass:"form-input"},[_c('el-input',{on:{"blur":function($event){_vm.activityCopy.activityCode != _vm.activity.activityCode
                        ? _vm.sendUpdateActivityCommand({ activityCode: _vm.activity.activityCode })
                        : ''},"input":function($event){return _vm.onActivityFormInputChange(_vm.activityCopy.activityCode, _vm.activity.activityCode)}},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return (function () { return _vm.activityCopy.activityCode != _vm.activity.activityCode
                          ? _vm.sendUpdateActivityCommand({ activityCode: _vm.activity.activityCode })
                          : ''; }).apply(null, arguments)}},model:{value:(_vm.activity.activityCode),callback:function ($$v) {_vm.$set(_vm.activity, "activityCode", $$v)},expression:"activity.activityCode"}}),_c('history',{attrs:{"taskId":_vm.task.id,"playbookId":_vm.playbook._id,"targetId":_vm.activity.id,"field":"activityCode"},on:{"restore":function($event){_vm.status = _vm.UpdateStatus.EDITING;
                      _vm.sendUpdateActivityCommand({ activityCode: $event.value ||'' });}}})],1)]),_c('el-form-item',{attrs:{"label":"Status","prop":"status"}},[_c('div',{staticClass:"form-input"},[_c('el-input',{on:{"blur":function($event){_vm.activityCopy.status != _vm.activity.status
                        ? _vm.sendUpdateActivityCommand({ status: _vm.activity.status })
                        : ''},"input":function($event){return _vm.onActivityFormInputChange(_vm.activityCopy.status, _vm.activity.status)}},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return (function () { return _vm.activityCopy.status != _vm.activity.status
                          ? _vm.sendUpdateActivityCommand({ status: _vm.activity.status })
                          : ''; }).apply(null, arguments)}},model:{value:(_vm.activity.status),callback:function ($$v) {_vm.$set(_vm.activity, "status", $$v)},expression:"activity.status"}}),_c('history',{attrs:{"taskId":_vm.task.id,"playbookId":_vm.playbook._id,"targetId":_vm.activity.id,"field":"status"},on:{"restore":function($event){_vm.status = _vm.UpdateStatus.EDITING;
                      _vm.sendUpdateActivityCommand({ status: $event.value ||'' });}}})],1)]),_c('el-form-item',{attrs:{"label":"Owner","prop":"owner"}},[_c('div',{staticClass:"form-input"},[_c('el-input',{on:{"blur":function($event){_vm.activityCopy.owner != _vm.activity.owner
                        ? _vm.sendUpdateActivityCommand({ owner: _vm.activity.owner })
                        : ''},"input":function($event){return _vm.onActivityFormInputChange(_vm.activityCopy.owner, _vm.activity.owner)}},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return (function () { return _vm.activityCopy.owner != _vm.activity.owner
                          ? _vm.sendUpdateActivityCommand({ owner: _vm.activity.owner })
                          : ''; }).apply(null, arguments)}},model:{value:(_vm.activity.owner),callback:function ($$v) {_vm.$set(_vm.activity, "owner", $$v)},expression:"activity.owner"}}),_c('history',{attrs:{"taskId":_vm.task.id,"playbookId":_vm.playbook._id,"targetId":_vm.activity.id,"field":"owner"},on:{"restore":function($event){_vm.status = _vm.UpdateStatus.EDITING;
                      _vm.sendUpdateActivityCommand({ owner: $event.value ||'' });}}})],1)]),_c('el-form-item',{attrs:{"label":"Assignee","prop":"assignedTo"}},[_c('div',{staticClass:"form-input"},[_c('el-input',{on:{"blur":function($event){_vm.activityCopy.assignedTo != _vm.activity.assignedTo
                        ? _vm.sendUpdateActivityCommand({ assignedTo: _vm.activity.assignedTo })
                        : ''},"input":function($event){return _vm.onActivityFormInputChange(_vm.activityCopy.assignedTo, _vm.activity.assignedTo)}},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return (function () { return _vm.activityCopy.assignedTo != _vm.activity.assignedTo
                          ? _vm.sendUpdateActivityCommand({ assignedTo: _vm.activity.assignedTo })
                          : ''; }).apply(null, arguments)}},model:{value:(_vm.activity.assignedTo),callback:function ($$v) {_vm.$set(_vm.activity, "assignedTo", $$v)},expression:"activity.assignedTo"}}),_c('history',{attrs:{"taskId":_vm.task.id,"playbookId":_vm.playbook._id,"targetId":_vm.activity.id,"field":"assignedTo"},on:{"restore":function($event){_vm.status = _vm.UpdateStatus.EDITING;
                      _vm.sendUpdateActivityCommand({ assignedTo: $event.value ||'' });}}})],1)]),_c('el-form-item',{attrs:{"label":"Client focal point","prop":"clientFocalPoint"}},[_c('div',{staticClass:"form-input"},[_c('el-input',{on:{"blur":function($event){_vm.activityCopy.clientFocalPoint != _vm.activity.clientFocalPoint
                        ? _vm.sendUpdateActivityCommand({
                            clientFocalPoint: _vm.activity.clientFocalPoint
                          })
                        : ''},"input":function($event){return _vm.onActivityFormInputChange(
                        _vm.activityCopy.clientFocalPoint,
                        _vm.activity.clientFocalPoint
                      )}},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return (function () { return _vm.activityCopy.clientFocalPoint != _vm.activity.clientFocalPoint
                          ? _vm.sendUpdateActivityCommand({
                              clientFocalPoint: _vm.activity.clientFocalPoint
                            })
                          : ''; }).apply(null, arguments)}},model:{value:(_vm.activity.clientFocalPoint),callback:function ($$v) {_vm.$set(_vm.activity, "clientFocalPoint", $$v)},expression:"activity.clientFocalPoint"}}),_c('history',{attrs:{"taskId":_vm.task.id,"playbookId":_vm.playbook._id,"targetId":_vm.activity.id,"field":"clientFocalPoint"},on:{"restore":function($event){_vm.status = _vm.UpdateStatus.EDITING;
                      _vm.sendUpdateActivityCommand({ clientFocalPoint: $event.value ||'' });}}})],1)]),_c('el-form-item',{attrs:{"label":"Summary","prop":"summary"}},[_c('div',{staticClass:"form-input"},[_c('el-input',{staticStyle:{"width":"100%"},attrs:{"type":"textarea","prop":"summary"},on:{"blur":function($event){_vm.activityCopy.summary != _vm.activity.summary
                        ? _vm.sendUpdateActivityCommand({ summary: _vm.activity.summary })
                        : ''},"input":function($event){return _vm.onActivityFormInputChange(_vm.activityCopy.summary, _vm.activity.summary)}},model:{value:(_vm.activity.summary),callback:function ($$v) {_vm.$set(_vm.activity, "summary", $$v)},expression:"activity.summary"}}),_c('history',{attrs:{"taskId":_vm.task.id,"playbookId":_vm.playbook._id,"targetId":_vm.activity.id,"field":"summary"},on:{"restore":function($event){_vm.status = _vm.UpdateStatus.EDITING;
                      _vm.sendUpdateActivityCommand({ summary: $event.value ||'' });}}})],1)]),_c('el-form-item',{attrs:{"label":"Budget","prop":"budget"}},[_c('div',{staticClass:"form-input"},[_c('el-input',{staticStyle:{"width":"100%"},attrs:{"type":"textarea","prop":"budget"},on:{"blur":function($event){_vm.activityCopy.budget != _vm.activity.budget
                        ? _vm.sendUpdateActivityCommand({ budget: _vm.activity.budget })
                        : ''},"input":function($event){return _vm.onActivityFormInputChange(_vm.activityCopy.budget, _vm.activity.budget)}},model:{value:(_vm.activity.budget),callback:function ($$v) {_vm.$set(_vm.activity, "budget", $$v)},expression:"activity.budget"}}),_c('history',{attrs:{"taskId":_vm.task.id,"playbookId":_vm.playbook._id,"targetId":_vm.activity.id,"field":"budget"},on:{"restore":function($event){_vm.status = _vm.UpdateStatus.EDITING;
                      _vm.sendUpdateActivityCommand({ budget: $event.value ||'' });}}})],1)]),_c('el-form-item',{attrs:{"label":"Start date"}},[_c('div',{staticClass:"form-input"},[_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"type":"date","value-format":"yyyy-MM-dd","placeholder":"Start date","prop":"startDate"},on:{"change":function($event){_vm.activityCopy.startDate != _vm.activity.startDate &&
                        _vm.sendUpdateActivityCommand({ startDate: _vm.activity.startDate || '' })},"input":function($event){return _vm.onActivityFormInputChange(_vm.activityCopy.startDate, _vm.activity.startDate)}},model:{value:(_vm.activity.startDate),callback:function ($$v) {_vm.$set(_vm.activity, "startDate", $$v)},expression:"activity.startDate"}}),_c('history',{attrs:{"taskId":_vm.task.id,"playbookId":_vm.playbook._id,"targetId":_vm.activity.id,"field":"startDate"},on:{"restore":function($event){_vm.status = _vm.UpdateStatus.EDITING;
                      _vm.sendUpdateActivityCommand({ startDate: $event.value ||'' });}}})],1)]),_c('el-form-item',{attrs:{"label":"Due date"}},[_c('div',{staticClass:"form-input"},[_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"type":"date","value-format":"yyyy-MM-dd","placeholder":"Due date","prop":"dueDate"},on:{"change":function($event){_vm.activityCopy.dueDate != _vm.activity.dueDate &&
                        _vm.sendUpdateActivityCommand({ dueDate: _vm.activity.dueDate || '' })},"input":function($event){return _vm.onActivityFormInputChange(_vm.activityCopy.dueDate, _vm.activity.dueDate)}},model:{value:(_vm.activity.dueDate),callback:function ($$v) {_vm.$set(_vm.activity, "dueDate", $$v)},expression:"activity.dueDate"}}),_c('history',{attrs:{"taskId":_vm.task.id,"playbookId":_vm.playbook._id,"targetId":_vm.activity.id,"field":"dueDate"},on:{"restore":function($event){_vm.status = _vm.UpdateStatus.EDITING;
                      _vm.sendUpdateActivityCommand({ dueDate: $event.value ||'' });}}})],1)]),_c('el-form-item',{attrs:{"label":"New finish date"}},[_c('div',{staticClass:"form-input"},[_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"type":"date","value-format":"yyyy-MM-dd","placeholder":"New finish date","prop":"newFinishDate"},on:{"input":function($event){return _vm.onActivityFormInputChange(
                        _vm.activityCopy.newFinishDate,
                        _vm.activity.newFinishDate
                      )},"change":function($event){_vm.activityCopy.newFinishDate != _vm.activity.newFinishDate &&
                        _vm.sendUpdateActivityCommand({ newFinishDate: _vm.activity.newFinishDate || '' })}},model:{value:(_vm.activity.newFinishDate),callback:function ($$v) {_vm.$set(_vm.activity, "newFinishDate", $$v)},expression:"activity.newFinishDate"}}),_c('history',{attrs:{"taskId":_vm.task.id,"playbookId":_vm.playbook._id,"targetId":_vm.activity.id,"field":"newFinishDate"},on:{"restore":function($event){_vm.status = _vm.UpdateStatus.EDITING;
                      _vm.sendUpdateActivityCommand({ newFinishDate: $event.value ||'' });}}})],1)]),_c('el-form-item',{attrs:{"label":"Remaining effort","prop":"effort"}},[_c('div',{staticClass:"form-input"},[_c('el-input',{staticStyle:{"width":"100%"},attrs:{"prop":"effort"},on:{"blur":function($event){_vm.activityCopy.remainingEffort != _vm.activity.remainingEffort
                        ? _vm.sendUpdateActivityCommand({ remainingEffort: _vm.activity.remainingEffort })
                        : ''},"input":function($event){return _vm.onActivityFormInputChange(
                        _vm.activityCopy.remainingEffort,
                        _vm.activity.remainingEffort
                      )}},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return (function () { return _vm.activityCopy.remainingEffort != _vm.activity.remainingEffort
                          ? _vm.sendUpdateActivityCommand({
                              remainingEffort: _vm.activity.remainingEffort
                            })
                          : ''; }).apply(null, arguments)}},model:{value:(_vm.activity.remainingEffort),callback:function ($$v) {_vm.$set(_vm.activity, "remainingEffort", $$v)},expression:"activity.remainingEffort"}}),_c('history',{attrs:{"taskId":_vm.task.id,"playbookId":_vm.playbook._id,"targetId":_vm.activity.id,"field":"remainingEffort"},on:{"restore":function($event){_vm.status = _vm.UpdateStatus.EDITING;
                      _vm.sendUpdateActivityCommand({ remainingEffort: $event.value ||'' });}}})],1)])],1),_c('playbook-notes',{attrs:{"task":_vm.task,"playbook":_vm.playbook,"element":_vm.activity,"containerType":'Activity'}}),_c('conditions',{attrs:{"task":_vm.task,"playbook":_vm.playbook,"parentContainer":_vm.parentContainer,"activity":_vm.activity}}),_c('milestones',{attrs:{"task":_vm.task,"playbook":_vm.playbook,"parentContainer":_vm.parentContainer,"activity":_vm.activity}}),_c('resources',{attrs:{"task":_vm.task,"playbook":_vm.playbook,"parentContainer":_vm.parentContainer,"activity":_vm.activity}}),_c('additional-properties',{attrs:{"task":_vm.task,"playbook":_vm.playbook,"parentContainer":_vm.parentContainer,"element":_vm.activity}})],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }