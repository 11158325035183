<template>
  <div class="md-layout draggable-item"  v-if="!category.isArchived">
    <md-card class="md-layout-item category-card">
      <md-card-header class="md-layout">
        <div class="md-layout-item-10 ">
          <md-icon class="handle cursor-pointer">drag_handle</md-icon>
        </div>
        <div class="md-layout-item-10">
            <archived-categories-and-activities :playbookId="playbook._id" :taskId="task.id"
              :activities="category.activities.filter(e=>e.isArchived)" :categories="category.categories.filter(e=>e.isArchived)"
            @status="$emit('status', $event);"/>
        </div>

        <div class="md-layout-item-10 ">
          <md-icon class="md-layout-item">folder</md-icon>
          <md-tooltip md-delay="300">Category</md-tooltip>
        </div>

        <div class="md-layout-item title-container cursor-pointer" @click="viewCategoryCardClicked(category, $event)">
          <h4 >{{ truncateTitle(category.title, 50)}}</h4>
        </div>

        <div class="md-layout-item-10 delete-button" @click="onArchiveCategory()">
          <md-icon class="md-layout-item icon-action icon-delete" >archive</md-icon>
          <md-tooltip md-delay="300">Archive category</md-tooltip>
        </div>

        <div class="md-layout-item-10 delete-button" @click="onToggleCategoryExpansion()">
          <md-icon class="md-layout-item icon-action" >{{ isCategoryContentDisplayed ? "expand_less" :"expand_more"}}</md-icon>
          <md-tooltip md-delay="300">{{ isCategoryContentDisplayed ? "Show less" : "Show more"}}</md-tooltip>
        </div>
      </md-card-header>
      <md-card-content v-if="isCategoryContentDisplayed">
        <div class="activities-table-container">
          <draggable
            tag="ul"
            draggable=".draggable-item"
            v-model="categories"
            :animation="200"
            :group="{ name: 'all-categories', pull: 'all-categories', put: 'all-categories' }"
            handle=".handle"
            @change="onChangeCategories($event)"
          >
            <h5 slot="header" class="playbook-section-title">
              Categories
              <span
                class="saving-button"
                :class="{
                  saved: categoriesStatus === UpdateStatus.SAVED,
                  failed: categoriesStatus === UpdateStatus.FAILED,
                  updating: categoriesStatus === UpdateStatus.UPDATING,
                  editing: categoriesStatus === UpdateStatus.EDITING
                }"
              ></span>
            </h5>
            <category-card
              v-for="categoryIterator in categories"
              :category="categoryIterator"
              :key="categoryIterator.id"
              :playbook="playbook"
              :parentContainer="category"
              :task="task"
              @viewCategory="onViewCategory"
              @viewActivity="onViewActivity"
              @status="categoriesStatus = $event"
            >
            </category-card>
            <div slot="footer" v-if="categories.length == 0 ">
              none
            </div>
          </draggable>
          <ul>
            <new-category-card v-if="!createCategoryButtonDisplayed"
              @saveNewCategory="createCategory"
              @closeDialogue="onCloseNewCategoryDialogue">
            </new-category-card>
          </ul>
          <div class="activities-table-footer">
            <span @click="showCreateCategoryForm" class= "create-activity-button-container" v-if="createCategoryButtonDisplayed">
              <md-icon class="icon-action icon-add">create_new_folder</md-icon>
              <md-tooltip md-delay="300">Create Activity Category</md-tooltip>
            </span>
          </div>
        </div>
        <div class="activities-table-container">
          <draggable
            tag="ul"
            draggable=".draggable-item"
            v-model="activities"
            :animation="200"
            :group="{ name: 'all-activities', pull: 'all-activities', put: 'all-activities' }"
            handle=".handleActivity"
            @change="onChangeActivities($event)"
          >
            <h5 slot="header" class="playbook-section-title">
              Activities
              <span
                class="saving-button"
                :class="{
                  saved: activitiesStatus === UpdateStatus.SAVED,
                  failed: activitiesStatus === UpdateStatus.FAILED,
                  updating: activitiesStatus === UpdateStatus.UPDATING,
                  editing: activitiesStatus === UpdateStatus.EDITING
                }"
              ></span>
            </h5>
            <activity-card
              v-for="activity in activities"
              :activity="activity"
              :key="activity.id"
              :playbook="playbook"
              :parentContainer="category"
              :task="task"
              @viewActivity="onViewActivity"
              @status="activitiesStatus = $event"
            >
            </activity-card>
            <div slot="footer" v-if="activities.length == 0 ">
              none
            </div>
          </draggable>
          <ul>
            <new-activity-card v-if="!createActivityButtonDisplayed"
              @saveNewActivity="createActivity"
              @closeDialogue="onCloseNewActivityDialogue">
            </new-activity-card>
          </ul>
          <div
            class="activities-table-footer"
          >
            <div v-if="createActivityButtonDisplayed">
              <span @click="showCreateActivityForm" class= "create-activity-button-container">
                <md-icon class="icon-action icon-add">post_add</md-icon>
                <md-tooltip md-delay="300">Create Activity</md-tooltip>
              </span>
            </div>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import moment from 'moment';
import api from '@/api/api';
import draggable from 'vuedraggable';
import notify from '@/utils/notificationsUtils';
import ActivityCard from '@/components/Avalia/TaskDetails/Playbook/ActivityCard.vue';
import NewActivityCard from '@/components/Avalia/TaskDetails/Playbook/NewActivityCard.vue';
import NewCategoryCard from '@/components/Avalia/TaskDetails/Playbook/NewCategoryCard.vue';
import {
  Form, FormItem, Input, DatePicker, Button, Col, Checkbox,
} from 'element-ui';
import ArchivedCategoriesAndActivities from '@/components/Avalia/TaskDetails/Playbook/ArchivedCategoriesAndActivities.vue';
import UpdateStatus from '../UpdateStatus';

export default {
  components: {
    ActivityCard,
    NewActivityCard,
    draggable,
    [Form.name]: Form,
    [Input.name]: Input,
    [DatePicker.name]: DatePicker,
    [FormItem.name]: FormItem,
    [Button.name]: Button,
    [Col.name]: Col,
    [Checkbox.name]: Checkbox,
    NewCategoryCard,
    ArchivedCategoriesAndActivities,
  },
  name: 'category-card',
  props: {
    category: {
      type: Object,
      required: true,
    },
    playbook: {
      type: Object,
      required: true,
    },
    parentContainer: {
      type: Object,
      required: true,
    },
    task: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      categoriesStatus: UpdateStatus.SAVED,
      activitiesStatus: UpdateStatus.SAVED,
      numberOfCharInDescription: 220,
      isDeletionPopUpOpen: false,
      showActivityDetails: false,
      isCategoryContentDisplayed: false,
      createActivityButtonDisplayed: true,
      createCategoryButtonDisplayed: true,
      UpdateStatus,
      rules: {
        title: [
          { required: true, message: 'Please input an Activity Category title', trigger: 'blur' },
        ],
      },
    };
  },
  computed: {
    activities: {
      get() {
        return this.category.activities.filter((a) => !a.isArchived);
      },
      set(activities) {
        this.category.activities = activities;
        this.category.activities.concat(this.category.activities.filter((a) => a.isArchived));
      },
    },
    categories: {
      get() {
        return this.category.categories;//.filter((a) => !a.isArchived);
      },
      set(categories) {
        this.category.categories = categories;
        //this.category.categories.concat(this.playbook.categories.filter((a) => a.isArchived));
      },

    },
  },
  methods: {
    // ---------- Navigation -------------
    onCloseNewActivityDialogue() {
      this.createActivityButtonDisplayed = true;
    },
    ß() {
      // Didn't find other solution for this value change after loosing focus while clicking on cancel button
      setTimeout(() => this.isDeletionPopUpOpen = false, 200);
    },
    onViewActivity(eventData) {
      this.$emit('viewActivity', eventData);
    },
    onViewCategory(eventData) {
      this.$emit('viewCategory', eventData);
    },
    onCloseNewCategoryDialogue() {
      this.createCategoryButtonDisplayed = true;
    },
    showCreateActivityForm() {
      this.createActivityButtonDisplayed = false;
    },
    showCreateCategoryForm() {
      this.createCategoryButtonDisplayed = false;
    },
    onViewPlaybook() {
      this.showActivityDetails = false;
      this.selectedActivity = {};
    },
    onToggleCategoryExpansion() {
      this.isCategoryContentDisplayed = !this.isCategoryContentDisplayed;
    },
    // ---------- Fetch playbook ----------
    fetchPlaybook() {
      const payload = {
        taskId: this.task.id,
        vm: this,
      };
      return this.$store.dispatch('playbooks/fetchPlaybook', payload);
    },
    // ----------- Activities ----------
    onChangeActivities(event) {
      if (event.added) {
        this.onMoveActivityToList(event.added.element, event.added.newIndex);
      } else if (event.removed) {
        // Remove is only triggered when we add the element to another list, so we manage that in the add event
      } else if (event.moved) {
        //this.onReorderActivities();
        const newIndex = event.moved.newIndex + (event.moved.newIndex > event.moved.oldIndex ? 1 : 0);
        this.onMoveActivityToList(event.moved.element, newIndex);
      }
    },
    onMoveActivityToList(activity, index) {
      const moveActivityCommand = {
        type: 'MoveActivityCommand',
        // eslint-disable-next-line no-underscore-dangle
        playbookId: this.playbook._id,
        activityId: activity.id,
        destinationContainerId: this.category.id,
        index,
      };
      const answerPayload = {
        action: 'WORK',
        taskWorkDTO: {
          type: 'playbook task',
          command: moveActivityCommand,
        },
      };
      this.$emit('status', UpdateStatus.UPDATING);
      api.assess
        .post(`/tasks/${this.task.id}`, answerPayload)
        .then((response) => {
          this.fetchPlaybook();
          this.$emit('status', UpdateStatus.SAVED);
        })
        .catch((error) => {
          const errorMessage = `Could not reorder Playbook's activities : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, this);
          this.$emit('status', UpdateStatus.FAILED);
        });
    },
    createActivity(activity) {
      const addActivityCommand = {
        type: 'AddActivityCommand',
        // eslint-disable-next-line no-underscore-dangle
        playbookId: this.playbook._id,
        title: activity.title,
        containerId: this.category.id,
      };
      const answerPayload = {
        action: 'WORK',
        taskWorkDTO: {
          type: 'playbook task',
          command: addActivityCommand,
        },
      };

      this.$emit('status', UpdateStatus.UPDATING);
      return api.assess
        .post(`/tasks/${this.task.id}`, answerPayload)
        .then((response) => {
          // console.log('Activity successfully Created');
          this.fetchPlaybook();
          this.createActivityButtonDisplayed = true;
          this.$emit('status', UpdateStatus.SAVED);
        })
        .catch((error) => {
          const errorMessage = `Could not save playbook : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, this);
          this.$emit('status', UpdateStatus.FAILED);
        });
    },
    // --------- Categories ------------
    onChangeCategories(event) {
      console.log('category event', event);
      if (event.added) {
        this.onMoveCategoryToList(event.added.element, event.added.newIndex);
      } else if (event.removed) {
        // Remove is only triggered when we add the element to another list, so we manage that in the add event
      } else if (event.moved) {
        //this.onReorderCategories();
        const newIndex = event.moved.newIndex + (event.moved.newIndex > event.moved.oldIndex ? 1 : 0);
        this.onMoveCategoryToList(event.moved.element, newIndex);
      }
    },
    onMoveCategoryToList(category, index) {
      const moveCategoryCommand = {
        type: 'MoveCategoryCommand',
        // eslint-disable-next-line no-underscore-dangle
        playbookId: this.playbook._id,
        destinationContainerId: this.category.id,
        categoryId: category.id,
        index,
      };
      const answerPayload = {
        action: 'WORK',
        taskWorkDTO: {
          type: 'playbook task',
          command: moveCategoryCommand,
        },
      };
      this.activitiesStatus = UpdateStatus.UPDATING;
      api.assess
        .post(`/tasks/${this.task.id}`, answerPayload)
        .then((response) => {
          this.fetchPlaybook();
          this.activitiesStatus = UpdateStatus.SAVED;
        })
        .catch((error) => {
          const errorMessage = `Could not reorder Playbook's categories : ${
            error.response.data.message || ''
          }`;
          notify.notifyError(errorMessage, this);
          this.activitiesStatus = UpdateStatus.FAILED;
        });
    },
    createCategory(newCategory) {
      const addCategoryCommand = {
        type: 'AddCategoryCommand',
        // eslint-disable-next-line no-underscore-dangle
        playbookId: this.playbook._id,
        title: newCategory.title,
        containerId: this.category.id,
      };
      const answerPayload = {
        action: 'WORK',
        taskWorkDTO: {
          type: 'playbook task',
          command: addCategoryCommand,
        },
      };

      this.$emit('status', UpdateStatus.UPDATING);
      return api.assess
        .post(`/tasks/${this.task.id}`, answerPayload)
        .then((response) => {
          //console.log('Activity successfully Created');
          this.fetchPlaybook();
          this.createCategoryButtonDisplayed = true;
          this.$emit('status', UpdateStatus.SAVED);
        })
        .catch((error) => {
          const errorMessage = `Could not save playbook : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, this);
          this.$emit('status', UpdateStatus.FAILED);
        });
    },
    onArchiveCategory() {
      const archiveCategoriesCommand = {
        type: 'ArchiveCategoryCommand',
        // eslint-disable-next-line no-underscore-dangle
        playbookId: this.playbook._id,
        categoryId: this.category.id,
      };
      const answerPayload = {
        action: 'WORK',
        taskWorkDTO: {
          type: 'playbook task',
          command: archiveCategoriesCommand,
        },
      };
      this.$emit('status', UpdateStatus.UPDATING);
      api.assess
        .post(`/tasks/${this.task.id}`, answerPayload)
        .then((response) => {
          this.fetchPlaybook();
          this.$emit('status', UpdateStatus.SAVED);
        })
        .catch((error) => {
          const errorMessage = `Could not archive category : ${error.response
            .data.message || ''}`;
          notify.notifyError(errorMessage, this);
          this.$emit('status', UpdateStatus.FAILED);
        });
    },
    // ---------- Utils ------------
    viewCategoryCardClicked(category) {
      this.$emit('viewCategory', { category, parentContainer: this.parentContainer });
    },
    descriptionContent(description) {
      if (description) {
        const cleanedDescription = description.replace(/<\/?[^>]+(>|$)/g, '');
        return cleanedDescription.length < this.numberOfCharInDescription
          ? cleanedDescription
          : `${cleanedDescription.substring(0, this.numberOfCharInDescription)
          }...`;
      }
      return '-';
    },
    truncateTitle(title, size) {
      if (title.length > size - 3) {
        return `${title.substring(0, size - 3)}...`;
      }
      return title;
    },
    dateFormat(date) {
      if (date === undefined) {
        return '';
      }
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
  },
};
</script>

<style lang="scss" scoped >
ul{
  margin-left: 0;
}
.md-card {
  margin: 1rem 0;
  width: auto;
}
.md-layout .md-card{
  margin-bottom: 0px;
}
.activity-card {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
.activities-table-container {
  margin: 0rem 0rem 0rem 2rem;
}
.category-card {
  > .md-card-header .delete-button{
    transition: opacity 0.3s ease-out;
      opacity: 0;
  }
  &:hover > .md-card-header .delete-button{
    transition: opacity 0.3s ease-in;
    opacity: 1;
  }
  > .md-card-content > .activities-table-container > .activities-table-footer .create-activity-button-container{
    transition: opacity 0.3s ease-out;
      opacity: 0;
  }
  &:hover > .md-card-content > .activities-table-container > .activities-table-footer .create-activity-button-container{
    transition: opacity 0.3s ease-in;
    opacity: 1;
  }
}
.title {
    padding-left: 1.5rem;
}
.title-container {
    padding-left: 0px;
}
.cursor-pointer {
  cursor: pointer;
}
.activities-table-footer {
  display: flex;
  justify-content: flex-end;
  margin: 0 0 0 0;
  .create-activity-button-container {
    padding-right: 1rem;
  }
}
</style>
<style lang="scss" scoped>
.title-container{
  .el-form-item {
    margin-bottom: 0px;
}
  .el-form-item__content{
    line-height: 28px;
  }
  .el-input__inner{
    height: 28px;
    line-height: 28px;
  }
}
</style>
