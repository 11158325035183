<template>
<div class="md-layout">
    <md-card md-with-hover class="md-layout-item">
      <md-card-header class="md-card-header-icon">
          <div class="md-layout">
              <div class="md-layout-item md-size-95 title-container"
                @keydown.enter.prevent.stop="() => submitForm('newCategory')">
                  <el-form :model="newCategory" label-width="160px" :rules="rules" ref="newCategory" class="new-category-form">
                    <el-form-item label="New category title" prop="title">
                    <el-input
                      type="textarea"
                      autosize
                      v-model="newCategory.title"
                      ref="titleInput"
                    >
                    </el-input>
                    </el-form-item>
                  </el-form>
              </div>
              <div class="md-layout-item md-size-5"
                @click="closeDialogue">
                <md-icon class="icon-action icon-delete">close</md-icon>
              </div>
          </div>
      </md-card-header>
    </md-card>
  </div>
</template>

<script>
import {
  Form, FormItem, Input, Col,
} from 'element-ui';

export default {
  components: {
    [Form.name]: Form,
    [Input.name]: Input,
    [Col.name]: Col,
    [FormItem.name]: FormItem,
  },
  name: 'new-category-card',
  props: {
  },
  data() {
    return {
      newCategory: {
        title: '',
      },
      rules: {
        title: [
          { required: true, message: 'Please input new Activity Category title', trigger: 'blur' },
        ],
      },
    };
  },
  computed: {
  },
  mounted() {
    this.$refs.titleInput.focus();
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit('saveNewCategory', this.newCategory);
        }
      });
    },
    closeDialogue() {
      this.$emit('closeDialogue');
    },
  },
};
</script>

<style lang="scss" scoped>
.md-card-header {
  &::v-deep .card-icon {
    margin-top: 0.8rem;
    padding: 8px;
    background-color: $avalia-cyan;
  }
  &::v-deep .title {
    margin-top: 8px;
  }
}
.title-container {
    padding-top: 1rem;
    padding-left: 0rem;
    display: flex;
}
.actions-container {
    margin-left: auto;
    padding-left: 1rem;
}
.new-category-form{
  width: 100%;
}
</style>
