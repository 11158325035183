<template>
  <div>
    <el-col :span="24">
      <h5 class="section-title">
        <ArchivedMilestones :playbookId="playbook._id" :taskId="task.id" :activityId="activity.id" :milestones="activity.milestones.filter(e=>e.isArchived)"
            @status="status = $event"/> Milestones
        <span class="saving-button"
        :class="{saved: status === UpdateStatus.SAVED,
          failed: status === UpdateStatus.FAILED,
          updating: status === UpdateStatus.UPDATING,
          editing: status === UpdateStatus.EDITING}"></span>
      </h5>
    </el-col>
    <draggable
      tag="ul"
      v-model="milestones"
      :animation="200"
      :group="{ name: 'all-activities', pull: 'all-activities', put: 'all-activities' }"
      handle=".handleMilestones"
      @change="onChangeMilestones($event)"
    >
    <div v-for="(milestone, index) in milestones" :key="milestone.id" class="milestone-card md-layout">
      <div v-if="!milestone.isArchived" class="md-layout-item md-layout">
        <div class="md-layout-item md-size-5">
          <div class="card-icon noshadow cursor-pointer">
            <md-icon class="handleMilestones">drag_handle</md-icon>
          </div>
        </div>
        <el-form class="md-layout-item md-size-95" label-width="80px">
          <el-form-item label="Name">
            <el-col :span="24">
              <el-input
                v-model="milestone.name"
                :prop="'milestone.' + index"
                placeholder="Milestone name"
                @blur="milestonesCopy[index].name !== milestone.name ? sendUpdateMilestoneCommand(milestone):''"
                @keydown.enter.prevent.native="
                  milestonesCopy[index].name !== milestone.name ? sendUpdateMilestoneCommand(milestone):''
                "
                @input="onMilestoneFormChange(milestonesCopy[index].name,milestone.name)"
              >
              </el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="Due date">
            <el-col :span="24">
              <el-date-picker
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="Due date"
                v-model="milestone.dueDate"
                prop="startDate"
                @change="milestonesCopy[index].dueDate !== milestone.dueDate && sendUpdateMilestoneCommand(milestone)"
                @keydown.enter.prevent.native="
                  milestonesCopy[index].dueDate !== milestone.dueDate ? sendUpdateMilestoneCommand(milestone):''
                "
                @input="onMilestoneFormChange(milestonesCopy[index].dueDate,milestone.dueDate)"
              >
              </el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item>
            <el-col :span="6">
              <el-checkbox
                v-model="milestone.isReached"
                label="Reached"
                name="Reached"
                @change="onActivityCheckboxInputChange(milestone)"
              >
              </el-checkbox>
            </el-col>
            <el-col :span="2" :offset="16" class="align-right hide-button">
              <span @click="archiveMilestone(milestone)">
                <md-icon class="icon-action icon-action icon-delete"
                  >archive</md-icon
                >
                <md-tooltip md-delay="300"
                  >Archive milestone : "{{ milestone.name }}"</md-tooltip
                >
              </span>
            </el-col>
          </el-form-item>
          <el-form-item>
            <hr>
          </el-form-item>
        </el-form>
      </div>
    </div>
    </draggable>
    <el-form label-width="120px">
      <el-form-item v-if="addMilestoneFormDisplayed">
        <el-input
          v-model="newMilestone.name"
          :prop="'newMilestone'"
          placeholder="New milestone name"
          @keydown.enter.prevent.native="createNewMilestone()"
          @blur="createNewMilestone()"
          ref="milestoneInput"
        >
        </el-input>
      </el-form-item>
      <el-form-item v-else>
        <el-col  :span="1" :offset="23">
        <span @click="displayAddMilestoneForm">
          <md-icon class="icon-action">add</md-icon>
          <md-tooltip md-delay="300">Add activity milestone</md-tooltip>
        </span>
        </el-col>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import api from '@/api/api';
import notify from '@/utils/notificationsUtils';
import {
  Form,
  FormItem,
  Input,
  DatePicker,
  Button,
  Col,
  Checkbox,
  Select,
  Option,
  InputNumber,
  Slider,
} from 'element-ui';
import draggable from 'vuedraggable';
import UpdateStatus from '../../UpdateStatus';
import ArchivedMilestones from './ArchivedMilestones.vue';

export default {
  name: 'milestones',
  components: {
    draggable,
    [Form.name]: Form,
    [Input.name]: Input,
    [DatePicker.name]: DatePicker,
    [FormItem.name]: FormItem,
    [Button.name]: Button,
    [Col.name]: Col,
    [Select.name]: Select,
    [Option.name]: Option,
    [InputNumber.name]: InputNumber,
    [Slider.name]: Slider,
    [Checkbox.name]: Checkbox,
    ArchivedMilestones,
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
    activity: {
      type: Object,
      required: true,
    },
    parentContainer: {
      type: Object,
      required: true,
    },
    playbook: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      addMilestoneFormDisplayed: false,
      newMilestone: {},
      status: UpdateStatus.SAVED,
      UpdateStatus,
      milestonesCopy: [],
    };
  },
  mounted() {
    this.saveMilestonesToCopy();
  },
  computed: {
    milestones: {
      get() {
        return this.activity.milestones;//.filter((m) => !m.isArchived);
      },
      set(milestones) {
        this.activity.milestones = milestones;
        //this.activity.milestones.concat(this.activity.milestones.filter((m) => m.isArchived));
      },
    },
  },
  methods: {
    // reordering
    onChangeMilestones(event) {
      //console.log('playbook event', event);
      if (event.added) {
        // move between lists is currently unsupported for Milestones
      } else if (event.removed) {
        // Remove is only triggered when we add the element to another list, so we manage that in the add event
      } else if (event.moved) {
        const newIndex = event.moved.newIndex + (event.moved.newIndex > event.moved.oldIndex ? 1 : 0);
        this.onMoveMilestoneToList(event.moved.element, newIndex);
      }
    },
    onMoveMilestoneToList(milestone, index) {
      const moveMilestoneCommand = {
        type: 'MoveMilestoneCommand',
        // eslint-disable-next-line no-underscore-dangle
        playbookId: this.playbook._id,
        activityId: this.activity.id,
        milestoneId: milestone.id,
        index,
      };
      const answerPayload = {
        action: 'WORK',
        taskWorkDTO: {
          type: 'playbook task',
          command: moveMilestoneCommand,
        },
      };
      this.activitiesStatus = UpdateStatus.UPDATING;
      api.assess
        .post(`/tasks/${this.task.id}`, answerPayload)
        .then((response) => {
          this.fetchPlaybook();
          this.activitiesStatus = UpdateStatus.SAVED;
        })
        .catch((error) => {
          const errorMessage = `Could not reorder Playbook's conditions : ${error.response.data
            .message || ''}`;
          notify.notifyError(errorMessage, this);
          this.activitiesStatus = UpdateStatus.FAILED;
        });
    },
    // ------- Navigation ------
    displayAddMilestoneForm() {
      this.addMilestoneFormDisplayed = true;
      this.$nextTick(() => {
        this.$refs.milestoneInput.focus();
      });
    },
    hideAddMilestoneForm() {
      this.addMilestoneFormDisplayed = false;
    },
    // -------- Milestone actions ---------
    onActivityCheckboxInputChange(milestone) {
      const index = this.activity.milestones.indexOf(milestone);
      if (index !== -1) {
        this.sendMilestoneCommand({
          // TODO this will be dedicated command one day
          type: milestone.isReached
            ? 'ReachMilestoneCommand'
            : 'UnreachMilestoneCommand',
          milestoneId: milestone.id,
        }).then(() => {
          this.status = UpdateStatus.SAVED;
        });
      }
    },
    onMilestoneFormChange(oldValue, newValue) {
      if (oldValue !== newValue) {
        this.status = UpdateStatus.EDITING;
      } else {
        this.status = UpdateStatus.SAVED;
      }
    },
    createNewMilestone() {
      if (this.addMilestoneFormDisplayed) {
        if (this.newMilestone.name) {
          this.sendMilestoneCommand({
            type: 'AddMilestoneCommand',
            name: this.newMilestone.name,
          }).then(() => {
            this.newMilestone = {};
            //console.log('Milestone successfully created');
            this.fetchPlaybook();
            this.status = UpdateStatus.SAVED;
          });
        }
        this.hideAddMilestoneForm();
      }
    },
    sendUpdateMilestoneCommand(milestone) {
      const index = this.activity.milestones.indexOf(milestone);
      if (index !== -1 && this.status !== UpdateStatus.SAVED) {
        this.sendMilestoneCommand({
          type: 'UpdateMilestoneCommand',
          milestoneId: milestone.id,
          name: milestone.name,
          dueDate: milestone.dueDate || '',
        })
          .then(() => {
            this.status = UpdateStatus.SAVED;
          });
      }
    },
    archiveMilestone(milestone) {
      const index = this.activity.milestones.indexOf(milestone);
      if (index !== -1) {
        this.sendMilestoneCommand({
          type: 'ArchiveMilestoneCommand',
          milestoneId: milestone.id,
        })
          .then(() => {
            this.fetchPlaybook();
            this.status = UpdateStatus.SAVED;
          }).catch(() => {
            this.status = UpdateStatus.FAILED;
          });
      }
    },
    sendMilestoneCommand(partialCommand) {
      const milestoneCommand = {
        // eslint-disable-next-line no-underscore-dangle
        playbookId: this.playbook._id,
        activityId: this.activity.id,
        ...partialCommand,
      };
      const answerPayload = {
        action: 'WORK',
        taskWorkDTO: {
          type: 'playbook task',
          command: milestoneCommand,
        },
      };
      this.status = UpdateStatus.UPDATING;
      return api.assess
        .post(`/tasks/${this.task.id}`, answerPayload)
        .then(() => {
          //console.log('Milestone successfully updated');
          this.fetchPlaybook();
        })
        .catch((error) => {
          const errorMessage = `Could not save Activity : ${
            error.response.data.message || ''
          }`;
          notify.notifyError(errorMessage, this);
          this.status = UpdateStatus.FAILED;
        });
    },
    // ------ Fetch playbook ---------
    fetchPlaybook() {
      const payload = {
        taskId: this.task.id,
        vm: this,
      };
      return this.$store.dispatch('playbooks/fetchPlaybook', payload).then(this.saveMilestonesToCopy);
    },
    saveMilestonesToCopy() {
      this.milestonesCopy = [];
      Object.values(this.activity.milestones).forEach((milestone) => {
        const milestoneCopy = {
          name: milestone.name,
          dueDate: milestone.dueDate,
          isReached: milestone.isReached,
        };
        this.milestonesCopy.push(milestoneCopy);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
hr {
  border-width: 1px 0px 0px 0px;
  border-style: solid;
  border-color: #c8c8c8;
}
.milestone-card {
  .hide-button {
    transition: opacity 0.3s ease-out;
    opacity: 0;
  }
  &:hover .hide-button {
    transition: opacity 0.3s ease-in;
    opacity: 1;
  }
}
.section-title {
  margin-bottom: 1rem;
  padding-left: 1.2rem;
}
.button-container {
  padding-left: 1rem;
  text-align: right;
}
.line {
  text-align: center;
}
.align-right {
  text-align: right;
}
.align-left {
  text-align: left;
}
.handleMilestones {
  padding-top: 0.9rem;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
