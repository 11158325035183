<template>
  <notes-container
    :notes="notes"
    @onCreateNote="(notePayload) => $emit('onCreateNote', notePayload)"
    @onUpdateNote="(notePayload) => $emit('onUpdateNote', notePayload)"
    @onDeleteNote="(notePayload) => $emit('onDeleteNote', notePayload)"
  />
</template>

<script>
import NotesContainer from '@/components/Avalia/Note/NotesContainer.vue';

export default {
  name: 'notes-form-container',
  components: {
    NotesContainer,
  },
  props: {
    // The notes need to be link to the question and so need to be fetch in the FormTask
    notes: {
      type: Array,
      required: true,
    },
  },
  computed: {
  },
  data() {
    return {
    };
  },
  created() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.notes-container{
  padding-right: 0px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  position: relative;
}
// CUSTOM BUTTONS TO MOVE TO avalia.scss
.add-note-button {
  color: #27eb68 !important;
  background-color: white !important;
  border-style: solid !important;
  border-width: 2px !important;
  &:hover {
    color: white !important;
    background-color: #27eb68 !important;
    opacity: 0.8 !important;
    border-color: #27eb68 !important;
  }

  height: 1.4rem;
}
.add-note-button-container{
  height: 1.8rem;
}

</style>
