<template>
  <div>
    <div v-for="(question, index) in formSection.questions" :key="index + question.questionKey" class="question-container">
      <question
        :question="question"
        :readOnly="isTaskReadOnly"
        @onAnsweringQuestion="onAnsweringQuestion"
        @onAnsweringFileQuestion="(payload) => onAnsweringFileQuestion(payload)"
        @downloadFile="(questionKey) => onDownloadFile(questionKey)"
        @deleteFile="(questionKey) => onDeleteFile(questionKey)"
      />
      <notes-form-container
        class="notes-form-container"
        :notes="question.notes"
        @onCreateNote="(notePayload) => $emit('onCreateNote', { notePayload, questionKey: question.questionKey, questionLabel: question.label})"
        @onUpdateNote="(notePayload) => $emit('onUpdateNote', notePayload)"
        @onDeleteNote="(notePayload) => $emit('onDeleteNote', notePayload)"
      ></notes-form-container>
    </div>
  </div>
</template>

<script>
// import api from '@/api/api';
import Question from '@/components/Avalia/TaskDetails/Form/Question/Question.vue';
import NotesFormContainer from '@/components/Avalia/Note/NotesFormContainer.vue';

export default {
  name: 'form-section',
  components: {
    Question,
    NotesFormContainer,
  },
  props: {
    formSection: Object,
    isTaskReadOnly: {
      type: Boolean,
      default: true,
    },
    taskId: Number,
  },
  methods: {
    onAnsweringQuestion({ value, question }) {
      const payload = {
        question,
        answerContent: value,
      };
      this.$emit('input', payload);
    },
    onAnsweringFileQuestion({ value, question }) {
      const payload = {
        question,
        file: value,
      };
      this.$emit('fileInput', payload);
    },
    onDownloadFile(questionKey) {
      this.$emit('downloadFile', questionKey);
    },
    onDeleteFile(questionKey) {
      this.$emit('deleteFile', questionKey);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-container-actions {
  margin: 0rem;
  padding: 0.4rem;
}
.form-title {
  margin-top: 0rem;
}
.question-container {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.notes-form-container {
  padding: 0.4rem;
}
</style>
