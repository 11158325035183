<template>
  <div>
    <div class="info-container"><shortcuts-info /></div>
    <notes-container
      :notes="notes"
      @onCreateNote="onCreateNote"
      @onUpdateNote="onUpdateNote"
      @onDeleteNote="onDeleteNote"
    />
  </div>
</template>

<script>
import api from '@/api/api';
import notify from '@/utils/notificationsUtils';
import notesService from '@/services/notesService';
import NotesContainer from '@/components/Avalia/Note/NotesContainer.vue';
import ShortcutsInfo from './ShortcutsInfo.vue';

export default {
  name: 'notes-task-container',
  components: {
    NotesContainer,
    ShortcutsInfo,
  },
  computed: {
    taskId() {
      return this.$store.getters['task/GET_TASK_ID'];
    },
    taskName() {
      return this.$store.getters['task/GET_TASK_NAME'];
    },
    projectId() {
      return this.$store.getters['project/GET_PROJECT_ID'];
    },
    taskNotes() {
      return this.$store.getters['notes/GET_TASK_NOTES'];
    },
  },
  data() {
    return {
      notes: [],
    };
  },
  mounted() {
    this.filterTaskNotes();
  },
  methods: {
    filterTaskNotes() {
      this.notes = this.taskNotes.filter((note) => note.metadata.some((metadata) => metadata.key === 'relatedElement' && metadata.value === 'task'));
    },
    fetchCurrentTaskNotes() {
      const payload = {
        taskId: this.taskId,
        vm: this,
      };
      return this.$store.dispatch('notes/fetchTaskNotes', payload);
    },
    showSuccessMessageFetchAndFilterNotes(message) {
      this.fetchAndFilterNotes()
        .then(() => {
          notify.notifySuccess(message, this);
        });
    },
    fetchAndFilterNotes() {
      return this.fetchCurrentTaskNotes()
        .then(() => {
          this.filterTaskNotes();
        });
    },
    onCreateNote(notePayload) {
      const createPayload = {
        ...notePayload,
        metadata: [
          {
            key: 'relatedElement',
            value: 'task',
          },
          {
            key: 'relatedElementName',
            value: this.taskName,
          },
          {
            key: 'relatedElementId',
            value: this.taskId,
          },
          {
            key: 'relatedElementLink',
            value: `/project/${this.projectId}/task/${this.taskId}`,
          },
        ],
      };
      api.assess
        .post(`/tasks/${this.taskId}/notes`, createPayload)
        .then((response) => {
          this.showSuccessMessageFetchAndFilterNotes('Note successfully Saved');
        })
        .catch((error) => {
          const errorMessage = `Could not Save Note : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, this);
        });
    },
    onUpdateNote(notePayload) {
      return notesService.updateNote(notePayload, this)
        .then(() => this.fetchAndFilterNotes());
    },
    onDeleteNote(notePayload) {
      return notesService.deleteNote(notePayload, this)
        .then(() => this.fetchAndFilterNotes());
    },
  },
};
</script>

<style lang="scss" scoped>
.notes-container{
  padding-right: 0px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  position: relative;
}
// CUSTOM BUTTONS TO MOVE TO avalia.scss
.add-note-button {
  color: #27eb68 !important;
  background-color: white !important;
  border-style: solid !important;
  border-width: 2px !important;
  &:hover {
    color: white !important;
    background-color: #27eb68 !important;
    opacity: 0.8 !important;
    border-color: #27eb68 !important;
  }

  height: 1.4rem;
}
.add-note-button-container{
  height: 1.8rem;
}
.info-container {
  display: flex;
  justify-content: flex-end;
}
</style>
