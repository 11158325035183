<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100 nopadding">
      <div class="expert-opinion-container">
        <custom-editor
          :description="task.evaluation"
          :disabled="isTaskReadOnly"
          @inputContent="editorContentChanged"
          @save="onSaveExpertOpinion"
        ></custom-editor>
      </div>
    </div>
    <div class="md-layout-item md-size-100 expert-opinion-buttons-container">
      <span v-if="!isTaskReadOnly" @click="onSaveExpertOpinion">
        <md-icon class="icon-action-big icon-save">save</md-icon>
        <md-tooltip md-delay="300">Save Expert Opinion</md-tooltip>
      </span>
    </div>
  </div>
</template>

<script>
import api from '@/api/api';
import CustomEditor from '@/components/Avalia/Editor/CustomEditor.vue';
import notify from '@/utils/notificationsUtils';

export default {
  name: 'expert-opinion',
  components: {
    CustomEditor,
  },
  props: {
    task: Object,
    projectId: Number,
    isReadOnly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      contentHasChanged: false,
    };
  },
  computed: {
    isTaskReadOnly() {
      return this.isReadOnly;
    },
  },
  watch: {},
  created() {
    // On created
  },
  mounted() {
    // On mounted
    this.contentHasChanged = false;
  },
  methods: {
    fetchCurrentTask() {
      return this.$store.dispatch('task/fetchTask', { taskId: this.task.id, vm: this });
    },
    fetchProjectDetails() {
      return this.$store.dispatch('project/fetchProjectDetails', {
        projectId: this.projectId,
        vm: this,
      });
    },
    saveModifications() {
      if (this.contentHasChanged) {
        this.onSaveExpertOpinion();
      }
    },
    onSaveExpertOpinion() {
      const workPayload = {
        action: 'WORK',
        taskWorkDTO: {
          type: 'task evaluation',
          evaluation: this.task.evaluation,
        },
      };
      api.assess
        .post(`/tasks/${this.task.id}`, workPayload)
        .then((response) => this.fetchCurrentTask())
        .then((response) => this.fetchProjectDetails())
        .then((response) => {
          const successMessage = `Expert Opinion on Task ${this.task.name} successfully Submitted`;
          notify.notifySuccess(successMessage, this);
          this.contentHasChanged = false;
        })
        .catch((error) => {
          const errorMessage = `Could not Submit Expert Opinion on Task ${this.task.name} : ${error.response
            .data.message || ''}`;
          notify.notifyError(errorMessage, this);
          // console.log(error);
        });
    },
    editorContentChanged(value) {
      this.task.evaluation = value;
      this.contentHasChanged = true;
    },
  },
};
</script>

<style lang="scss" scoped>
// CUSTOM BUTTONS TO MOVE TO avalia.scss
.save-button {
  color: #27eb68 !important;
  background-color: white !important;
  border-style: solid !important;
  border-width: 2px !important;
  &:hover {
    color: white !important;
    background-color: #27eb68 !important;
    opacity: 0.8 !important;
    border-color: #27eb68 !important;
  }
}
.expert-opinion-container {
  margin-top: 1rem;
  margin-bottom: 1rem;
  position: relative; // fixes a positioning bug on tip-tap editor's menu-bubble
}
.expert-opinion-buttons-container {
  padding: 2rem 0.5rem 0.5rem;
}
</style>
