<template>
  <div class="task-work-log-view md-layout md-alignment-center">
    <div class="task-work-log-text">
      <span class="highlight">{{ taskWorkLog.user.displayName }}</span> logged
      {{ taskWorkLog.timeSpent }} hours ({{
        taskWorkLog.createdDate | moment("from")
      }}): {{ taskWorkLog.description }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'task-work-log',
  components: {},
  props: { taskWorkLog: Object },
  data() {
    return {};
  },
  computed: {
  },
  watch: {},
  created() {
    // On created
  },
  mounted() {
    // On mounted
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.highlight {
  font-weight: 600;
}
.task-work-log-view {
  border-radius: 0.6em;
  background-color: rgba(188, 198, 204, 0.2);
  padding: 0.4rem 1rem 0.4rem 1rem;
  margin: 0.2rem 0.2rem 0.4rem 0rem;
}
.task-work-log-text {
  margin: 0.2rem;
}
</style>
