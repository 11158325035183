<template>
  <div class="md-layout">
    <div class="md-layout-item md-layout md-size-100 md-medium-size-100">
      <div class="md-layout-item md-layout md-size-100 md-medium-size-100 nopadding">
        <div class="md-layout-item md-layout md-size-100 md-medium-size-100">
          <internal-breadcrumbs
            :sourceName="playbook.title"
            :currentName="eventFeedTitle"
            @showSource="onShowSource"
          />
        </div>
        <div class="md-layout-item md-size-100 groups-container">
          <el-divider></el-divider>
          <el-form :model="playbook">
            <el-form-item label="Group by" class="groups-form-item">
              <el-checkbox-group v-model="selectedGroupingOptions" size="small">
                <el-checkbox-button v-for="option in groupingOptions" :label="option.value" :key="option.value" @change="groupingChanged">
                  {{option.label}}
                </el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
          </el-form>
        </div>
        <div class="md-layout-item md-size-100 filters-container">
            <el-divider></el-divider>
            <span>Filters</span>
            <playbook-events-feed-filters
              :playbookId="playbookId"
              :taskId="taskId"
              @filterChanged="filterChanged">
            </playbook-events-feed-filters>
        </div>
        <div class="md-layout-item md-layout md-size-100 md-medium-size-100">
          <div class="md-layout-item md-size-100 md-medium-size-100">
            <div class="events-feed-container" v-infinite-scroll="load" :infinite-scroll-distance="10">
              <span v-for="(event, index) in events"
                :key="index"
                :event="event">
                <playbook-event-group v-if="isGrouped" :group="event" @filter="applyGroupAsFilters"></playbook-event-group>
                <playbook-event v-else :event="event"></playbook-event>
              </span>
              <p v-if="loading">Loading Events...</p>
              <p v-if="noMore">No more Events</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/api';
import notify from '@/utils/notificationsUtils';
import InternalBreadcrumbs from '@/components/Avalia/Breadcrumbs/InternalBreadcrumbs.vue';
import PlaybookEventGroup from '@/components/Avalia/TaskDetails/Playbook/Events/PlaybookEventGroup.vue';
import PlaybookEvent from '@/components/Avalia/TaskDetails/Playbook/Events/PlaybookEvent.vue';
import PlaybookEventsFeedFilters from '@/components/Avalia/TaskDetails/Playbook/Events/PlaybookEventsFeedFilters.vue';
import {
  InfiniteScroll,
  CheckboxGroup,
  CheckboxButton,
  Form,
  FormItem,
  Divider,
} from 'element-ui';
import moment from 'moment';

export default {
  name: 'playbook-events-feed',
  components: {
    InternalBreadcrumbs,
    PlaybookEventGroup,
    PlaybookEvent,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [CheckboxGroup.name]: CheckboxGroup,
    [CheckboxButton.name]: CheckboxButton,
    [Divider.name]: Divider,
    PlaybookEventsFeedFilters,
  },
  directives: {
    [InfiniteScroll.name]: InfiniteScroll,
  },
  props: {
    playbook: {
      type: Object,
      required: true,
    },
    taskId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      events: [],
      isGrouped: false,
      eventFeedTitle: 'Events Feed',
      loading: false,
      currentPage: 0,
      pageSize: 20,
      noMore: false,
      //totalPages: 0,
      selectedGroupingOptions: [],
      groupingOptions: [
        {
          label: 'date',
          value: 'date',
        },
        {
          label: 'author',
          value: 'author',
        },
        {
          label: 'target',
          value: 'targetName',
        },
        {
          label: 'type',
          value: 'targetType',
        },

      ],
    };
  },
  computed: {
    playbookId() {
      // eslint-disable-next-line no-underscore-dangle
      return this.playbook._id;
    },
    filters() {
      return this.$store.getters['playbookEventsFilters/GET_FILTERS'];
    },
    authorOptions() {
      return this.$store.getters['playbookEventsFilters/GET_OPTIONS_AUTHOR'];
    },
  },
  watch: {},
  created() {
    // On created
  },
  mounted() {
    // On mounted
  },
  methods: {
    applyGroupAsFilters(id) {
      this.reset();
      this.selectedGroupingOptions = [];
      //this.filters = [];
      this.resetFilters();
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(id)) {
        if (key === 'author') {
          const authorValue = this.authorOptions.find((a) => a.email === value.email);
          this.$store.commit('playbookEventsFilters/SET_FILTER', { key, value: authorValue });
        } else {
          this.$store.commit('playbookEventsFilters/SET_FILTER', { key, value });
        }
      }
      this.load();
    },
    filterChanged() {
      this.reset();
      this.load();
    },
    onShowSource() {
      this.$emit('viewPlaybook');
    },
    groupingChanged(eventValue) {
      this.reset();
      this.load();
    },
    reset() {
      this.events = [];
      this.currentPage = 0;
      this.noMore = false;
    },
    resetFilters() {
      this.$store.commit('playbookEventsFilters/RESET_FILTERS');
    },
    load() {
      if (!this.noMore && !this.loading) {
        this.loading = true;
        const payloadFilters = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(this.filters)) {
          if (value != null) {
            switch (key) {
              case 'date':
                payloadFilters.push({
                  type: 'LocalDateEqualsEventFilter',
                  key: 'date',
                  value: moment(`${value}`).local().format('YYYY-MM-DD'),
                });
                break;
              case 'author':
                payloadFilters.push({
                  type: 'ObjectEqualsEventFilter',
                  key,
                  value,
                  valueType: 'io.avalia.playbook.model.user.Author',
                });
                break;
              default:
                payloadFilters.push({
                  type: 'StringEqualsEventFilter',
                  key,
                  value,
                });
            }
          }
        }
        const commandPayload = {
          action: 'WORK',
          taskWorkDTO: {
            type: 'playbook task',
            command: {
              playbookId: this.playbookId,
              type: 'GetEventCustomCommand',
              pageable: {
                size: this.pageSize,
                page: this.currentPage++,
              },
              groups: this.selectedGroupingOptions,
              filters: payloadFilters,
            },
          },
        };
        api.assess.post(`/tasks/${this.taskId}`, commandPayload)
          .then((response) => {
            this.events = this.events.concat(response.data.page.content);
            this.noMore = response.data.page.empty;
            this.isGrouped = response.data.isGrouped;
          })
          .catch((error) => {
            const errorMessage = `Could not Fetch Playbook Events : ${error.response.data.message || ''}`;
            notify.notifyError(errorMessage, this);
          }).finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.section-title {
  margin-bottom: 1rem;
  padding-left: 1.2rem;
}
.events-feed-container{
  max-height: 600px;
  overflow-y: auto;
}
.filters-container {
  padding-left: 1.5rem;
  margin-bottom: 2rem;
}
.groups-container {
  padding-left: 1.5rem;
}
.groups-form-item {
  margin-bottom: 0rem;
}
/**/
.el-checkbox-button.is-checked {
  &::v-deep .el-checkbox-button__inner {
    background-color: $avalia-cyan;
    border-color: #8cc5ff;
    //border-left-color: white;
    -webkit-box-shadow: -1px 0 0 0 white;
    box-shadow: -1px 0 0 0 white;
  }
}
.el-checkbox-button.is-checked:hover {
  &::v-deep .el-checkbox-button__inner {
    color: #FFF;
  }
}
.el-checkbox-button:hover {
  &::v-deep .el-checkbox-button__inner {
    color: $avalia-cyan;
  }
}
.el-checkbox-button.is-focus {
  &::v-deep .el-checkbox-button__inner {
    border-color: $avalia-cyan;
  }
}

</style>
