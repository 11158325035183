<template>
  <div>
    <el-col :span="24">
      <h5 class="section-title">
        Additional properties
        <span
          class="saving-button"
          :class="{
            saved: status === UpdateStatus.SAVED,
            failed: status === UpdateStatus.FAILED,
            updating: status === UpdateStatus.UPDATING,
            editing: status === UpdateStatus.EDITING,
          }"
        ></span>
      </h5>
    </el-col>
    <div
      v-for="key in Object.keys(element.additionalProperties)"
      :key="key"
      class="el-form additional-properties-form"
    >
      <h5 class="prop-name">
        {{key}}
      </h5>

      <el-form id="additional-property-form" label-width="36px">
        <el-form-item>
          <!-- VALUE OF THE PROPERTY -->
          <el-col :span="17"
            label="value"
            v-if="element.additionalProperties[key].type === 'text'"
          >
            <el-input
              @change="element.additionalProperties[key].value === copyOfProperties[key].value ? '': updateAdditionalProperty(key)"
              @input="element.additionalProperties[key].value === copyOfProperties[key].value ? '': onAdditionalPropertyFormInputChange()"
              type="textarea"
              :autosize="{ minRows: 1, maxRows: 4 }"
              placeholder="Enter the field's value"
              v-model="element.additionalProperties[key].value"
            >
            </el-input>
          </el-col>
          <el-col :span="17"
            label="value"
            v-if="element.additionalProperties[key].type === 'json'"
          >
            <el-input
              @change="element.additionalProperties[key].value === copyOfProperties[key].value ? '': updateAdditionalProperty(key)"
              @input="element.additionalProperties[key].value === copyOfProperties[key].value ? '': onAdditionalPropertyFormInputChange()"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
              placeholder="Enter the field's value"
              v-model="element.additionalProperties[key].value"
            >
            </el-input>
          </el-col>
          <el-col :span="17"
            label="value"
            v-if="element.additionalProperties[key].type === 'boolean'"
          >
            <el-checkbox
                v-model="element.additionalProperties[key].value"
                label="Checked"
                name="Checked"
              @change="element.additionalProperties[key].value === copyOfProperties[key].value ? '': updateAdditionalProperty(key)"
              @input="element.additionalProperties[key].value === copyOfProperties[key].value ? '': onAdditionalPropertyFormInputChange()"
              >
            </el-checkbox>
          </el-col>
          <el-col :span="17"
            label="value"
            v-if="element.additionalProperties[key].type === 'date'"
          >
            <el-date-picker
              @change="element.additionalProperties[key].value === copyOfProperties[key].value ? '': updateAdditionalProperty(key)"
              @input="element.additionalProperties[key].value === copyOfProperties[key].value ? '': onAdditionalPropertyFormInputChange()"
              v-model="element.additionalProperties[key].value"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="Select a date"
              :default-time="new Date()"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="17"
            label="value"
            v-if="element.additionalProperties[key].type === 'number'"
          >
            <el-input-number
              @change="element.additionalProperties[key].value === copyOfProperties[key].value ? '': updateAdditionalProperty(key)"
              @blur="element.additionalProperties[key].value === copyOfProperties[key].value ? '': onAdditionalPropertyFormInputChange()"
              v-model="element.additionalProperties[key].value"
            ></el-input-number>
          </el-col>
          <el-col :span="17"
            label="value"
            v-if="element.additionalProperties[key].type === 'percentage'"
          >
            <el-slider
              v-model="element.additionalProperties[key].value"
              @change="element.additionalProperties[key].value === copyOfProperties[key].value ? '': updateAdditionalProperty(key)"
              @input="element.additionalProperties[key].value === copyOfProperties[key].value ? '': onAdditionalPropertyFormInputChange()"
            ></el-slider>
            <p style="text-align: center; margin: 0; line-height: 0">
              {{ element.additionalProperties[key].value }}
            </p>
          </el-col>
          <!-- TYPE OF THE PROPERTY -->
          <el-col :span="4" :offset="1">
            <el-select
              v-model="element.additionalProperties[key].type"
              @change="element.additionalProperties[key].type === copyOfProperties[key].type ? '': updateAdditionalPropertyType(key)"
            >
              <el-option value="text">Textarea</el-option>
              <el-option value="number">Number</el-option>
              <el-option value="boolean">Boolean</el-option>
              <el-option value="percentage">Percentage</el-option>
              <el-option value="date">Date</el-option>
              <el-option value="json">JSON</el-option>
              <!--<el-option value="html">Html</el-option>-->
            </el-select>
          </el-col>

          <!-- DELETE BUTTON -->
          <el-col :span="1" :offset="1" class="align-right hide-button">
              <span @click="showDeletionConfirmationPopUp(key)">
                <md-icon class="icon-action icon-action icon-delete"
                  >archive</md-icon
                >
                <md-tooltip md-delay="300"
                  >Delete additional property : "{{ key }}"</md-tooltip
                >
              </span>
          </el-col>
        </el-form-item>
      </el-form>
    </div>

    <el-form label-width="120px">
      <el-form-item v-if="addAdditionalPropertyFormDisplayed">
        <el-input
          v-model="newKeyName"
          placeholder="Create an additional property"
          @keydown.enter.prevent.native="createNewAdditionalProperty()"
          @blur="createNewAdditionalProperty()"
          ref="additionalPropertyInput"
        >
        </el-input>
      </el-form-item>
      <el-form-item v-else>
        <el-col :span="1" :offset="23">
          <span @click="displayAddAdditionalPropertyForm">
            <md-icon class="icon-action">add</md-icon>
            <md-tooltip md-delay="300"
              >Add additional property</md-tooltip
            >
          </span>
        </el-col>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import api from '@/api/api';
import notify from '@/utils/notificationsUtils';
import {
  Form,
  FormItem,
  Input,
  DatePicker,
  Button,
  Col,
  Checkbox,
  Select,
  Option,
  InputNumber,
  Slider,
} from 'element-ui';
import Swal from 'sweetalert2';
import UpdateStatus from '../../UpdateStatus';

//import notify from '@/utils/notificationsUtils';
const swalWithCustomButtons = Swal.mixin({
  customClass: {
    confirmButton: 'md-button md-success btn-fill',
    cancelButton: 'md-button md-danger btn-fill',
  },
  buttonsStyling: false,
});

export default {
  name: 'additional-properties',
  components: {
    [Form.name]: Form,
    [Input.name]: Input,
    [DatePicker.name]: DatePicker,
    [FormItem.name]: FormItem,
    [Button.name]: Button,
    [Col.name]: Col,
    [Select.name]: Select,
    [Option.name]: Option,
    [InputNumber.name]: InputNumber,
    [Slider.name]: Slider,
    [Checkbox.name]: Checkbox,
  },
  props: {
    element: {
      type: Object,
      required: true,
    },
    parentContainer: {
      type: Object,
      required: false,
    },
    playbook: {
      type: Object,
      required: true,
    },
    task: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      newKeyName: '',
      status: UpdateStatus.SAVED,
      UpdateStatus,
      addAdditionalPropertyFormDisplayed: false,
      copyOfProperties: {},
    };
  },
  mounted() {
    this.saveCopyOfProperties();
  },
  methods: {
    // -------- Navigation ----------
    displayAddAdditionalPropertyForm() {
      this.addAdditionalPropertyFormDisplayed = true;
      this.$nextTick(() => {
        this.$refs.additionalPropertyInput.focus();
      });
    },
    hideAddAdditionalPropertyForm() {
      this.addAdditionalPropertyFormDisplayed = false;
    },
    // --------- Additional properties actions ---------
    onAdditionalPropertyFormInputChange() {
      this.status = UpdateStatus.EDITING;
    },
    showDeletionConfirmationPopUp(key) {
      swalWithCustomButtons
        .fire({
          title: 'Are you sure you want to delete this additional property :',
          text: `"${key}"`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, remove it!',
          buttonsStyling: false,
        })
        .then((result) => {
          if (result.value) {
            this.deleteAdditionalProperty(key);
          }
        });
    },
    deleteAdditionalProperty(key) {
      this.sendAdditionalPropertyCommand({
        type: 'DeleteAdditionalPropertyCommand',
        key,
      })
        .then(() => {
          delete this.element.additionalProperties[key];
          this.$forceUpdate(); // TODO : Improve
          this.status = UpdateStatus.SAVED;
          this.fetchPlaybook();
        });
    },
    updateAdditionalPropertyType(key) {
      if (this.element.additionalProperties[key].type === 'date') {
        // TODO : Improve
        // eslint-disable-next-line no-param-reassign
        this.element.additionalProperties[key].value = new Date();
      } else if (
        this.element.additionalProperties[key].type === 'percentage'
        || this.element.additionalProperties[key].type === 'number'
      ) {
        // TODO : Improve
        // eslint-disable-next-line no-param-reassign
        this.element.additionalProperties[key].value = 0;
      } else if (
        this.element.additionalProperties[key].type === 'boolean'
      ) {
        // TODO : Improve
        // eslint-disable-next-line no-param-reassign
        this.element.additionalProperties[key].value = false;
      }
      this.sendAdditionalPropertyCommand({
        type: 'UpdateAdditionalPropertyCommand',
        key,
        value: this.element.additionalProperties[key],
      })
        .then(() => {
          this.status = UpdateStatus.SAVED;
          this.fetchPlaybook();
        });
    },
    updateAdditionalProperty(key) {
      this.sendAdditionalPropertyCommand({
        type: 'UpdateAdditionalPropertyCommand',
        key,
        value: this.element.additionalProperties[key] || '',
      })
        .then(() => {
          this.status = UpdateStatus.SAVED;
          this.fetchPlaybook();
        });
    },
    createNewAdditionalProperty() {
      if (this.addAdditionalPropertyFormDisplayed) {
        if (this.newKeyName) {
          this.sendAdditionalPropertyCommand({
            type: 'AddAdditionalPropertyCommand',
            key: this.newKeyName,
            value: { type: 'text', value: '' },
          })
            .then(() => {
              this.status = UpdateStatus.SAVED;
              this.$set(this.element.additionalProperties, this.newKeyName, {
                type: 'text',
                value: '',
              });
              this.newKeyName = '';
              this.fetchPlaybook();
            });
        }
        this.hideAddAdditionalPropertyForm();
      }
    },
    sendAdditionalPropertyCommand(partialCommand) {
      const conditionCommand = {
        // eslint-disable-next-line no-underscore-dangle
        playbookId: this.playbook._id,
        elementId: this.element.id,
        ...partialCommand,
      };
      const answerPayload = {
        action: 'WORK',
        taskWorkDTO: {
          type: 'playbook task',
          command: conditionCommand,
        },
      };
      this.status = UpdateStatus.UPDATING;
      return api.assess
        .post(`/tasks/${this.task.id}`, answerPayload)
        .catch((error) => {
          const errorMessage = `Could not save Activity : ${
            error.response.data.message || ''
          }`;
          notify.notifyError(errorMessage, this);
          this.status = UpdateStatus.FAILED;
        });
    },
    // -------- Fetch playbook -----------
    fetchPlaybook() {
      const payload = {
        taskId: this.task.id,
        vm: this,
      };
      return this.$store.dispatch('playbooks/fetchPlaybook', payload).then(this.saveCopyOfProperties);
    },
    saveCopyOfProperties() {
      this.copyOfProperties = JSON.parse(JSON.stringify(this.element.additionalProperties));
    },
  },
};
</script>

<style lang="scss" scoped>

hr {
  border-width: 1px 0px 0px 0px;
  border-style: solid;
  border-color: #c8c8c8;
}
.section-title {
  margin-bottom: 1rem;
  padding-left: 1.2rem;
}
.prop-name {
  font-size: 0.95rem;
  margin-bottom: 1rem;
  padding-left: 2rem;
}
.create-additional-property {
  margin-top: 1rem;
}
.button-container {
  padding-left: 1rem;
  text-align: right;
}
.line {
  text-align: center;
}
.align-right {
  text-align: right;
}
.additional-properties-form {
  .delete-button {
    transition: opacity 0.3s ease-out;
    opacity: 0;
  }
  &:hover .delete-button {
    transition: opacity 0.3s ease-in;
    opacity: 1;
  }
}

.additional-properties-form{
  .hide-button{
    text-align: right;
    transition: opacity 0.3s ease-out;
    opacity: 0;
  }
  &:hover .hide-button{
    transition: opacity 0.3s ease-in;
    opacity: 1;
  }
}

</style>
