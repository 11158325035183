<template>
  <div class="md-layout nopadding">
    <div class="md-layout-item md-layout md-size-100 md-medium-size-100 nopadding">
      <div class="md-layout-item md-size-35 filter-container-date">
        <span>Filter By Date</span>
          <el-date-picker
            v-model="filterDate"
            type="date"
            placeholder="Pick a day"
            :picker-options="pickerOptions"
            @change="filterChanged">
          </el-date-picker>
      </div>
      <div class="md-layout-item md-size-21 filter-container">
        <span>Filter By Author</span>
        <el-select
          v-model="filterAuthor"
          value-key="email"
          placeholder="Pick an Author"
          clearable
          @change="filterChanged">
          <el-option
            v-for="item in authorOptions"
            :key="item.email"
            :label="item.firstName + ' ' + item.lastName"
            :value="item">
          </el-option>
        </el-select>
      </div>
      <div class="md-layout-item md-size-21 filter-container">
        <span>Filter By Target Type</span>
        <el-select
          v-model="filterTargetType"
          placeholder="Pick a Target Type"
          clearable
          @change="filterChanged">
          <el-option
            v-for="item in targetTypeOptions"
            :key="item"
            :label="item"
            :value="item">
          </el-option>
        </el-select>
      </div>
      <div class="md-layout-item md-size-21 filter-container">
        <span>Filter By Target</span>
        <el-select
          v-model="filterTargetName"
          placeholder="Pick a Target"
          clearable
          @change="filterChanged">
          <el-option
            v-for="item in targetNameOptions"
            :key="item"
            :label="item"
            :value="item">
          </el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
//import api from '@/api/api';
//import notify from '@/utils/notificationsUtils';
/*
import InternalBreadcrumbs from '@/components/Avalia/Breadcrumbs/InternalBreadcrumbs.vue';
import PlaybookEventGroup from '@/components/Avalia/TaskDetails/Playbook/Events/PlaybookEventGroup.vue';
import PlaybookEvent from '@/components/Avalia/TaskDetails/Playbook/Events/PlaybookEvent.vue';
*/
import {
  Select,
  Option,
  DatePicker,
  Button,
} from 'element-ui';
//import moment from 'moment';

export default {
  name: 'playbook-events-feed-filters',
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
    [Button.name]: Button,
  },
  props: {
    playbookId: {
      type: String,
      required: true,
    },
    taskId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: 'Today',
          onClick(picker) {
            picker.$emit('pick', new Date());
          },
        }, {
          text: 'Yesterday',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          },
        }, {
          text: 'A week ago',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          },
        }],
      },
      optionNames: ['author', 'targetType', 'targetName'],
    };
  },
  computed: {
    filters() {
      return this.$store.getters['playbookEventsFilters/GET_FILTERS'];
    },
    filterDate: {
      get() {
        return this.$store.getters['playbookEventsFilters/GET_FILTER_DATE'];
      },
      set(value) {
        this.$store.commit('playbookEventsFilters/SET_FILTER_DATE', value);
      },
    },
    filterAuthor: {
      get() {
        return this.$store.getters['playbookEventsFilters/GET_FILTER_AUTHOR'];
      },
      set(value) {
        if (value === null || value === '') {
          this.$store.commit('playbookEventsFilters/RESET_FILTER_AUTHOR');
        } else {
          this.$store.commit('playbookEventsFilters/SET_FILTER_AUTHOR', value);
        }
      },
    },
    filterTargetName: {
      get() {
        return this.$store.getters['playbookEventsFilters/GET_FILTER_TARGET'];
      },
      set(value) {
        if (value === null || value === '') {
          this.$store.commit('playbookEventsFilters/RESET_FILTER_TARGET');
        } else {
          this.$store.commit('playbookEventsFilters/SET_FILTER_TARGET', value);
        }
      },
    },
    filterTargetType: {
      get() {
        return this.$store.getters['playbookEventsFilters/GET_FILTER_TARGET_TYPE'];
      },
      set(value) {
        if (value === null || value === '') {
          this.$store.commit('playbookEventsFilters/RESET_FILTER_TARGET_TYPE');
        } else {
          this.$store.commit('playbookEventsFilters/SET_FILTER_TARGET_TYPE', value);
        }
      },
    },
    authorOptions() {
      return this.$store.getters['playbookEventsFilters/GET_OPTIONS_AUTHOR'];
    },
    targetTypeOptions() {
      return this.$store.getters['playbookEventsFilters/GET_OPTIONS_TARGET_TYPE'];
    },
    targetNameOptions() {
      return this.$store.getters['playbookEventsFilters/GET_OPTIONS_TARGET'];
    },
  },
  watch: {},
  created() {
    // On created
    this.resetFilters();
    this.optionNames.forEach((optionName) => this.fetchPlaybookOption(optionName));
  },
  mounted() {
    // On mounted
  },
  methods: {
    filterChanged(value) {
      this.$emit('filterChanged');
    },
    fetchPlaybookOption(key) {
      const payload = {
        playbookId: this.playbookId,
        taskId: this.taskId,
        key,
        valueType: key === 'author' ? 'io.avalia.playbook.model.user.Author' : null,
        vm: this,
      };
      this.$store.dispatch('playbookEventsFilters/fetchPlaybookOption', payload);
    },
    resetFilters() {
      this.$store.commit('playbookEventsFilters/RESET_FILTERS');
    },
  },
};
</script>

<style lang="scss" scoped>
.section-title {
  margin-bottom: 1rem;
  padding-left: 1.2rem;
}
.events-feed-container{
  max-height: 600px;
  overflow-y: auto;
}
.filter-container-date {
  padding-left: 0rem;
  display: flex;
  flex-direction: column;
}
.filter-container{
  display: flex;
  flex-direction: column;
}
.el-date-editor.el-input {
  width: auto;
}
</style>
