<template>
  <div class="md-layout">
    <div class="md-layout-item md-layout md-size-100 md-medium-size-100">
      <div class="md-layout-item md-size-100 md-medium-size-100 nopadding">
        <div class="md-layout-item md-size-100 md-medium-size-100">
          <internal-breadcrumbs
            :sourceName="playbook.title"
            :currentName="activity.title"
            @showSource="onShowSource"
          />
        </div>
        <div>
          <div class="md-layout">
            <div class="md-layout-item md-size-100 md-medium-size-100">
              <h5 class="section-title">
                Activity
                <span
                  class="saving-button"
                  :class="{
                    saved: status === UpdateStatus.SAVED,
                    failed: status === UpdateStatus.FAILED,
                    updating: status === UpdateStatus.UPDATING,
                    editing: status === UpdateStatus.EDITING
                  }"
                >
                </span>
              </h5>
              <el-form :model="activity" label-width="120px" :rules="rules" ref="activity">
                <el-form-item label="Id" prop="id">
                  <el-col :span="18">
                    <el-input v-model="activity.id" :disabled="true"></el-input>
                  </el-col>
                  <el-col :span="6" class="align-right">
                    <span @click="toggleFlag">
                      <md-icon class="icon-action" :class="flagClass">flag</md-icon>
                      <md-tooltip md-delay="300">Flag activity</md-tooltip>
                    </span>
                  </el-col>
                </el-form-item>
                <el-form-item label="Category" prop="category">
                  <el-input v-model="parentContainer.title" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="Title" prop="title">
                  <div class="form-input">
                    <el-input
                      type="textarea"
                      autosize
                      v-model="activity.title"
                      @blur="
                        activityCopy.title != activity.title
                          ? sendUpdateActivityCommand({ title: activity.title })
                          : ''
                      "
                      @keydown.enter.prevent.native="
                        () =>
                          activityCopy.title != activity.title
                            ? sendUpdateActivityCommand({ title: activity.title })
                            : ''
                      "
                      @input="onActivityFormInputChange(activityCopy.title, activity.title)"
                    >
                    </el-input>
                    <history
                      :taskId="task.id"
                      :playbookId="playbook._id"
                      :targetId="activity.id"
                      field="title"
                      @restore="
                        status = UpdateStatus.EDITING;
                        sendUpdateActivityCommand({ title: $event.value ||'' });
                      "
                    />
                  </div>
                </el-form-item>
                <el-form-item label="Activity Type" prop="activityType">
                  <div class="form-input">
                    <el-input
                      v-model="activity.activityType"
                      @blur="
                        activityCopy.activityType != activity.activityType
                          ? sendUpdateActivityCommand({ activityType: activity.activityType })
                          : ''
                      "
                      @keydown.enter.prevent.native="
                        () =>
                          activityCopy.activityType != activity.activityType
                            ? sendUpdateActivityCommand({ activityType: activity.activityType })
                            : ''
                      "
                      @input="
                        onActivityFormInputChange(activityCopy.activityType, activity.activityType)
                      "
                    >
                    </el-input>
                    <history
                      :taskId="task.id"
                      :playbookId="playbook._id"
                      :targetId="activity.id"
                      field="activityType"
                      @restore="
                        status = UpdateStatus.EDITING;
                        sendUpdateActivityCommand({ activityType: $event.value ||'' });
                      "
                    />
                  </div>
                </el-form-item>
                <el-form-item label="Activity Code" prop="activityCode">
                  <div class="form-input">
                    <el-input
                      v-model="activity.activityCode"
                      @blur="
                        activityCopy.activityCode != activity.activityCode
                          ? sendUpdateActivityCommand({ activityCode: activity.activityCode })
                          : ''
                      "
                      @keydown.enter.prevent.native="
                        () =>
                          activityCopy.activityCode != activity.activityCode
                            ? sendUpdateActivityCommand({ activityCode: activity.activityCode })
                            : ''
                      "
                      @input="
                        onActivityFormInputChange(activityCopy.activityCode, activity.activityCode)
                      "
                    >
                    </el-input>
                    <history
                      :taskId="task.id"
                      :playbookId="playbook._id"
                      :targetId="activity.id"
                      field="activityCode"
                      @restore="
                        status = UpdateStatus.EDITING;
                        sendUpdateActivityCommand({ activityCode: $event.value ||'' });
                      "
                    />
                  </div>
                </el-form-item>
                <el-form-item label="Status" prop="status">
                  <div class="form-input">
                    <el-input
                      v-model="activity.status"
                      @blur="
                        activityCopy.status != activity.status
                          ? sendUpdateActivityCommand({ status: activity.status })
                          : ''
                      "
                      @keydown.enter.prevent.native="
                        () =>
                          activityCopy.status != activity.status
                            ? sendUpdateActivityCommand({ status: activity.status })
                            : ''
                      "
                      @input="onActivityFormInputChange(activityCopy.status, activity.status)"
                    >
                    </el-input>
                    <history
                      :taskId="task.id"
                      :playbookId="playbook._id"
                      :targetId="activity.id"
                      field="status"
                      @restore="
                        status = UpdateStatus.EDITING;
                        sendUpdateActivityCommand({ status: $event.value ||'' });
                      "
                    />
                  </div>
                </el-form-item>
                <el-form-item label="Owner" prop="owner">
                  <div class="form-input">
                    <el-input
                      v-model="activity.owner"
                      @blur="
                        activityCopy.owner != activity.owner
                          ? sendUpdateActivityCommand({ owner: activity.owner })
                          : ''
                      "
                      @keydown.enter.prevent.native="
                        () =>
                          activityCopy.owner != activity.owner
                            ? sendUpdateActivityCommand({ owner: activity.owner })
                            : ''
                      "
                      @input="onActivityFormInputChange(activityCopy.owner, activity.owner)"
                    >
                    </el-input>
                    <history
                      :taskId="task.id"
                      :playbookId="playbook._id"
                      :targetId="activity.id"
                      field="owner"
                      @restore="
                        status = UpdateStatus.EDITING;
                        sendUpdateActivityCommand({ owner: $event.value ||'' });
                      "
                    />
                  </div>
                </el-form-item>
                <el-form-item label="Assignee" prop="assignedTo"
                  ><div class="form-input">
                    <el-input
                      v-model="activity.assignedTo"
                      @blur="
                        activityCopy.assignedTo != activity.assignedTo
                          ? sendUpdateActivityCommand({ assignedTo: activity.assignedTo })
                          : ''
                      "
                      @keydown.enter.prevent.native="
                        () =>
                          activityCopy.assignedTo != activity.assignedTo
                            ? sendUpdateActivityCommand({ assignedTo: activity.assignedTo })
                            : ''
                      "
                      @input="
                        onActivityFormInputChange(activityCopy.assignedTo, activity.assignedTo)
                      "
                    >
                    </el-input>
                    <history
                      :taskId="task.id"
                      :playbookId="playbook._id"
                      :targetId="activity.id"
                      field="assignedTo"
                      @restore="
                        status = UpdateStatus.EDITING;
                        sendUpdateActivityCommand({ assignedTo: $event.value ||'' });
                      "
                    />
                  </div>
                </el-form-item>
                <el-form-item label="Client focal point" prop="clientFocalPoint"
                  ><div class="form-input">
                    <el-input
                      v-model="activity.clientFocalPoint"
                      @blur="
                        activityCopy.clientFocalPoint != activity.clientFocalPoint
                          ? sendUpdateActivityCommand({
                              clientFocalPoint: activity.clientFocalPoint
                            })
                          : ''
                      "
                      @keydown.enter.prevent.native="
                        () =>
                          activityCopy.clientFocalPoint != activity.clientFocalPoint
                            ? sendUpdateActivityCommand({
                                clientFocalPoint: activity.clientFocalPoint
                              })
                            : ''
                      "
                      @input="
                        onActivityFormInputChange(
                          activityCopy.clientFocalPoint,
                          activity.clientFocalPoint
                        )
                      "
                    >
                    </el-input>
                    <history
                      :taskId="task.id"
                      :playbookId="playbook._id"
                      :targetId="activity.id"
                      field="clientFocalPoint"
                      @restore="
                        status = UpdateStatus.EDITING;
                        sendUpdateActivityCommand({ clientFocalPoint: $event.value ||'' });
                      "
                    />
                  </div>
                </el-form-item>
                <el-form-item label="Summary" prop="summary">
                  <div class="form-input">
                    <el-input
                      type="textarea"
                      v-model="activity.summary"
                      style="width: 100%;"
                      prop="summary"
                      @blur="
                        activityCopy.summary != activity.summary
                          ? sendUpdateActivityCommand({ summary: activity.summary })
                          : ''
                      "
                      @input="onActivityFormInputChange(activityCopy.summary, activity.summary)"
                    ></el-input>
                    <history
                      :taskId="task.id"
                      :playbookId="playbook._id"
                      :targetId="activity.id"
                      field="summary"
                      @restore="
                        status = UpdateStatus.EDITING;
                        sendUpdateActivityCommand({ summary: $event.value ||'' });
                      "
                    />
                  </div>
                </el-form-item>
                <el-form-item label="Budget" prop="budget">
                  <div class="form-input">
                    <el-input
                      type="textarea"
                      v-model="activity.budget"
                      style="width: 100%;"
                      prop="budget"
                      @blur="
                        activityCopy.budget != activity.budget
                          ? sendUpdateActivityCommand({ budget: activity.budget })
                          : ''
                      "
                      @input="onActivityFormInputChange(activityCopy.budget, activity.budget)"
                    ></el-input>
                    <history
                      :taskId="task.id"
                      :playbookId="playbook._id"
                      :targetId="activity.id"
                      field="budget"
                      @restore="
                        status = UpdateStatus.EDITING;
                        sendUpdateActivityCommand({ budget: $event.value ||'' });
                      "
                    />
                  </div>
                </el-form-item>
                <el-form-item label="Start date">
                  <div class="form-input">
                    <el-date-picker
                      type="date"
                      value-format="yyyy-MM-dd"
                      placeholder="Start date"
                      v-model="activity.startDate"
                      style="width: 100%;"
                      prop="startDate"
                      @change="
                        activityCopy.startDate != activity.startDate &&
                          sendUpdateActivityCommand({ startDate: activity.startDate || '' })
                      "
                      @input="onActivityFormInputChange(activityCopy.startDate, activity.startDate)"
                    >
                    </el-date-picker>
                    <history
                      :taskId="task.id"
                      :playbookId="playbook._id"
                      :targetId="activity.id"
                      field="startDate"
                      @restore="
                        status = UpdateStatus.EDITING;
                        sendUpdateActivityCommand({ startDate: $event.value ||'' });
                      "
                    />
                  </div>
                </el-form-item>
                <el-form-item label="Due date">
                  <div class="form-input">
                    <el-date-picker
                      type="date"
                      value-format="yyyy-MM-dd"
                      placeholder="Due date"
                      v-model="activity.dueDate"
                      style="width: 100%;"
                      prop="dueDate"
                      @change="
                        activityCopy.dueDate != activity.dueDate &&
                          sendUpdateActivityCommand({ dueDate: activity.dueDate || '' })
                      "
                      @input="onActivityFormInputChange(activityCopy.dueDate, activity.dueDate)"
                    >
                    </el-date-picker>
                    <history
                      :taskId="task.id"
                      :playbookId="playbook._id"
                      :targetId="activity.id"
                      field="dueDate"
                      @restore="
                        status = UpdateStatus.EDITING;
                        sendUpdateActivityCommand({ dueDate: $event.value ||'' });
                      "
                    />
                  </div>
                </el-form-item>
                <el-form-item label="New finish date">
                  <div class="form-input">
                    <el-date-picker
                      type="date"
                      value-format="yyyy-MM-dd"
                      placeholder="New finish date"
                      v-model="activity.newFinishDate"
                      style="width: 100%;"
                      prop="newFinishDate"
                      @input="
                        onActivityFormInputChange(
                          activityCopy.newFinishDate,
                          activity.newFinishDate
                        )
                      "
                      @change="
                        activityCopy.newFinishDate != activity.newFinishDate &&
                          sendUpdateActivityCommand({ newFinishDate: activity.newFinishDate || '' })
                      "
                    >
                    </el-date-picker>
                    <history
                      :taskId="task.id"
                      :playbookId="playbook._id"
                      :targetId="activity.id"
                      field="newFinishDate"
                      @restore="
                        status = UpdateStatus.EDITING;
                        sendUpdateActivityCommand({ newFinishDate: $event.value ||'' });
                      "
                    />
                  </div>
                </el-form-item>
                <el-form-item label="Remaining effort" prop="effort">
                  <div class="form-input">
                    <el-input
                      v-model="activity.remainingEffort"
                      style="width: 100%;"
                      prop="effort"
                      @blur="
                        activityCopy.remainingEffort != activity.remainingEffort
                          ? sendUpdateActivityCommand({ remainingEffort: activity.remainingEffort })
                          : ''
                      "
                      @keydown.enter.prevent.native="
                        () =>
                          activityCopy.remainingEffort != activity.remainingEffort
                            ? sendUpdateActivityCommand({
                                remainingEffort: activity.remainingEffort
                              })
                            : ''
                      "
                      @input="
                        onActivityFormInputChange(
                          activityCopy.remainingEffort,
                          activity.remainingEffort
                        )
                      "
                    >
                    </el-input>
                    <history
                      :taskId="task.id"
                      :playbookId="playbook._id"
                      :targetId="activity.id"
                      field="remainingEffort"
                      @restore="
                        status = UpdateStatus.EDITING;
                        sendUpdateActivityCommand({ remainingEffort: $event.value ||'' });
                      "
                    />
                  </div>
                </el-form-item>
              </el-form>
              <!-- NOTES ------------------------------------------------->
              <playbook-notes
                :task="task"
                :playbook="playbook"
                :element="activity"
                :containerType="'Activity'"
              />
              <!-- CONDITIONS ------------------------------------------------->
              <conditions
                :task="task"
                :playbook="playbook"
                :parentContainer="parentContainer"
                :activity="activity"
              />

              <!-- MILESTONES ------------------------------------------------->
              <milestones
                :task="task"
                :playbook="playbook"
                :parentContainer="parentContainer"
                :activity="activity"
              />

              <!-- Resources ------------------------------------------------->
              <resources
                :task="task"
                :playbook="playbook"
                :parentContainer="parentContainer"
                :activity="activity"
              />

              <!-- Addition properties ------------------------------------------------->
              <additional-properties
                :task="task"
                :playbook="playbook"
                :parentContainer="parentContainer"
                :element="activity"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/api';
import notify from '@/utils/notificationsUtils';
import InternalBreadcrumbs from '@/components/Avalia/Breadcrumbs/InternalBreadcrumbs.vue';
import {
  Form,
  FormItem,
  Input,
  DatePicker,
  Button,
  Col,
  Checkbox,
  Select,
  Option,
  InputNumber,
  Slider,
} from 'element-ui';
import Conditions from './Conditions.vue';
import Milestones from './Milestones.vue';
import Resources from './Resources.vue';
import UpdateStatus from '../../UpdateStatus';
import AdditionalProperties from './AdditionalProperties.vue';
import History from '../History.vue';
import PlaybookNotes from '../Notes/PlaybookNotes.vue';
//import notify from '@/utils/notificationsUtils';

export default {
  name: 'activity-details',
  components: {
    InternalBreadcrumbs,
    Conditions,
    Milestones,
    Resources,
    [Form.name]: Form,
    [Input.name]: Input,
    [DatePicker.name]: DatePicker,
    [FormItem.name]: FormItem,
    [Button.name]: Button,
    [Col.name]: Col,
    [Select.name]: Select,
    [Option.name]: Option,
    [InputNumber.name]: InputNumber,
    [Slider.name]: Slider,
    [Checkbox.name]: Checkbox,
    AdditionalProperties,
    History,
    PlaybookNotes,
  },
  props: {
    activity: {
      type: Object,
      required: true,
    },
    parentContainer: {
      type: Object,
      required: true,
    },
    playbook: {
      type: Object,
      required: true,
    },
    task: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isNewConditionFieldOpen: false,
      status: UpdateStatus.SAVED,
      UpdateStatus,
      rules: {
        title: [{ required: true, message: 'Please input an Activity title', trigger: 'blur' }],
      },
      activityCopy: {},
    };
  },
  computed: {
    flagClass() {
      return this.activity.isFlagged ? 'red-flag' : 'gray-flag';
    },
  },
  watch: {},
  created() {
    // On created
  },
  mounted() {
    this.saveActivityToCopy();
  },
  methods: {
    // -------- Navigation -----------
    onActivityFormInputChange(oldValue, newValue) {
      if (oldValue !== newValue) {
        this.status = UpdateStatus.EDITING;
      } else {
        this.status = UpdateStatus.SAVED;
      }
    },
    onActivityCheckboxInputChange(partialCommand) {
      this.status = UpdateStatus.EDITING;
      this.sendUpdateActivityCommand(partialCommand);
    },
    onShowSource() {
      this.$emit('viewPlaybook');
    },
    toggleFlag() {
      this.status = UpdateStatus.EDITING;
      if (this.activity.isFlagged === true) {
        this.sendUnflagActivityCommand().then(() => {
          this.activity.isFlagged = false;
        });
      } else {
        this.sendFlagActivityCommand().then(() => {
          this.activity.isFlagged = true;
        });
      }
    },
    sendFlagActivityCommand() {
      if (this.activity.isFlagged === true) {
        return false;
      }
      const flagActivityCommand = {
        type: 'FlagActivityCommand',
        // eslint-disable-next-line no-underscore-dangle
        playbookId: this.playbook._id,
        activityId: this.activity.id,
      };
      return this.sendActivityCommand(flagActivityCommand);
    },
    sendUnflagActivityCommand() {
      if (this.activity.isFlagged === false) {
        return false;
      }
      const unflagActivityCommand = {
        type: 'UnflagActivityCommand',
        // eslint-disable-next-line no-underscore-dangle
        playbookId: this.playbook._id,
        activityId: this.activity.id,
      };
      return this.sendActivityCommand(unflagActivityCommand);
    },
    // --------- Update activity -----------
    sendUpdateActivityCommand(partialCommand) {
      if (this.status !== UpdateStatus.EDITING) {
        return false;
      }
      const updateActivityCommand = {
        type: 'UpdateActivityCommand',
        // eslint-disable-next-line no-underscore-dangle
        playbookId: this.playbook._id,
        activityId: this.activity.id,
      };
      const command = { ...updateActivityCommand, ...partialCommand };
      return this.sendActivityCommand(command);
    },
    sendActivityCommand(command) {
      const answerPayload = {
        action: 'WORK',
        taskWorkDTO: {
          type: 'playbook task',
          command,
        },
      };
      this.status = UpdateStatus.UPDATING;
      return api.assess
        .post(`/tasks/${this.task.id}`, answerPayload)
        .then((response) => {
          //console.log('Activity successfully Saved');
          this.fetchPlaybook();
          this.status = UpdateStatus.SAVED;
        })
        .catch((error) => {
          const errorMessage = `Could not save Activity : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, this);
          this.status = UpdateStatus.FAILED;
        });
    },
    // ----------- Fetch playbook ------------
    fetchPlaybook() {
      const payload = {
        taskId: this.task.id,
        vm: this,
      };
      this.$store.dispatch('playbooks/fetchPlaybook', payload).then(this.saveActivityToCopy);
    },
    saveActivityToCopy() {
      this.activityCopy.title = this.activity.title;
      this.activityCopy.activityCode = this.activity.activityCode;
      this.activityCopy.status = this.activity.status;
      this.activityCopy.owner = this.activity.owner;
      this.activityCopy.assignedTo = this.activity.assignedTo;
      this.activityCopy.clientFocalPoint = this.activity.clientFocalPoint;
      this.activityCopy.summary = this.activity.summary;
      this.activityCopy.budget = this.activity.budget;
      this.activityCopy.startDate = this.activity.startDate;
      this.activityCopy.dueDate = this.activity.dueDate;
      this.activityCopy.newFinishDate = this.activity.newFinishDate;
      this.activityCopy.remainingEffort = this.activity.remainingEffort;
    },
  },
};
</script>

<style lang="scss" scoped>
.section-title {
  margin-bottom: 1rem;
  padding-left: 1.2rem;
}
.button-container {
  padding-left: 1rem;
  text-align: right;
}
.line {
  text-align: center;
}
.align-right {
  text-align: right;
}
.red-flag {
  color: #fe0600 !important;
}
.gray-flag {
  color: #bcc6cc !important;
}
.form-input {
  display: flex;
  gap: 10px;
}
</style>
