<template>
  <div class="archive">
    <span>
      <md-icon class="icon-action" tabindex="-1" v-popover:archive slot="reference">restore</md-icon>
      <md-tooltip md-delay="300">Show archived elements</md-tooltip>
    </span>
    <el-popover
        placement="bottom-start"
        title=""
        ref="archive"
        trigger="click"
        width="400"
        v-model="popoverVisible">
        <div class="archive-popover">
          <h5 class="text-center">Archived categories</h5>
          <table class="archive-table">
              <tr>
                  <th style="min-width:120px;">Title</th>
                  <th colspan="2" style="min-width:50px;">Actions</th>
              </tr>
              <tr v-for="(category, i) in categories" :key="i">
                  <td style="vertical-align:top" :title="category.title">
                    {{category.title}}
                  </td>
                  <td style="vertical-align:top">
                    <md-icon class="icon-action" @click.native="unarchiveCategory(category)" >unarchive</md-icon>
                    <md-tooltip class="archive-action-tooltip" md-delay="300">Unarchive category</md-tooltip>
                  </td>
                  <td style="vertical-align:top">
                    <md-icon class="icon-action" @click.native="showDeletionConfirmationPopUpCategory(category)" >delete</md-icon>
                    <md-tooltip class="archive-action-tooltip" md-delay="300">Delete category permanently</md-tooltip>
                  </td>
              </tr>
          </table>
          <h5 class="text-center">Archived activities</h5>
          <table class="archive-table">
              <tr>
                  <th style="min-width:120px;">Title</th>
                  <th style="min-width:50px;">Actions</th>
              </tr>
              <tr v-for="(activity, i) in activities" :key="i">
                  <td style="vertical-align:top" :title="activity.title">
                    {{activity.title}}
                  </td>
                  <td style="vertical-align:top">
                    <span>
                      <md-icon class="icon-action" @click.native="unarchiveActivity(activity)" >unarchive</md-icon>
                      <md-tooltip class="archive-action-tooltip" md-delay="300">Unarchive activity</md-tooltip>
                    </span>
                    <span>
                      <md-icon class="icon-action" @click.native="showDeletionConfirmationPopUpActivity(activity)" >delete</md-icon>
                      <md-tooltip class="archive-action-tooltip" md-delay="300">Delete activity permanently</md-tooltip>
                    </span>
                  </td>
              </tr>
          </table>
        </div>
    </el-popover>
  </div>
</template>

<script>
import api from '@/api/api';
import notify from '@/utils/notificationsUtils';
import {
  Popover,
  Button,
  Tooltip,
  InfiniteScroll,
} from 'element-ui';
import Swal from 'sweetalert2';
import UpdateStatus from '../UpdateStatus';

const swalWithCustomButtons = Swal.mixin({
  customClass: {
    confirmButton: 'md-button md-success btn-fill',
    cancelButton: 'md-button md-danger btn-fill',
  },
  buttonsStyling: false,
});

export default {
  components: {
    [Button.name]: Button,
    [Popover.name]: Popover,
    [Tooltip.name]: Tooltip,
  },
  directives: {
    [InfiniteScroll.name]: InfiniteScroll,
  },
  name: 'archived-categories-and-activities',
  props: {
    taskId: {
      type: Number,
      required: true,
    },
    playbookId: {
      type: String,
      required: true,
    },
    activities: {
      type: Array,
      required: true,
      default: () => [],
      description: 'This is the  list of archived activities.',
    },
    categories: {
      type: Array,
      required: true,
      default: () => [],
      description: 'This is the  list of archived categories.',
    },
  },
  data() {
    return {
      popoverVisible: false,
    };
  },
  methods: {
    showDeletionConfirmationPopUpCategory(category) {
      this.isDeletionPopUpOpen = true;
      swalWithCustomButtons
        .fire({
          title: 'Are you sure you want to permanently delete this category :',
          text: `"${category.title}"`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, remove it!',
          buttonsStyling: false,
          onClose: this.closeIsDeletionPopUpOpen,
        })
        .then((result) => {
          if (result.value) {
            this.deleteCategory(category);
          }
        });
    },
    deleteCategory(category) {
      const deleteCategoryCommand = {
        // eslint-disable-next-line no-underscore-dangle
        playbookId: this.playbookId,
        categoryId: category.id,
        type: 'DeleteCategoryCommand',
      };
      const taskWorkCommand = {
        action: 'WORK',
        taskWorkDTO: {
          type: 'playbook task',
          command: deleteCategoryCommand,
        },
      };
      this.$emit('status', UpdateStatus.UPDATING);
      return api.assess
        .post(`/tasks/${this.taskId}`, taskWorkCommand)
        .then((response) => {
          //console.log('Category successfully deleted');
          this.fetchPlaybook();
          this.popoverVisible = false;
          this.$emit('status', UpdateStatus.SAVED);
        })
        .catch((error) => {
          const errorMessage = `Could not delete category : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, this);
          this.$emit('status', UpdateStatus.FAILED);
        });
    },
    unarchiveCategory(category) {
      const archiveCategoriesCommand = {
        type: 'UnarchiveCategoryCommand',
        // eslint-disable-next-line no-underscore-dangle
        playbookId: this.playbookId,
        categoryId: category.id,
      };
      const answerPayload = {
        action: 'WORK',
        taskWorkDTO: {
          type: 'playbook task',
          command: archiveCategoriesCommand,
        },
      };
      this.$emit('status', UpdateStatus.UPDATING);
      api.assess
        .post(`/tasks/${this.taskId}`, answerPayload)
        .then((response) => {
          this.fetchPlaybook();
          this.popoverVisible = false;
          this.$emit('status', UpdateStatus.SAVED);
        })
        .catch((error) => {
          const errorMessage = `Could not archive category : ${error.response
            .data.message || ''}`;
          notify.notifyError(errorMessage, this);
          this.$emit('status', UpdateStatus.FAILED);
        });
    },
    showDeletionConfirmationPopUpActivity(activity) {
      this.isDeletionPopUpOpen = true;
      swalWithCustomButtons
        .fire({
          title: 'Are you sure you want to permanently delete this activity :',
          text: `"${activity.title}"`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, remove it!',
          buttonsStyling: false,
          onClose: this.closeIsDeletionPopUpOpen,
        })
        .then((result) => {
          if (result.value) {
            this.deleteActivity(activity);
          }
        });
    },
    deleteActivity(activity) {
      const deleteActivityCommand = {
        // eslint-disable-next-line no-underscore-dangle
        playbookId: this.playbookId,
        activityId: activity.id,
        type: 'DeleteActivityCommand',
      };
      const taskWorkCommand = {
        action: 'WORK',
        taskWorkDTO: {
          type: 'playbook task',
          command: deleteActivityCommand,
        },
      };
      this.$emit('status', UpdateStatus.UPDATING);
      return api.assess
        .post(`/tasks/${this.taskId}`, taskWorkCommand)
        .then((response) => {
          //console.log('Activity successfully deleted');
          this.fetchPlaybook();
          this.popoverVisible = false;
          this.$emit('status', UpdateStatus.SAVED);
        })
        .catch((error) => {
          const errorMessage = `Could not delete activity : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, this);
          this.$emit('status', UpdateStatus.FAILED);
        });
    },
    unarchiveActivity(activity) {
      const archiveActivitiesCommand = {
        type: 'UnarchiveActivityCommand',
        // eslint-disable-next-line no-underscore-dangle
        playbookId: this.playbookId,
        activityId: activity.id,
      };
      const answerPayload = {
        action: 'WORK',
        taskWorkDTO: {
          type: 'playbook task',
          command: archiveActivitiesCommand,
        },
      };
      this.$emit('status', UpdateStatus.UPDATING);
      api.assess
        .post(`/tasks/${this.taskId}`, answerPayload)
        .then((response) => {
          this.fetchPlaybook();
          this.popoverVisible = false;
          this.$emit('status', UpdateStatus.SAVED);
        })
        .catch((error) => {
          const errorMessage = `Could not archive activity : ${error.response
            .data.message || ''}`;
          notify.notifyError(errorMessage, this);
          this.$emit('status', UpdateStatus.FAILED);
        });
    },
    fetchPlaybook() {
      const payload = {
        taskId: this.taskId,
        vm: this,
      };
      return this.$store.dispatch('playbooks/fetchPlaybook', payload);
    },
  },
};
</script>

<style lang="scss" scoped >
.archive{
  display: inline-block;
}
.archive-popover{
  max-height: 400px;
  overflow-y: auto;
  z-index: 4000;
}
.archive-table{
  width: 100%;
}
.archive-action-tooltip {
  z-index: 5000;
}
</style>
