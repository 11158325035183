<template>
  <div>
    <el-col :span="24">
      <h5 class="section-title">
        <ArchivedConditions :playbookId="playbook._id" :taskId="task.id" :activityId="activity.id" :conditions="activity.conditions.filter(e=>e.isArchived)"
            @status="status = $event"/> Conditions
        <span class="saving-button"
        :class="{saved: status === UpdateStatus.SAVED,
          failed: status === UpdateStatus.FAILED,
          updating: status === UpdateStatus.UPDATING,
          editing: status === UpdateStatus.EDITING}"></span>
      </h5>
    </el-col>

    <draggable
      tag="ul"
      v-model="conditions"
      :animation="200"
      :group="{ name: 'all-activities', pull: 'all-activities', put: 'all-activities' }"
      handle=".handleCondition"
      @change="onChangeConditions($event)"
    >
      <div v-for="(condition, index) in conditions" :key="condition.id" class="condition-card md-layout">
        <div v-if="!condition.isArchived" class="md-layout-item md-layout">
          <div class="md-layout-item md-size-5">
            <div class="card-icon noshadow cursor-pointer">
              <md-icon class="handleCondition">drag_handle</md-icon>
            </div>
          </div>
          <el-form class="md-layout-item md-size-95" label-width="80px">
            <el-form-item label="Name">
              <el-col :span="24">
                <el-input
                  v-model="condition.condition"
                  :prop="'condition.' + index"
                  placeholder="Condition description"
                  @blur="conditionsCopy[index].condition !== condition.condition ? sendUpdateConditionCommand(condition):''"
                  @keydown.enter.prevent.native="() => conditionsCopy[index].condition !== condition.condition ? sendUpdateConditionCommand(condition):''"
                  @input="onConditionFormChange(conditionsCopy[index].condition, condition.condition)"
                >
                </el-input>
              </el-col>
            </el-form-item>
            <el-form-item>
              <el-col :span="6">
                <el-checkbox
                  v-model="condition.isComplete"
                  label="Completed"
                  name="Completed"
                  @change="onActivityCheckboxInputChange(condition)"
                >
                </el-checkbox>
              </el-col>
              <el-col :span="2" :offset="16" class="align-right hide-button">
                <span @click="archiveCondition(condition)">
                  <md-icon class="icon-action icon-action icon-delete"
                    >archive</md-icon
                  >
                  <md-tooltip md-delay="300"
                    >Archive condition : "{{ condition.condition }}"</md-tooltip
                  >
                </span>
              </el-col>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </draggable>
    <el-form label-width="120px">
      <el-form-item v-if="addConditionFormDisplayed">
        <el-input
          v-model="newCondition.condition"
          :prop="'newCondition'"
          placeholder="New condition description"
          @keydown.enter.prevent.native="createNewCondition()"
          @blur="createNewCondition()"
          ref="conditionInput"
        >
        </el-input>
      </el-form-item>
      <el-form-item v-else>
        <el-col :span="1" :offset="23">
          <span @click="displayAddConditionForm">
            <md-icon class="icon-action">add</md-icon>
            <md-tooltip md-delay="300">Add activity condition</md-tooltip>
          </span>
        </el-col>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import api from '@/api/api';
import notify from '@/utils/notificationsUtils';
import draggable from 'vuedraggable';
import {
  Form,
  FormItem,
  Input,
  DatePicker,
  Button,
  Col,
  Checkbox,
  Select,
  Option,
  InputNumber,
  Slider,
} from 'element-ui';
import ArchivedConditions from './ArchivedConditions.vue';
import UpdateStatus from '../../UpdateStatus';

export default {
  name: 'conditions',
  components: {
    draggable,
    [Form.name]: Form,
    [Input.name]: Input,
    [DatePicker.name]: DatePicker,
    [FormItem.name]: FormItem,
    [Button.name]: Button,
    [Col.name]: Col,
    [Select.name]: Select,
    [Option.name]: Option,
    [InputNumber.name]: InputNumber,
    [Slider.name]: Slider,
    [Checkbox.name]: Checkbox,
    ArchivedConditions,
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
    activity: {
      type: Object,
      required: true,
    },
    parentContainer: {
      type: Object,
      required: true,
    },
    playbook: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      addConditionFormDisplayed: false,
      newCondition: {},
      status: UpdateStatus.SAVED,
      UpdateStatus,
      conditionsCopy: [],
    };
  },
  mounted() {
    this.saveConditionsToCopy();
  },
  computed: {
    conditions: {
      get() {
        return this.activity.conditions;//.filter((c) => !c.isArchived);
      },
      set(conditions) {
        this.activity.conditions = conditions;
        //this.activity.conditions.concat(this.activity.conditions.filter((c) => c.isArchived));
      },
    },
  },
  methods: {
    // reordering
    onChangeConditions(event) {
      //console.log('playbook event', event);
      if (event.added) {
        // move between lists is currently unsupported for Conditions
      } else if (event.removed) {
        // Remove is only triggered when we add the element to another list, so we manage that in the add event
      } else if (event.moved) {
        const newIndex = event.moved.newIndex + (event.moved.newIndex > event.moved.oldIndex ? 1 : 0);
        this.onMoveConditionToList(event.moved.element, newIndex);
      }
    },
    onMoveConditionToList(condition, index) {
      const moveConditionCommand = {
        type: 'MoveConditionCommand',
        // eslint-disable-next-line no-underscore-dangle
        playbookId: this.playbook._id,
        activityId: this.activity.id,
        conditionId: condition.id,
        index,
      };
      const answerPayload = {
        action: 'WORK',
        taskWorkDTO: {
          type: 'playbook task',
          command: moveConditionCommand,
        },
      };
      this.activitiesStatus = UpdateStatus.UPDATING;
      api.assess
        .post(`/tasks/${this.task.id}`, answerPayload)
        .then((response) => {
          this.fetchPlaybook();
          this.activitiesStatus = UpdateStatus.SAVED;
        })
        .catch((error) => {
          const errorMessage = `Could not reorder Playbook's conditions : ${error.response.data
            .message || ''}`;
          notify.notifyError(errorMessage, this);
          this.activitiesStatus = UpdateStatus.FAILED;
        });
    },
    // -------- Navigation -------
    displayAddConditionForm() {
      this.addConditionFormDisplayed = true;
      this.$nextTick(() => {
        this.$refs.conditionInput.focus();
      });
    },
    hideAddConditionForm() {
      this.addConditionFormDisplayed = false;
    },
    // --------- Conditions actions ---------
    onConditionFormChange(oldValue, newValue) {
      if (oldValue !== newValue) {
        this.status = UpdateStatus.EDITING;
      } else {
        this.status = UpdateStatus.SAVED;
      }
    },
    onActivityCheckboxInputChange(condition) {
      const index = this.activity.conditions.indexOf(condition);
      if (index !== -1) {
        this.sendConditionCommand({
          type: condition.isComplete
            ? 'CompleteConditionCommand'
            : 'UncompleteConditionCommand',
          conditionId: condition.id,
          isComplete: condition.isComplete,
        }).then(() => {
          this.status = UpdateStatus.SAVED;
        });
      }
    },
    createNewCondition() {
      if (this.addConditionFormDisplayed) {
        if (this.newCondition.condition) {
          this.sendConditionCommand({
            type: 'AddConditionCommand',
            condition: this.newCondition.condition,
          }).then(() => {
            this.newCondition = {};
            this.fetchPlaybook();
            this.status = UpdateStatus.SAVED;
          });
        }
        this.hideAddConditionForm();
      }
    },
    sendUpdateConditionCommand(condition) {
      const index = this.activity.conditions.indexOf(condition);
      if (index !== -1 && this.status !== UpdateStatus.SAVED) {
        this.sendConditionCommand({
          type: 'UpdateConditionCommand',
          conditionId: condition.id,
          condition: condition.condition,
        })
          .then(() => {
            this.status = UpdateStatus.SAVED;
          });
      }
    },
    archiveCondition(condition) {
      const index = this.activity.conditions.indexOf(condition);
      if (index !== -1) {
        this.sendConditionCommand({
          type: 'ArchiveConditionCommand',
          conditionId: condition.id,
        })
          .then(() => {
            this.fetchPlaybook();
            this.status = UpdateStatus.SAVED;
          }).catch(() => {
            this.status = UpdateStatus.FAILED;
          });
      }
    },
    sendConditionCommand(partialCommand) {
      const conditionCommand = {
        // eslint-disable-next-line no-underscore-dangle
        playbookId: this.playbook._id,
        activityId: this.activity.id,
        ...partialCommand,
      };
      const answerPayload = {
        action: 'WORK',
        taskWorkDTO: {
          type: 'playbook task',
          command: conditionCommand,
        },
      };
      this.status = UpdateStatus.UPDATING;
      return api.assess
        .post(`/tasks/${this.task.id}`, answerPayload)
        .then(() => {
          this.fetchPlaybook();
        })
        .catch((error) => {
          const errorMessage = `Could not save Activity : ${
            error.response.data.message || ''
          }`;
          notify.notifyError(errorMessage, this);
          this.status = UpdateStatus.FAILED;
        });
    },
    // -------- Fetch playbook -----------
    fetchPlaybook() {
      const payload = {
        taskId: this.task.id,
        vm: this,
      };
      return this.$store.dispatch('playbooks/fetchPlaybook', payload).then(this.saveConditionsToCopy);
    },
    saveConditionsToCopy() {
      this.conditionsCopy = [];
      Object.values(this.activity.conditions).forEach((condition) => {
        const conditionCopy = {
          condition: condition.condition,
          isComplete: condition.isComplete,
        };
        this.conditionsCopy.push(conditionCopy);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
hr {
  border-width: 1px 0px 0px 0px;
  border-style: solid;
  border-color: #c8c8c8;
}
.condition-card {
  .hide-button{
    transition: opacity 0.3s ease-out;
    opacity: 0;
  }
  &:hover .hide-button{
    transition: opacity 0.3s ease-in;
    opacity: 1;
  }
}
.section-title {
  margin-bottom: 1rem;
  padding-left: 1.2rem;
}
.button-container {
  padding-left: 1rem;
  text-align: right;
}
.line {
  text-align: center;
}
.align-right {
  text-align: right;
}
.handleCondition {
  padding-top: 0.9rem;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
