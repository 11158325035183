<template>
  <div class="history">
    <el-popover
        placement="bottom-end"
        title="History"
        ref="history"
        trigger="click"
        width="500"
        @show="fetch()">
        <md-icon class="icon-action" tabindex="-1" v-popover:history slot="reference">history</md-icon>
        <div class="history-popover" v-infinite-scroll="load">
          <table class="history-table">
              <tr>
                  <th style="min-width:120px;">Date</th>
                  <th style="min-width:50px;">Author</th>
                  <th style="min-width:50px;">Value</th>
                  <th style="min-width:50px;">Restore</th>
              </tr>
              <tr v-for="(history, i) in histories" :key="i">
                  <td style="vertical-align:top">{{dateFormat(new Date(history.timestamp))}} (GMT)</td>
                  <td style="vertical-align:top">{{history.author}}</td>
                  <td style="vertical-align:top" :title="history.value">
                    <template v-if="history.value && history.value.length > 100">
                      {{history.value.slice(0,100)}}...
                    </template>
                    <template v-else>
                      {{history.value}}
                    </template>
                  </td>
                  <td style="vertical-align:top" class="text-center">
                      <md-icon class="icon-action" @click.native="restore(history)" >restore</md-icon>
                  </td>
              </tr>
          </table>
        </div>
    </el-popover>
  </div>
</template>

<script>
import moment from 'moment';
import api from '@/api/api';
import notify from '@/utils/notificationsUtils';
import {
  Popover,
  Button,
  Tooltip,
  InfiniteScroll,
} from 'element-ui';

export default {
  components: {
    [Button.name]: Button,
    [Popover.name]: Popover,
    [Tooltip.name]: Tooltip,
  },
  directives: {
    [InfiniteScroll.name]: InfiniteScroll,
  },
  name: 'history',
  props: {
    taskId: {
      type: Number,
      required: true,
    },
    playbookId: {
      type: String,
      required: true,
    },
    targetId: {
      type: String,
      required: false,
      default: null,
      description: 'This is the target element UUID within the playbook. Leave null at playbook level.',
    },
    field: {
      type: String,
      required: false,
      default: null,
      description: 'This is the key of the field being retrieved. Leave null if we want the root level history.',
    },
  },
  data() {
    return {
      histories: [],
      loading: true,
      currentPage: 0,
    };
  },
  methods: {
    restore(history) {
      //console.log(history);
      this.$emit('restore', history);
      this.$refs.history.doClose();
    },
    fetch() {
      this.loading = true;
      this.currentPage = 0;

      const answerPayload = {
        action: 'WORK',
        taskWorkDTO: {
          type: 'playbook task',
          command: {
            playbookId: this.playbookId,
            targetId: this.targetId,
            key: this.field,
            type: 'GetModificationEventCommand',
            pageable: {
              size: 20,
              page: this.currentPage++,
            },
          },
        },
      };
      // yes we do a post to get I know
      api.assess.post(`/tasks/${this.taskId}`, answerPayload)
        .then((response) => {
          this.histories = response.data.page.content;
        })
        .catch((error) => {
        // console.log(error);
          const errorMessage = `Could not Fetch Playbook History : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, this);
        }).finally(() => {
          this.loading = false;
        });
    },
    load() {
      this.loading = true;

      const answerPayload = {
        action: 'WORK',
        taskWorkDTO: {
          type: 'playbook task',
          command: {
            playbookId: this.playbookId,
            targetId: this.targetId,
            key: this.field,
            type: 'GetModificationEventCommand',
            pageable: {
              size: 20,
              page: this.currentPage++,
            },
          },
        },
      };
      api.assess.post(`/tasks/${this.taskId}`, answerPayload)
        .then((response) => {
          this.histories = this.histories.concat(response.data.page.content);
        })
        .catch((error) => {
        // console.log(error);
          const errorMessage = `Could not Fetch Playbook History : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, this);
        }).finally(() => {
          this.loading = false;
        });
    },
    dateFormat(date) {
      if (date === undefined) {
        return '';
      }
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
  },
};
</script>

<style lang="scss" scoped >
.history-popover{
  max-height: 400px;
  overflow-y: auto;

}
.history-table{
  width: 100%;
}
</style>
