<template>
  <div>
    <div v-if="loading">
      <loader/>
    </div>
    <div v-if="!loading">
      <log-work-modal
        v-if="showLogWorkModal"
        @close="showLogWorkModal = false"
        @logWork="onLogWork"
      ></log-work-modal>
      <div class="md-layout">
        <div class="md-layout-item md-layout md-size-100 md-medium-size-100">
          <div class="md-layout-item md-layout md-size-75 md-medium-size-75">
            <div class="md-layout-item md-size-100 md-medium-size-100 nopadding">
              <div class="md-layout-item md-size-100 md-medium-size-100">
                <breadcrumbs :sourceName="project.name" :sourceLink="projectPath" :currentName="currentTask.name"/>
              </div>
              <div>
                <div class="md-layout card-task-work-layout">
                  <div class="md-layout-item md-size-100 md-medium-size-100 work-container">
                    <nav-tabs-card class="noshadow">
                      <template slot="content">
                        <md-tabs
                          class="md-custom-color"
                          md-alignment="centered"
                          @md-changed="onTabChanged"
                        >
                          <md-tab v-if="isFormTask" id="tab-form" md-label="Form" md-icon="article">
                            <form-task
                              :projectId="projectId"
                              :isReadOnly="hasOnlyObserverRole"
                              ref="formTaskComponent"
                            ></form-task>
                          </md-tab>

                          <md-tab v-if="isPlaybookTask" id="tab-playbook" md-label="Playbook" md-icon="book">
                            <playbook-task></playbook-task>
                          </md-tab>

                          <md-tab id="tab-notes" md-label="Notes" md-icon="sticky_note_2">
                            <notes-task-container></notes-task-container>
                          </md-tab>

                          <md-tab
                            id="tab-instructions"
                            md-label="Instructions"
                            md-icon="integration_instructions"
                          >
                            <div class="md-layout">
                              <div class="md-layout-item md-size-100 nopadding">
                                <div class="work-instructions">
                                  <p class="instructions-text">{{ currentTask.workInstructions }}</p>
                                </div>
                              </div>
                            </div>
                          </md-tab>
                        </md-tabs>
                      </template>
                    </nav-tabs-card>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Right Column with summary, expert opinion and work log -->
          <div class="md-layout-item md-size-25 md-medium-size-25">
            <div class="md-layout">
              <div class="md-layout-item md-size-100 nopadding">
                <md-card class="card-task-information">
                  <md-card-header class="md-card-header-text">
                    <div class="card-text">
                      <h5 class="title">Summary</h5>
                    </div>
                  </md-card-header>
                  <md-card-content>
                    <h6>Status: {{ currentTaskState }}</h6>
                    <h6>Time spent: {{ currentTaskTimeSpent }} hours</h6>
                    <div class="task-summary-buttons-container">
                      <div v-if="!isTaskCompleted && !hasOnlyObserverRole">
                        <span @click="completeTask">
                          <md-icon class="icon-action-big icon-add">check_circle_outline</md-icon>
                          <md-tooltip md-delay="300">Mark Task As Done</md-tooltip>
                        </span>
                      </div>
                      <div v-if="displayButtons">
                        <span @click="showLogWorkModal = true">
                          <md-icon class="icon-action-big icon-add">alarm</md-icon> <!-- alternative icon: schedule, update -->
                          <md-tooltip md-delay="300">Log Time on Task</md-tooltip>
                        </span>
                      </div>
                    </div>
                  </md-card-content>
                </md-card>
              </div>
              <div class="md-layout-item md-size-100 nopadding">
                <md-card class="card-task-activities" v-if="showSlidesCard">
                  <md-card-header class="md-card-header-text">
                    <div class="card-text">
                      <h5 class="title">Slides</h5>
                    </div>
                  </md-card-header>
                  <md-card-content>
                    <div class="form-container-content">
                      <md-button
                        v-if="jwtRelayUrl != ''"
                        class="md-sm show-deck-button"
                        :href="deckUrl"
                        target="_blank"
                      >
                        Show Slides
                      </md-button>
                    </div>
                  </md-card-content>
                </md-card>
              </div>
              <div class="md-layout-item md-size-100 nopadding">
                <md-card class="card-task-activities">
                  <md-card-header class="md-card-header-text">
                    <div class="card-text">
                      <h5 class="title">Expert Opinion</h5>
                    </div>
                  </md-card-header>
                  <md-card-content>
                    <expert-opinion
                      :task="currentTask"
                      :projectId="projectId"
                      :isReadOnly="hasOnlyObserverRole"
                      ref="expertOpinionComponent"
                    >
                    </expert-opinion>
                  </md-card-content>
                </md-card>
              </div>
              <div class="md-layout-item md-size-100 nopadding">
                <md-card class="card-task-activities">
                  <md-card-header class="md-card-header-text">
                    <div class="card-text">
                      <h5 class="title">Work Log</h5>
                    </div>
                  </md-card-header>
                  <md-card-content>
                    <task-work-log-list :taskId="this.taskId"></task-work-log-list>
                  </md-card-content>
                </md-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { NavTabsCard } from '@/components/Template';

import api from '@/api/api';
import Loader from '@/components/Avalia/Loader/Loader.vue';
import FormTask from '@/components/Avalia/TaskDetails/TaskType/FormTask.vue';
import TaskWorkLogList from '@/components/Avalia/TaskDetails/TaskWorkLog/TaskWorkLogList.vue';
import LogWorkModal from '@/components/Avalia/TaskDetails/LogWorkModal.vue';
import NotesTaskContainer from '@/components/Avalia/Note/NotesTaskContainer.vue';
import ExpertOpinion from '@/components/Avalia/TaskDetails/Form/ExpertOpinion/ExpertOpinion.vue';
import Breadcrumbs from '@/components/Avalia/Breadcrumbs/Breadcrumbs.vue';
import PlaybookTask from '@/components/Avalia/TaskDetails/TaskType/PlaybookTask.vue';

import taskUtils from '@/utils/taskUtils';

import {
  observerRole,
  curatorRole,
  avaliaExpertRole,
  externalExpertRole,
} from '@/utils/rolesUtils';
import notify from '@/utils/notificationsUtils';

export default {
  name: 'task-details',
  components: {
    NavTabsCard,
    FormTask,
    TaskWorkLogList,
    LogWorkModal,
    NotesTaskContainer,
    ExpertOpinion,
    Breadcrumbs,
    Loader,
    PlaybookTask,
  },
  props: {},
  data() {
    return {
      showLogWorkModal: false,
      projectId: null,
      taskId: null,
      jwtRelayUrl: '',
      tabChoose: 'tab-instructions',
      loading: true,
    };
  },
  computed: {
    deckUrl() {
      const config = this.$store.getters['config/GET_CORE_CONFIG'];
      const slidesAppUrl = config.applications.slides.externalUrl;
      let target;
      if (!api.assess.defaults.baseURL.startsWith('http')) {
        // eslint-disable-next-line no-restricted-globals
        target = encodeURIComponent(
          // eslint-disable-next-line no-restricted-globals
          `${location.origin}${api.assess.defaults.baseURL}/tasks/${this.taskId}/slides`,
        );
      } else {
        target = encodeURIComponent(`${api.assess.defaults.baseURL}/tasks/${this.taskId}/slides`);
      }
      return `${slidesAppUrl}/decks/remote/1?url=${target}&relayUrl=${this.jwtRelayUrl}`;
    },
    showSlidesCard() {
      return this.currentTask.hasSlides;
    },
    currentTaskFormId() {
      return this.currentTask.formId;
    },
    currentTask() {
      return this.$store.getters['task/GET_TASK'];
    },
    currentTaskState() {
      return this.currentTask.state;
    },
    currentTaskTimeSpent() {
      return this.currentTask.hoursSpent;
    },
    isTaskCompleted() {
      return this.currentTaskState === 'completed';
    },
    isFormTask() {
      return taskUtils.isFormTask(this.currentTask.type);
    },
    isPlaybookTask() {
      return taskUtils.isPlaybookTask(this.currentTask.type);
    },
    project() {
      return this.$store.getters['project/GET_PROJECT'];
    },
    projectPath() {
      return `/project/${this.projectId}`;
    },
    currentUser() {
      return this.$store.getters['users/GET_CURRENT_USER'];
    },
    currentUserInProject() {
      return this.project.users.find((u) => u.email === this.currentUser.sub);
    },
    hasCuratorRole() {
      return this.hasRoleInProject(curatorRole);
    },
    hasAvaliaExpertRole() {
      return this.hasRoleInProject(avaliaExpertRole);
    },
    hasExternalExpertRole() {
      return this.hasRoleInProject(externalExpertRole);
    },
    hasObserverRole() {
      return this.hasRoleInProject(observerRole);
    },
    hasOnlyObserverRole() {
      if (this.hasCuratorRole || this.hasAvaliaExpertRole || this.hasExternalExpertRole) {
        return false;
      }
      return true;
    },
    displayButtons() {
      return !this.hasOnlyObserverRole;
    },
  },
  watch: {},
  created() {
    // On created
    this.projectId = +this.$route.params.projectId;
    this.taskId = +this.$route.params.taskId;

    this.fetchAllTaskData();
  },
  mounted() {
    // On mounted
    // STUFF to generate the slide deck
    this.$track('fe_teaser_slideShowAllProjects');
    api.assess.get('/sso/relay').then((response) => {
      if (!api.assess.defaults.baseURL.startsWith('http')) {
        // eslint-disable-next-line no-restricted-globals
        this.jwtRelayUrl = `${location.origin}${api.assess.defaults.baseURL}/sso/relay/${response.data}/jwt`;
      } else {
        this.jwtRelayUrl = `${api.assess.defaults.baseURL}/sso/relay/${response.data}/jwt`;
      }
    });
  },
  methods: {
    onTabChanged(event) {
      this.savePendingModifications();
      // Scroll tab back up on tab change
      const elements = document.getElementsByClassName('md-tabs-content');
      const element = elements[0];
      if (element) {
        element.scrollTop = 0;
      }
      this.tabChoose = event;
    },
    savePendingModifications() {
      // Save potentially unsaved answers on form
      if (this.isFormTask) {
        this.$refs.formTaskComponent.savePendingModifications();
      }
      // Save potentially unsaved expert opinion
      this.$refs.expertOpinionComponent.saveModifications();
    },
    hasRoleInProject(role) {
      return this.currentUserInProject.roles.includes(role);
    },
    fetchAllTaskData() {
      // Promises to fetch task and project details
      const taskFetchPromise = this.fetchCurrentTask();
      const taskNotesFetchPromise = this.fetchCurrentTaskNotes();
      let projectFetchPromise = null;

      if (this.project.id == null) {
        projectFetchPromise = this.fetchProjectDetails();
      }

      Promise.all([taskFetchPromise, taskNotesFetchPromise, projectFetchPromise])
        .then(() => {
          this.loading = false;
        });
    },
    fetchCurrentTask() {
      return this.$store.dispatch('task/fetchTask', { taskId: this.taskId, vm: this });
    },
    fetchCurrentTaskNotes() {
      const payload = {
        taskId: this.taskId,
        vm: this,
      };
      return this.$store.dispatch('notes/fetchTaskNotes', payload);
    },
    resetTaskWorkLog() {
      return this.$store.dispatch('task/resetTaskWorkLog');
    },
    fetchProjectDetails() {
      return this.$store.dispatch('project/fetchProjectDetails', {
        projectId: this.projectId,
        vm: this,
      });
    },
    onLogWork(workLogForm) {
      const workLogPayload = {
        timeSpent: workLogForm.timeSpent,
        description: workLogForm.description,
        taskId: this.taskId,
      };
      this.showLogWorkModal = false;
      api.assess
        .post('/worklogs', workLogPayload)
        .then((response) => {
          this.resetTaskWorkLog();
          this.fetchCurrentTask();
          notify.notifySuccess(`Work on Task ${this.currentTask.name} successfully Logged`, this);
        })
        .catch((error) => {
          // console.log(error);
          const errorMessage = `Could not Log Work on Task ${this.currentTask.name} : ${error.response.data.message} || ''}`;
          notify.notifyError(errorMessage, this);
        });
    },
    completeTask() {
      const workPayload = {
        action: 'COMPLETE',
        taskWorkDTO: null,
      };
      this.savePendingModifications();
      api.assess
        .post(`/tasks/${this.currentTask.id}`, workPayload)
        .then((response) => {
          const successMessage = `Task ${this.currentTask.name} successfully Completed`;
          notify.notifySuccess(successMessage, this);
          this.$router.push({
            name: 'Project Details',
            params: { projectId: this.projectId },
          });
        })
        .catch((error) => {
          // console.log(error);
          const errorMessage = `Could not Complete Task : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, this);
        });
    },
    moveToTop() {
      const to = this.moveToDown ? this.$refs.description.offsetTop - 60 : 0;

      window.scroll({
        top: to,
        left: 0,
        behavior: 'smooth',
      });

      this.moveToDown = !this.moveToDown;
    },
  },
};
</script>

<style lang="scss" scoped>
.task-state-container {
  padding-top: 0.6rem;
  font-size: 1rem;
}
.task-time-container {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  font-size: 1rem;
}
.custom-breadcrumbs-font {
  font-weight: 400;
  font-size: 1.25rem;
  text-transform: uppercase;
  color: #7a7a7a !important;
}
.custom-breadcrumbs-link {
  &:hover {
    text-decoration: underline;
  }
}
.custom-breadcrumbs {
  padding-top: 0.1rem;
  padding-right: 0.8rem;
}
.breadcrumb-icon {
  padding-right: 0.8rem;
}
.work-container {
  padding: 0rem 0rem 0rem;
}
.card-task-work {
  padding-top: 1rem;
}
.card-task-work-layout {
  height: 100%;
}
.card-task-information {
  margin-top: 3.6rem;
}
// CUSTOM BUTTONS TO MOVE TO avalia.scss
.complete-button {
  margin-right: 1rem;

  color: #27eb68 !important;
  background-color: white !important;
  border-style: solid !important;
  border-width: 2px !important;
  &:hover {
    color: white !important;
    background-color: #27eb68 !important;
    opacity: 0.8 !important;
    border-color: #27eb68 !important;
  }
}
.submit-button {
  color: #ffa200 !important; //avalia-orange
  background-color: white !important;
  border-style: solid !important;
  border-width: 2px !important;
  &:hover {
    color: white !important;
    background-color: #ffa200 !important;
    opacity: 0.8 !important;
    border-color: #ffa200 !important;
  }
}
.show-deck-button {
  color: #27eb68 !important;
  background-color: white !important;
  border-style: solid !important;
  border-width: 2px !important;
  &:hover {
    color: white !important;
    background-color: #27eb68 !important;
    opacity: 0.8 !important;
    border-color: #27eb68 !important;
  }
}
.right-side-info-container {
  flex-wrap: nowrap;
  flex-direction: column;
}
.md-tabs.md-custom-color {
  &::v-deep .md-tabs-navigation {
    background-color: $avalia-gray;

    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12);
  }
  &::v-deep .md-content {
    min-height: 5rem;
    //height: auto !important;
  }
  &::v-deep .md-tab-label {
    font-size: 14px;
  }
}
#tab-form {
  height: auto;
}
.task-summary-buttons-container{
  padding: 2rem 0.5rem 0.5rem;
  display: flex;
  justify-content: space-between;
}
</style>
