<template>
  <modal @close="hide">
    <template slot="header">
      <md-button
        class="md-simple md-just-icon md-round modal-default-button"
        @click="hide"
      >
        <md-icon>clear</md-icon>
      </md-button>
      <h4 class="modal-title">{{ title }}</h4>
    </template>

    <template slot="body">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form class="form-horizontal" @submit.prevent="handleSubmit(onSubmit)">
          <div class="md-layout">
            <!-- Project Name -->
            <div class="md-layout-item md-size-100 mx-auto md-medium-size-100">
              <ValidationProvider
                name="timeSpent"
                rules="required|decimal"
                v-slot="{ passed, failed, errors }"
              >
                <span>{{ errors[0] }}</span>
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Time Spent (Hours)</label>
                  <md-input
                    v-model="workLogForm.timeSpent"
                    type="text"
                    ref="timeSpentField"
                    required
                  ></md-input>

                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
            <!-- Description -->
            <div class="md-layout-item md-size-100 mx-auto md-medium-size-100">
              <md-field class="description-field">
                <label>Description</label>
                <md-textarea
                  :maxlength="2000"
                  :md-autogrow="true"
                  v-model="workLogForm.description"
                  type="text"
                />
              </md-field>
            </div>
            <!-- Buttons -->
            <div
              class="md-layout-item md-size-100 mx-auto md-medium-size-100 buttons-container"
            >
              <md-button
                class="md-danger modal-button md-sm cancel-button"
                @click="hide"
                >Cancel</md-button
              >
              <md-button class="md-success md-sm confirm-button" type="submit">
                Confirm
              </md-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </template>
  </modal>
</template>

<script>
import { SlideYDownTransition } from 'vue2-transitions';
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

import { Modal } from '@/components/Template';

extend('required', required);
// Custom validation rule required for Vee-Validate 3
// https://stackoverflow.com/questions/59156440/how-to-validate-decimal-value-in-vee-validate-3-0-version
extend('decimal', {
  validate: (value, { decimals = '*', separator = '.' } = {}) => {
    if (value === null || value === undefined || value === '') {
      return {
        valid: false,
      };
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value),
      };
    }
    const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`;
    const regex = new RegExp(
      `^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`,
    );
    return {
      valid: regex.test(value),
      data: {
        serverMessage: 'Only decimal values are accepted in this field',
      },
    };
  },
  message: '{serverMessage}',
});

export default {
  name: 'log-work-modal',
  components: { Modal, SlideYDownTransition },
  props: {},
  data() {
    return {
      title: 'Submit Work Log',
      workLogForm: {
        timeSpent: '',
        description: '',
      },
    };
  },
  computed: {},
  watch: {},
  created() {
    // On created
  },
  mounted() {
    // On mounted
    // Set focus on time spent field
    this.$refs.timeSpentField.$el.focus();
  },
  methods: {
    // Other methods
    hide() {
      this.$emit('close');
    },
    onSubmit() {
      this.$emit('logWork', this.workLogForm);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-required {
  text-align: left;
  padding-left: 1rem;
  padding-top: 0.5rem;
  font-size: 14px;
}
.participants-autocomplete {
  padding-top: 0rem;
}
.participants-autocomplete-item {
  padding-left: 0rem;
}
.user-entry {
  text-align: left;
}
.buttons-container {
  padding-top: 0.4rem;
}
.no-participant {
  padding-top: 1rem;
  padding-left: 1.2rem;
}
.tiny-button {
  height: 19px;
  min-width: 19px;
  width: 19px;
  top: -0.2rem;
  left: 0.4rem;
}
.tags-field-container {
  padding-top: 0rem;
  padding-bottom: 0rem;
}
.tags-field {
  margin-top: 0rem;
}
.tags-label {
  padding-top: 0.8rem;
  padding-left: 0rem;
}
.tags-input {
  padding-left: 0rem;
}
.modal-button {
  margin-right: 0.4rem;
}
.link-field {
  padding-top: 0.4rem;
}
.description-field {
  margin-top: 0.8rem;
}
// CUSTOM BUTTONS TO MOVE TO avalia.scss
.confirm-button {
  color: #27eb68 !important;
  background-color: white !important;
  border-style: solid !important;
  border-width: 2px !important;
  &:hover {
    color: white !important;
    background-color: #27eb68 !important;
    opacity: 0.8 !important;
    border-color: #27eb68 !important;
  }
}
.cancel-button {
  color: #fe0600 !important;
  background-color: white !important;
  border-style: solid !important;
  border-width: 2px !important;
  &:hover {
    color: white !important;
    background-color: #fe0600 !important;
    opacity: 0.8 !important;
    border-color: #fe0600 !important;
  }
}
</style>
