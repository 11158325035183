<template>
  <div>
    <task-work-log
      v-for="taskWorkLog in currentTaskWorkLogs"
      :key="taskWorkLog.id"
      :taskWorkLog="taskWorkLog"
    ></task-work-log>
    <infinite-loading
      slot="append"
      :identifier="infiniteId"
      @infinite="infiniteHandler"
      force-use-infinite-wrapper=".el-table__body-wrapper">
    >
      <div slot="no-results">No work log</div>
      <div slot="no-more">No more work log</div>
    </infinite-loading>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';

import TaskWorkLog from '@/components/Avalia/TaskDetails/TaskWorkLog/TaskWorkLog.vue';

export default {
  name: 'task-work-logs-list',
  components: { TaskWorkLog, InfiniteLoading },
  props: {
    taskId: Number,
  },
  data() {
    return {};
  },
  computed: {
    currentTaskWorkLogs() {
      return this.$store.getters['task/GET_TASK_WORKLOGS'];
    },
    infiniteId() {
      return this.$store.getters['task/GET_INFINITE_ID'];
    },
  },
  watch: {
    taskId(newValue, oldValue) {
      this.$store.dispatch('task/resetTaskWorkLog');
    },
  },
  created() {
    // On created
    this.$store.dispatch('task/resetTaskWorkLog');
  },
  mounted() {
    // On mounted
  },
  methods: {
    infiniteHandler($state) {
      const payload = {
        taskId: this.taskId,
        infiniteState: $state,
        vm: this,
      };
      this.$store.dispatch('task/fetchTaskWorkLogs', payload);
    },
  },
};
</script>

<style lang="scss" scoped></style>
